<template>
  <div id="login">
  <div id="section-signup" class="section last-section container">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h2 class="section-title mb-3">Subscribe Client</h2>
            <form v-on:submit.prevent="AddClient">
              <ClientPage v-on:form-updated="form = $event"/>

              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="AddFail == true" v-on:alert-closed="AddFail = false" :isWarning="AddFail" :msg="errorMsg"/>
                    <Alert v-if="AddDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!AddDone" msg='Client Subscribed Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Add">
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
</div>
</template>

<script>
import Alert from '../../components/Alert.vue'
import ClientPage from '../../components/NewClient'
    export default {
        name: 'login',
        components: {
            Alert,
            ClientPage,
          },
    data(){
        return{
            form: { },
            msg: '',
            isHidden: true,
            AddFail: false,
            AddDone: false,
            errorMsg: 'Add failed, client not subscribed'
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    async AddClient(){
      await this.$store.dispatch('addClient', this.form)
        .then(resp => {
          console.log(resp);
          if(resp.data.error){
            this.errorMsg = resp.data.error.message;
            this.AddFail = true;
          }
          else{this.AddDone = true}
          }).catch(() => this.AddFail = true)
},
}

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
