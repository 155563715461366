<template>
  <tr>
    <td>{{index}}</td>
    <td>{{user.ProviderName}}</td>
    <td>{{user.ProviderContact}}</td>
    <td><a :href="src" class="icon-map-marker" style="color: red" target="_blank"></a> {{user.ProviderAddress}}, {{user.ProviderCity}}</td>
    <td>{{user.UserId}}</td>
    <td><button class="btn-inv icon-settings" v-on:click="getProvider(user)" hint="more"></button></td>
</tr>
</template>

<script>
    export default {
    name: 'ProviderLine',
    props:{
      user: Object,
      index: Number
    },
    data(){
        return{
          src: ''
        }
    },
    methods:{
      getProvider: function(user){
      this.$router.push({
          name: 'ProviderPage',
          params: {
              Provider: user
          }
      })
      },
      },
    mounted(){
      this.src = "http://www.google.com/maps/place/" + String(this.user.ProviderX) + "," + String(this.user.ProviderY)
    },
    }
</script>

