<template>
  <tr>
    <td><span class="btn-inv icon-circle" style="color: red; font-size: 20px"></span>
      {{index}}
    </td>
    <td>{{status[0]}}</td>
    <td> </td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectBuyer"
                          :value.sync="value"
                          :options="SelectBuyer"
                          >
          </v-select></td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select></td>
    <td><input type="Number" placeholder="Quantity" v-model="order.Quantity" length="any"> <v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          >
          </v-select></td>
    <td> </td>
    <td><button class="btn-inv icon-plus" v-on:click="SendOrder()" title="add order"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'TrackMandiLine',
        components: {
          // ToggleSubscription
    },
    props:{
      index: Number,
    },
    data(){
        return{
            order: {},
            status:['Initiated', 'InProcess', 'Fulfilled', 'Delivered'],
            changeStatus: false,
            loading: false,
            buyers: [],
            items: [],
            units: []
        }
    },
    methods:{
    refreshOrder(){
      this.loading = true;
      this.$emit('update');
    },
    OnChangeSelectItem(e){
          this.order.ItemId = e.value
        },
    OnChangeSelectUnit(e){
          this.order.UnitId = e.value
        },
    OnChangeSelectBuyer(e){
          this.order.BuyerId = e.value
        },
    SendOrder(){
      this.order.Quantity = Number(this.order.Quantity);
      this.$store.dispatch('addOrder', this.order).then(() => {
        this.$alert("Order added!");
        this.$emit('update');}).catch(err => {
          this.$alert(err.error.message);
        });
    }
    },
    mounted(){
      this.loading = true;
      this.$store.dispatch('getAllBuyers').then(resp => {this.buyers = resp.data.data.buyers;
        this.loading = false});
      this.$store.dispatch('getAllItemsNames').then(resp =>
                                           {this.items = resp.data.data.items
                                            });
      this.$store.dispatch('getAllUnits').then(resp => this.units = resp.data.data.units)
    },
    computed:{
    SelectUnit(){
      return this.units.map(g => ({label: g.UnitName, value: g.UnitId}))
    },
    SelectItem(){
      return this.items.map(g => ({label: g.ItemName, value: g.ItemId}))
    },
    SelectBuyer(){
      return this.buyers.map(g => ({label: g.BuyerName + ', ' + g.BuyerCity, value: g.BuyerId}))
    }
  }
  }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
