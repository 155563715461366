<template>
  <tr>
    <td v-if="Type!='MandiTrack'"><span v-if="Flags.includes(Rate.MandiId)" class="icon-flag" style="color: red"></span>{{Rate.RecordID}}</td>
    <td v-else ><span v-if="Flags.includes(Rate.MandiId)" class="icon-flag" style="color: red"></span>{{index}}</td>
    <td v-if="Type=='Item'">{{Rate.MandiName}}</td>
    <td v-else>{{ItemName}}</td>
    <td v-if="retailOnly!=1">{{Rate.MinRate}}</td>
    <td v-if="retailOnly==1 && Type=='Item'"><i class="material-icons">&#x274C;</i></td>
    <td v-if="retailOnly!=1">{{Rate.MaxRate}}</td>
    <td v-if="retailOnly==1 && Type=='Item'"><i class="material-icons">&#x274C;</i></td>
    <td v-if="retailOnly!=2 && Rate.RetailRate">{{Rate.RetailRate}}</td>
    <td v-if="retailOnly!=2 && !Rate.RetailRate">None</td>
    <td v-if="retailOnly==2 && Type=='Item'"><i class="material-icons">&#x274C;</i></td>
    <td>{{Rate.UnitName}}</td>
    <td>{{DisplayTime(Rate.RecordTime)}}</td>
    <td v-if="Rate.Prediction && Type=='MandiTrack'">{{Rate.Prediction}}</td>
    <td v-if="Rate.PredictionTime && Type=='MandiTrack'">{{DisplayTime(Rate.PredictionTime)}}</td>
    <td v-if="!Rate.Prediction && Type=='MandiTrack'">None</td>
    <td v-if="!Rate.PredictionTime && Type=='MandiTrack'">None</td>
    <td>{{Rate.FirstName}} {{Rate.LastName}}</td>
</tr>
</template>
<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'RateLine',
        components: {
          // ToggleSubscription
    },
    props:{
      Rate: Object,
      Type: String,
      ItemName: String,
      index: Number,
      retailOnly: Number,
      Flags: Array
    },
    data(){
        return{
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    DisplayTime(record){
      if(record=='None'){
        return record
      }
      let date = record.substring(0, 10);
      let day = date.substring(0,3);
      let time = record.substring(10);
      let hour = Number(time.substring(0,3)) + 5;
      if (hour > 24){
        hour = hour % 24;
        day = Number(day) + 1;
      }
      let Dhour = String(hour);
      if(hour < 10){
        Dhour = '0' + Dhour
      }
      return String(day) + date.substring(3) + ' ' + Dhour + time.substring(3)

    }
  }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
