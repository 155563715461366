<template>
  <form v-on:submit.prevent="ChangeName" class="container" style="margin-left: -7px">
      <div class="form-group row">
        <div class="col-md-12">
        <p v-if="Type=='Name'">Enter Client Name:</p>
        <p v-if="Type=='District'">Enter Client District:</p>
        <p v-if="Type=='Locale'">Enter Client Locale:</p>
      </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Client Name" v-model="form.ClientName" v-on:change="ChangeFormEmit">
        </div>
      </div>

       <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
          <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Confirm" style="background-color: red; border-color: black">
        </div>
      </div>

    </form>
</template>

<script>
    export default {
        name: 'changeMandi',
        components: {
    },
    props:{
      ClientInfo: Object,
      Type: String
    },
    data(){
        return{
            form:{},
            Mandis: Object,
            gotMandis: false,
        }
    },
    methods:{
        ChangeFormEmit(){
            this.$emit('form-updated', this.form)
        },
        ChangeName(){
          let nameForm = this.form;
          let alert = ""
          if(this.Type == 'Name'){
            alert = "Change name from "+ this.ClientInfo.ClientName+" to "+this.form.ClientName+"?"
          }
          if(this.Type == 'District'){
            alert = "Change district from "+ this.ClientInfo.ClientDistrict+" to "+this.form.ClientName+"?"
          }
          if(this.Type == 'Locale'){
            alert = "Change locale from "+ this.ClientInfo.ClientLocale+" to "+this.form.ClientName+"?"
          }
          this.$confirm(alert).then(() =>{
          let ChangeForm ={
            'ClientNumber': this.ClientInfo.ClientNumber,
            'form': nameForm
          };
          this.$store.dispatch('editClientById', ChangeForm).then(() =>  {
            this.$alert("Client "+ this.Type +" Changed!");
          this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientNumber: this.ClientInfo.ClientNumber,
          }
          })}).catch(() =>  this.$alert("Error! Client "+ this.Type +" did not change"))
          }).catch(() =>{
             this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientInfo: this.ClientInfo,
          }
          })
        })
        }
    },
    created () {
        this.form = this.ClientInfo;
        this.$store.dispatch('getAllMandis').then(resp =>
                                                  {this.Mandis = resp.data.data.mandis;
                                                    this.gotMandis = true;});

  },
  computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: String(g.MandiName)+', '+String(g.City), value: g.MandiId}))
    },
  }
}
</script>

