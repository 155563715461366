<template>
  <tr>
    <td><span v-if="user.ProducerType=='Small'" class="btn-inv icon-square" style="color: blue; font-size: 20px"></span>
      <span v-if="user.ProducerType=='Medium'" class="btn-inv icon-square" style="color: green; font-size: 20px"></span>
      <span v-if="user.ProducerType=='Industrial'" class="btn-inv icon-square" style="color: red; font-size: 20px"></span>
      {{index}}
    </td>
    <td>{{user.ProducerName}}</td>
    <td>{{user.ProducerType}}</td>
    <td>{{user.ProducerContact}}</td>
    <td><a :href="src" class="icon-map-marker" style="color: red" target="_blank"></a> {{user.ProducerAddress}}, {{user.ProducerCity}}</td>
    <td>{{user.UserName}}</td>
    <td><button class="btn-inv icon-settings" v-on:click="seeProducer(user)"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'MandiLine',
        components: {
    },
    props:{
      user: Object,
      index: Number
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            items: Object,
            src: ''

        }
    },
    mounted(){
      this.src = "http://www.google.com/maps/place/" + String(this.user.ProducerX) + "," + String(this.user.ProducerY)
    },
    methods:{
    seeProducer: function(user){
        this.$router.push({
          name: 'ProducerPage',
          params: {
              Producer: user,
          }
      })
      }
      },
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
