<template>
  <div id="AllSubscriptions">
    <div class="container col-lg-12">
    <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row mb-3">
                    <div class="col-xs-4">
                        <h2>Mandis <b>Management</b></h2>
                    </div>
                    <div class="col-xs-4">
                        <button v-on:click="goTo('/admin/add-mandi')" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Mandi</span></button>
                    </div>
                    <div class="col-xs-4">
                        <form class="search-form" action="" style="margin-top: -1px; margin-left: 20px">
                          <input class="search-box" v-model="search" placeholder="Search Name..." style="font-size: 14px;">
                          <i class="faa icon-search" style="font-size: 14px"></i>
                        </form>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-xs-7">
                        <button v-on:click="sort=mandiTypeOptions[3]; sortNormalOn = false; sortRetailOn=false; sortBulkOn=false; sortSourceOn=!sortSourceOn" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[3] && sortSourceOn">
                            <span class="btn-inv icon-star" style="color: green"></span> {{mandiTypeOptions[3]}}</span><span v-else><span class="btn-inv icon-star" style="color: green"></span> {{mandiTypeOptions[3]}}</span></button>


                        <button v-on:click="sort=mandiTypeOptions[2]; sortNormalOn = !sortNormalOn; sortRetailOn=false; sortBulkOn=false; sortSourceOn=false" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[2] && sortNormalOn">
                            <span class="btn-inv icon-star" style="color: black"></span> {{mandiTypeOptions[2]}}</span><span v-else><span class="btn-inv icon-star" style="color: black"></span> {{mandiTypeOptions[2]}}</span></button>

                        <button v-on:click="sort=mandiTypeOptions[1]; sortRetailOn = !sortRetailOn; sortBulkOn=false; sortNormalOn=false; sortSourceOn=false" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[1] && sortRetailOn"><span class="btn-inv icon-star" style="color: blue"></span>{{mandiTypeOptions[1]}}</span><span v-else><span class="btn-inv icon-star" style="color: blue"></span>{{mandiTypeOptions[1]}}</span></button>

                        <button v-on:click="sort=mandiTypeOptions[0]; sortBulkOn = !sortBulkOn; sortRetailOn=false; sortNormalOn=false; sortSourceOn=false" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[0] && sortBulkOn">{{mandiTypeOptions[0]}}</span><span v-else>{{mandiTypeOptions[0]}}</span></button>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>City</th>
                        <th>District</th>
                        <th>Location</th>
                    </tr>
                </thead>
                 <tbody>
                  <AddMandiLine v-if="AddMandiForm" :index="index" v-on:mandi-added="refreshMandis()"/>
                </tbody>
                <tbody v-for="mandi in filteredList" :key="mandi.MandiId">
                    <MandiLine :mandi="mandi" :Units="Units" :index="mandi.MandiId"/>
                </tbody>
            </table>
        </div>
    </div>
</div>

      </div>
</template>

<script>
import MandiLine from '../../components/MandiLine'
import AddMandiLine from '../../components/NewMandiLine'
    export default {
        name: 'AllSubscriptions',
        components: {
      MandiLine,
      AddMandiLine
    },
    props:{
    },
    data(){
        return{
            mandis: [],
            msg: '',
            AddFail: false,
            AddDone: false,
            StatusFlag: false,
            Loading: true,
            search: "",
            AddMandiForm: false,
            sort: String,
            mandiTypeOptions: ['Normal (Bulk + Retail)', 'Retail Only', 'Bulk Only', 'Source Only'],
            sortNormalOn: false,
            sortRetailOn: false,
            sortBulkOn: false,
            sortSourceOn: false,
            color: '#ff7f50',
            bulkList: [],
            bulkMandis: [],
            Units: []
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    refreshMandis(){
    this.Loading = true;
    this.$store.dispatch('getAllMandis').then(resp => {this.mandis = resp.data.data.mandis;
        this.Loading=false});
    this.AddMandiForm=false;
  },
    },
    mounted(){
      this.$store.dispatch('getAllMandis').then(resp => {
        this.mandis = resp.data.data.mandis;
        this.bulkMandis = resp.data.data.mandis.filter(mandi => {return mandi.RetailOnly==2});
        this.Loading=false});
      this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units);
    },
    computed : {
        filteredList() {
        var mandiSub = {};
        if(!this.Loading)
        {
            if(!this.sortRetailOn && !this.sortNormalOn && !this.sortBulkOn && !this.sortSourceOn)
            {
                return this.mandis.filter(mandi => {
                return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase())
              })
            }
            else{
                if(this.sort==this.mandiTypeOptions[0])
                {
                    mandiSub = this.mandis.filter(mandi => {return mandi.RetailOnly==0});
                }
                else if(this.sort==this.mandiTypeOptions[1]){
                    mandiSub = this.mandis.filter(mandi => {return mandi.RetailOnly==1});
                }
                else if(this.sort==this.mandiTypeOptions[2]){
                    mandiSub = this.mandis.filter(mandi => {return mandi.RetailOnly==2});
                }
                else{
                    mandiSub = this.mandis.filter(mandi => {return mandi.RetailOnly==3});
                }
                return mandiSub.filter(mandi => {
                return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase())
              })
            }
        }
        else{
            return {}
        }
    },
    },
    }
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
        color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
    border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 13px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
</style>
