<template>
  <div id="AllClients">
    <div>

      <div class="row" v-if="type=='to' || edit_to">
        <div class="col-lg-4">
          From:
          <br>
        <v-select class="btn-vselect" v-on:input="OnChangeSelectFrom"
                          :options="SelectNode"
                          >
          </v-select>
          <br>
        </div>
        <div class="col-lg-4">
          Truck:
          <v-select class="btn-vselect" v-on:input="OnChangeSelectTruck"
                          :options="SelectTruck"
                          >
          </v-select>
          <br>
          | <span class="line"></span> >>>
          <br>
          <br>
          <input type="number" class="form-control" placeholder="Cost" v-model="newRoute.RouteCost">
        </div>
        <div class="col-lg-4">
          To:
          <br>
        {{node.NodeCity}}, {{node.NodeLocale}}  <button v-on:click="addRoute()" class="btn-inv" title="Add Route"><i class="material-icons">&#xE147;</i></button>
        <br>
        </div>
      </div>

        <div class="row" v-if="type=='from' || edit_from">
        <div class="col-lg-4">
          From:
          <br>
        {{node.NodeCity}}, {{node.NodeLocale}}
          <br>
        </div>
        <div class="col-lg-4">
          Truck:
          <v-select class="btn-vselect" v-on:input="OnChangeSelectTruck"
                          :options="SelectTruck"
                          >
          </v-select>
          <br>
          | <span class="line"></span> >>>
          <br>
          <br>
          <input type="number" class="form-control" placeholder="Cost" v-model="newRoute.RouteCost">
        </div>
        <div class="col-lg-4">
          To:
          <br>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectTo"
                          :options="SelectNode"
                          >
          </v-select> <button v-on:click="addRoute()" class="btn-inv" title="Add Route"><i class="material-icons">&#xE147;</i></button>
        <br>
        </div>
      </div>


      <div v-if="type=='display-to' && !edit_to" class="row mb-3" style="color: black">
        <div class="col-lg-3">
          <br>
         {{route.StartNode.NodeCity}}, {{route.StartNode.NodeLocale}}
          <br>
        </div>
        <div class="col-lg-6">
          {{route.Truck.TruckName}} (upto {{route.Truck.TruckMaxLoad}} MT)
          <br>
          | <span class="line"></span> >>>
          <br>
          Cost: {{route.RouteCost}} Rs.
        </div>
        <div class="col-lg-3">
          <br>
          <span style="color: green;">{{node.NodeCity}}, {{node.NodeLocale}}</span> <button v-on:click="editRoute()" class="btn-inv icon-settings" title="edit Route"></button>
        <br>
        </div>
      </div>

      <div v-if="type=='display-from' && !edit_from" class="row mb-3" style="color: black">
        <div class="col-lg-4">
          <br>
        <span style="color: green;">{{node.NodeCity}}, {{node.NodeLocale}}</span>
          <br>
        </div>
        <div class="col-lg-4">
          {{route.Truck.TruckName}} (upto {{route.Truck.TruckMaxLoad}} MT)
          <br>
          | <span class="line"></span> >>>
          <br>
          Cost: {{route.RouteCost}} Rs.
        </div>
        <div class="col-lg-4">
          <br>
          {{route.EndNode.NodeCity}}, {{route.EndNode.NodeLocale}} <button v-on:click="editRoute()" class="btn-inv icon-settings" title="edit Route"></button>
        <br>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
    export default {
        name: 'NodeRoute',
        components: {
    },
    props:{
      route: Object,
      type: String,
      node: Object
    },
    data(){
        return{
          nodes: [],
          newRoute: {},
          trucks: [],
          edit_from: false,
          edit_to: false

        }
    },
    mounted(){
      if(this.type=='to' || this.type=="from"){
        this.$store.dispatch('getAllNodes').then(resp => {
            this.nodes = resp.data.data.nodes;});
        this.$store.dispatch('getAllTrucks').then(resp => {
            this.trucks = resp.data.data.trucks})
      }
      
    },
    methods:{
      OnChangeSelectFrom(e){
          this.newRoute.StartNodeId = e.value;
          this.newRoute.EndNodeId = this.node.NodeId
        },
      OnChangeSelectTo(e){
          this.newRoute.EndNodeId = e.value;
          this.newRoute.StartNodeId = this.node.NodeId
        },
       OnChangeSelectTruck(e){
          this.newRoute.TruckId = e.value;
        },
      addRoute(){
        this.$store.dispatch('addRoute', this.newRoute).then(() => {
          this.$emit('added');
          }).catch(() => {
            this.$alert("Error adding route!");
          })
      },
      editRoute(){
        this.$store.dispatch('getAllNodes').then(resp => {
            this.nodes = resp.data.data.nodes;});
        this.$store.dispatch('getAllTrucks').then(resp => {
            this.trucks = resp.data.data.trucks});
        this.newRoute.RouteCost = this.route.RouteCost;
        this.newRoute.TruckId = this.route.Truck.TruckId;
        if(this.type=="display-from"){
          this.edit_from = true;
          this.newRoute.EndNodeId = this.route.EndNode.NodeId;
          this.newRoute.StartNodeId = this.node.NodeId;
        }
        else{
          this.edit_to = true;
           this.newRoute.StartNodeId = this.route.StartNode.NodeId;
           this.newRoute.EndNodeId = this.node.NodeId;
        }
      }
      },
     computed:{
    SelectNode(){
      return this.nodes.map(g => ({label: g.NodeCity + ", " + g.NodeLocale, value: g.NodeId})) || ""
    },
    SelectTruck(){
      return this.trucks.map(g => ({label: g.TruckName + "(upto " + g.TruckMaxLoad + " MT)", value: g.TruckId})) || ""
    },
  }
    }
</script>

<style>
.line {
  width: 150%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 1px;
  display:inline-block;
  outline-color:  black;
}
</style>
