<template>
  <div id="login">
  <div class="section last-section">

    <div class="form-group row">
        <div class="col-md-12">
        <h3><b>Select Item:</b></h3>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
        <v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select>
      </div>
    </div>

    <div class="form-group row">
    <div class="col-md-12">
        <h3><b>Amount:</b></h3>
    </div>
    </div>
    <div class="form-group row">
        <div class="col-md-6">
         <input type="Number" placeholder="Quantity" v-model="order.Quantity" length="any">
         </div>
         <div class="col-md-6">
         <v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          >
          </v-select>
      </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
        <h3><b>Buyer:</b></h3>
        </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
        <v-select class="btn-vselect" v-on:input="OnChangeSelectBuyer"
                          :value.sync="value"
                          :options="SelectBuyer"
                          >
          </v-select>
      </div>
    </div>

    <div class="form-group row">
    <div class="col-md-12">
        <h3><b>Delivery Details:</b></h3>
    </div>
    </div>
    <div class="form-group row">
        <div class="col-md-6">
         <input type="text" placeholder="Delivery Address" v-model="order.DeliveryAddress" length="any">
      </div>
      <div class="col-md-6">
         <input type="text" placeholder="Delivery City" v-model="order.DeliveryCity" length="any">
      </div>
    </div>

    <div class="form-group row">
    <div class="col-md-12">
        <h3><b>Estimated Delivery Date:</b></h3>
    </div>
    </div>
    <div class="form-group row">
        <div class="col-md-12">
         <input type="date" placeholder="Delivery Date" v-model="order.DeliveryDate">
      </div>
    </div>

    <div class="form-group row justify-content-center py-3">
    <br>
    <br>
        <div class="col-md-4">
            <button class="icon-plus btn btn-primary py-3 px-5 btn-block" title="add" v-on:click="addOrder()"></button>
        </div>
    </div>


      </div>
</div>
</template>

<script>
    export default {
        name: 'NewOrder',
        components: {
          },
    data(){
        return{
          loading: true,
          refresh: false,
          state: 0,
          buyers: [],
          items: [],
          units: [],
          order: {},
        }
    },
    methods:{
    OnChangeSelectItem(e){
          this.order.ItemId = e.value
        },
    OnChangeSelectUnit(e){
          this.order.UnitId = e.value
        },
    OnChangeSelectBuyer(e){
          this.order.BuyerId = e.value
        },
    addOrder(){
        this.order.Quantity = Number(this.order.Quantity);
      this.$store.dispatch('addOrder', this.order).then(() => {
        this.$alert("Order added!");
        this.$store.dispatch('goTo', '/admin/all-orders');
        }).catch(err => {
          this.$alert(err.error.message);
        });
    }
},
    mounted(){
      this.loading = true;
      this.$store.dispatch('getAllBuyers').then(resp => {this.buyers = resp.data.data.buyers;
        this.loading = false});
      this.$store.dispatch('getAllItemsAllNames').then(resp =>
                                           {this.items = resp.data.data.items
                                            });
      this.$store.dispatch('getAllUnits').then(resp => this.units = resp.data.data.units)
    },
    computed:{
    SelectUnit(){
      return this.units.map(g => ({label: g.UnitName, value: g.UnitId}))
    },
    SelectItem(){
      return this.items.map(g => ({label: g.ItemName, value: g.ItemId}))
    },
    SelectBuyer(){
      return this.buyers.map(g => ({label: g.BuyerName + ', ' + g.BuyerCity, value: g.BuyerId}))
    }
  }

  }
</script>

