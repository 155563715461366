<template>
  <tr>
    <td><span v-if="mandi.RetailOnly==1" class="btn-inv icon-star" style="color: blue"></span>
      <span v-if="mandi.RetailOnly==2" class="btn-inv icon-star" style="color: black"></span>
      <span v-if="mandi.RetailOnly==3" class="btn-inv icon-star" style="color: green"></span>{{index}}
    </td>
    <td style="text-align: left">{{mandi.MandiName}}</td>
    <td>{{mandi.City}}</td>
    <td>{{mandi.District}}</td>
    <td><a :href="src" class="icon-map-marker" style="color: red" target="_blank"></a></td>
    <td v-if="user!='Operator' && mandi.RetailOnly!=3"><button class="btn-inv" v-on:click="goToMandiRate(mandi.MandiName, mandi.MandiId)">Rates</button></td>
    <td v-if="user!='Operator' && mandi.RetailOnly!=3"><button class="btn-inv" v-on:click="goToMandiPredict(mandi.MandiName, mandi.MandiId)">Predictions</button></td>
    <td><button class="btn-inv icon-settings" v-on:click="EditMandi(mandi)"></button></td>
    <td v-if="mandi.RetailOnly!=3"><button class="btn-inv" v-on:click="download()" title="Download Empty"><span class="icon-download"></span></button></td>
    <td v-if="mandi.RetailOnly!=3"><input style="margin-left: 20px; margin-top: 1px;" type="file" ref="file" @change="upload()" title="Upload Filled"/></td>
</tr>
</template>

<script>
import XLSX from 'xlsx'
    export default {
        name: 'MandiLine',
        components: {
    },
    props:{
      mandi: Object,
      index: Number,
      user: String,
      Units: Array
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            items: Object,
            src: '',
            submittedFile: Object,
            Predictions: ['Up', 'Same', 'Down'],

        }
    },
    mounted(){
      this.src = "http://www.google.com/maps/place/" + String(this.mandi.MandiX) + "," + String(this.mandi.MandiY)
    },
    methods:{
      async goToMandiRate(mandiName, mandiId){
        await this.getItems(mandiId)
      this.$router.push({
          name: 'AllItemRates',
          params: {
              Items: this.items,
              itemName: "",
              Type: "Mandi",
              mandiName: mandiName,
              mandiId: mandiId,
              retailOnly: this.mandi.RetailOnly,
              user: this.user
          }
      })
    },
    async goToMandiPredict(mandiName, mandiId){
        await this.getItems(mandiId)
      this.$router.push({
          name: 'AllItemPredictions',
          params: {
              Items: this.items,
              itemName: "",
              Type: "Mandi",
              mandiId: mandiId,
              mandiName: mandiName,
              user: this.user
          }
      })
    },
    async getItems(MandiId){
      await this.$store.dispatch("getMandiItems", MandiId).then(resp => this.items = resp.data.data.items)
      console.log(this.items)
    },
    EditMandi: function(mandi){
        this.$router.push({
          name: 'EditMandi',
          params: {
              Mandi: mandi,
          }
      })
    },
    download(){
        this.$store.dispatch('downloadMandiSheet', this.mandi.MandiId)
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', this.mandi.MandiName +'_empty.csv'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    upload: async function(){
        await this.submitFile();
        this.checkSubmittedRates();
    },
    submitFile: function(){
        let file = this.$refs.file.files[0];
        const types = file.name.split('.')[1];
          const fileType = [
            'xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'
          ].some(item => item == types);
          if (!fileType) {
                 alert('Format error! Please reselect');
            return
          }
          console.log('known type')

          const reader = new FileReader();
          let result = [];
          reader.onload = function(e) {
            const data = e.target.result;
            const wb = XLSX.read(data, {
              type: 'binary'
            });
            wb.SheetNames.forEach((sheetName) => {
                console.log(sheetName);
                result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
              })
            });
          };
          reader.readAsBinaryString(file);
          this.submittedFile = result;
          return
    },
    checkSubmittedRates(){
        if(this.submittedFile.length > 0){
            let filledRates = [];
            let WrongRates = [];
            let correctRates = [];
            let wrongFileFlag = false;
            let filledPredictions = this.submittedFile[0].sheet.filter(rate => { return this.Predictions.includes(rate.Prediction)});
            if(this.mandi.RetailOnly == 0){
                filledRates = this.submittedFile[0].sheet.filter(rate => { return (rate.MinRate && rate.MaxRate && rate.RetailRate)});
                WrongRates = filledRates.filter(rate => { return (rate.MinRate <= 0 || rate.MaxRate <= 0 || rate.RetailRate <= 0 || rate.MaxRate < rate.MinRate || !this.SelectUnit.includes(rate.UnitName) )})
            }
            if(this.mandi.RetailOnly == 1){
                filledRates = this.submittedFile[0].sheet.filter(rate => { return (rate.RetailRate)});
                if(filledRates.filter(rate => {return rate.MinRate || rate.MaxRate}).length > 0){
                    wrongFileFlag = true;
                }
                WrongRates = filledRates.filter(rate => { return (rate.RetailRate <= 0 || !this.SelectUnit.includes(rate.UnitName) )})
            }
            if(this.mandi.RetailOnly == 2){
                filledRates = this.submittedFile[0].sheet.filter(rate => { return (rate.MinRate && rate.MaxRate)});
                if(filledRates.filter(rate => {return rate.RetailRate}).length > 0){
                    wrongFileFlag = true;
                }
                WrongRates = filledRates.filter(rate => { return (rate.MinRate <= 0 || rate.MaxRate <= 0 || rate.MaxRate < rate.MinRate ||!this.SelectUnit.includes(rate.UnitName))})
            }
            if(filledRates.length == 0 || wrongFileFlag || WrongRates.length == filledRates.length){
                this.$alert("You have not entered any valid rates or you have uploaded a file for a different mandi. Please check your file and try again");
                // this.$router.go();
            }
            else{
                if(WrongRates.length > 0){
                    let ratesError = ""
                     WrongRates.forEach(rate => {
                        ratesError = ratesError + String(rate.ItemName) + ", "
                    });
                    this.$confirm("You have entered "+ filledRates.length+" rates + "+ filledPredictions.length + " predictions. The following "+ WrongRates.length + " rates have errors: \n" + ratesError + ". Proceed to sending the valid rates/predictions?").then(() => {
                        correctRates = filledRates.filter(rate => {return !WrongRates.includes(rate)});
                        this.viewUploadedRates(correctRates, filledPredictions)
                    })
                }
                else{
                    this.$confirm("You have entered "+ filledRates.length+" valid rates, and "+ filledPredictions.length + " valid predictions. send the rates/predictions?").then(() => {
                        correctRates = filledRates;
                        this.viewUploadedRates(correctRates, filledPredictions)
                    })
                }
            }

        }
        else{
            setTimeout(this.checkSubmittedRates, 5);
        }
    },
    viewUploadedRates(correctRates, filledPredictions){
        console.log(correctRates);
        let ratesList = [];
        let predictList = filledPredictions.map(g => ({ItemId: g.ItemId, Prediction: g.Prediction, MandiId: this.mandi.MandiId}));
        if(this.mandi.RetailOnly == 0){
            ratesList = correctRates.map(g => ({ItemId: g.ItemId, MinRate: g.MinRate, MaxRate: g.MaxRate, RetailRate: g.RetailRate, MandiId: this.mandi.MandiId, UnitId: this.findUnit(g.UnitName)}))
        }
        if(this.mandi.RetailOnly == 1){
            ratesList = correctRates.map(g => ({ItemId: g.ItemId, MinRate: -1, MaxRate: -1, RetailRate: g.RetailRate, MandiId: this.mandi.MandiId, UnitId: this.findUnit(g.UnitName)}))
        }
        if(this.mandi.RetailOnly == 2){
            ratesList = correctRates.map(g => ({ItemId: g.ItemId, MinRate: g.MinRate, MaxRate: g.MaxRate, MandiId: this.mandi.MandiId, UnitId: this.findUnit(g.UnitName)}))
        }
        let multiForm = {
            'rates': ratesList,
            'predictions': predictList
        };
        this.$store.dispatch('addMulti', multiForm).then(resp => {
            console.log(resp);
            this.$alert(resp.data.rates+ ' / ' + resp.data.predictions);
        }).catch(err => {
            this.$alert(err)
        });
    },
    findUnit(unitName){
        for(var x in this.Units){
            if(this.Units[x].UnitName == unitName) return this.Units[x].UnitId
          }
    },
  },
  computed:{
    SelectUnit(){
      return this.Units.map(g => (g.UnitName))
    },
  }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
