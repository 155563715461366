<template>
  <div id="main-user">
        <h2 class="section-title">Change Password</h2>

        <Alert v-if="changeFail == true" v-on:alert-closed="changeFail = false" :isWarning="changeFail" :msg="'Oops! ' + errorMsg"/>
        <Alert v-if="changeDone == true" v-on:alert-closed="logOut()" :isWarning="!changeDone" msg='Password Changed Successfully! Please log-in with your new password'/>

        <form v-on:submit.prevent="changePassword">
        <div class="form-group row">
          <div class="col-md-12">
            <input v-if="isHiddenOld" required type="password" class="form-control" placeholder="Old Password" v-model="form.Old">
         <input v-else type="text" required class="form-control" placeholder="Old Password" v-model="form.Old">
          <a v-on:click="isHiddenOld = !isHiddenOld" class="fa fa-fw fa-eye field-icon toggle-password icon-eye"></a>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <input v-if="isHiddenNew" required type="password" class="form-control" placeholder="New Password" v-model="form.New">
         <input v-else type="text"  required class="form-control" placeholder="New Password" v-model="form.New">
          <a v-on:click="isHiddenNew = !isHiddenNew" class="fa fa-fw fa-eye field-icon toggle-password icon-eye"></a>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <input v-if="isHiddenAgain" required type="password" class="form-control" placeholder="Confirm New Password" v-model="form.Again">
         <input v-else type="text" required class="form-control" placeholder="Confirm New Password" v-model="form.Again">
          <a v-on:click="isHiddenAgain = !isHiddenAgain" class="fa fa-fw fa-eye field-icon toggle-password icon-eye"></a>
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-4"/>
          <div class="col-md-4">
            <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Change Password">
          </div>
        </div>
      </form>

  </div>
</template>



<script>
import Alert from '../../components/Alert.vue';

    export default {
    name: 'changePassword',
   components: {
    Alert,
    },
    props:{
    },
    data(){
        return{
          form: {
              Old: "",
              New: "",
              Again: "",
            },
          changeDone: Boolean,
          changeFail: Boolean,
          errorMsg: '',
          isHiddenOld: true,
          isHiddenNew: true,
          isHiddenAgain: true,
        }
    },
  mounted() {
  },
  methods: {
    goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    changePassword(){
        this.$store.dispatch('changePassword', this.form)
       .then(resp =>
         {
          if (resp.data.error)
          {
            this.errorMsg = resp.data.error.message;
            this.changeFail = true;
          }
          else{
            this.changeDone = true;
            setTimeout( () => {this.$store.dispatch('logout').then(() => this.goTo('/login'))} , 2000);
          }
          }).catch(err => {
        this.changeFail = true;
        this.errorMsg = err.data;
       })
        },
    logOut(){
      this.changeDone = false;
      this.$store.dispatch('logout').then(() => this.goTo('/login'));
    }
  }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700,900')*/
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
