<template>
  <tr>
    <!-- <td>{{index}}</td> -->
    <td>{{Item.ItemUrduName}}</td>
    <td>{{ItemName}}</td>
    <td v-if="retailOnly!=1"><input type="number" style="width: 100px" :placeholder="UrduNames.MinRate" v-model="form.MinRate" v-on:change="CheckBox()"></td>
    <td v-if="retailOnly!=1"><input type="number" style="width: 110px" :placeholder="UrduNames.MaxRate" v-model="form.MaxRate" v-on:change="CheckBox()"></td>
    <td v-if="retailOnly!=2"><input type="number" :placeholder="UrduNames.RetailRate" style="width: 100px" v-model="form.RetailRate" v-on:change="CheckBox()"></td>
    <td v-if="Rate!=null">{{form.RecordTime}}</td>
    <td v-else> </td>
    <td>{{unit}} <br> <v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :options="SelectUnit"
                          >
          </v-select></td>
    <td><b v-if="Prediction!=''">{{Prediction}}</b><b v-else>غیر متعین</b> <br> <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectPredict"
                          :options="SelectPredict"
                          :v-model="Prediction"
                          v-on:change="OnChangeSelectPredict"
                          >
          </v-select></td>
    <td><button class="btn-inv icon-plus" v-on:click="AddRatePredict()"></button></td>
    <td><input type="checkbox" id="checkbox" v-model="checked" @change="!ckecked; CheckBox()"></td>
    <td v-if="retailOnly!=1"><button class="btn-inv" v-on:click="goToBidding()">Sources/Bidding</button></td>
</tr>
</template>
<script>
// import { Prop } from 'vue/types/options';
    export default {
        name: 'RateLineData',
    props:{
      Item: Object,
      ItemName: String,
      index: Number,
      retailOnly: Number,
      ItemId: Number,
      MandiId: Number,
      Units: Array,
      check: Boolean,
      mandiName: String
    },
    data(){
        return{
          Rate: {},
          Predict: {},
          form: {},
          refresh: false,
          predictions: [
            {value: 'Up', name: 'اوپر'},
            {value: 'Same', name: 'یہی'},
            {value: 'Down', name: 'نیچے '}
          ],
          Prediction: '',
          predictionForm: {},
          unit: "",
          UrduNames:{
                Fruit: 'پھل ',
                Vegetable: 'سبزی',
                Grain: 'اناج ',
                RetailRate: 'پرچون ریٹ',
                Date: 'تاریخ ',
                Prediction: 'پیشنگوئی',
                Name: 'نام',
                Unit: 'وحدت',
                MinRate: 'کم سے کم',
                MaxRate: 'زیادہسےزیادہ',
                Uncheck: 'براہ کرم چیک کو ہٹا دیں',
                UnitError: 'کے لئے یونٹ منتخب کریں اور باکس کو دوبارہ چیک کریں'
            },
          addRateReturn: String,
          test: false,
          checked: this.check
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    goToBidding(){
      this.$router.push({
          name: 'ItemSourcesManagement',
          params: {
              Item: this.Item,
              ItemName: this.ItemName,
              MandiId: this.MandiId,
              MandiName: this.mandiName
          }
      })
    },
    OnChangeSelectUnit(e){
          this.form.UnitId = e.value;
          this.CheckBox();
          console.log(e.label)
        },
    OnChangeSelectPredict(e){
          this.Prediction = e.value;
          this.$emit('prediction-updated', this.Prediction)
        },
    AdjustRate: function(rate){
      if(this.retailOnly==1)
      {
        rate.MaxRate = -1;
        rate.MinRate = -1;
      }
      else{
        rate.MaxRate = Number(rate.MaxRate);
        rate.MinRate = Number(rate.MinRate);
      }
      rate.RetailRate = Number(rate.RetailRate)
      if(this.retailOnly==2){
        rate.RetailRate = 0
      }
      return rate
    },
    AddRate: async function(old_rate){
      let rate = this.AdjustRate(old_rate);
      if(this.retailOnly!=1){
        if(rate.MaxRate <= 0 || rate.MinRate <= 0){
          this.addRateReturn = "Rate Not Added! Please enter positive rates!"
          return
        }
      }
      if(this.retailOnly!=2){
        if(rate.RetailRate <= 0){
          this.addRateReturn = "Rate Not Added! Please enter positive ratail rate!"
          return
        }
      }
      if(rate.MaxRate < rate.MinRate){
        this.addRateReturn = "Rate Not Added! Max Rate should be greater than min rate!"
        return
      }
      if(!rate.UnitId){
         this.addRateReturn = "Please Select a Unit!"
        return
      }
      else{
        console.log(rate);
        await this.$store.dispatch('addRate', rate)
        .then(() => {
          this.addRateReturn = "Rate added";
          // this.$alert("Rate added");
          }).catch(() => {
          this.addRateReturn = "Rate not added!";
            // this.$alert("Rate not added!")
          })
      }
      },
    AddRatePredict: async function(){
      this.predictionForm.ItemId = this.form.ItemId;
      this.predictionForm.MandiId = this.form.MandiId;
      this.predictionForm.Prediction = this.Prediction;
      console.log(this.form);
      await this.AddRate(this.form).then(() => {this.$store.dispatch('addPredict', this.predictionForm).then(() => {
        this.$alert(this.addRateReturn+" / "+"Prediction added\n");
      }).catch(() => {
        this.$alert(this.addRateReturn+" / "+"Prediction not added!\n")
      })
    });
      this.$emit('rate-added');
    },
    CheckBox(){
      let rate = this.AdjustRate(this.form);
      if(this.checked){
      if(isNaN(rate.MinRate) || isNaN(rate.MaxRate) || isNaN(rate.RetailRate)){
        this.$alert("Please enter rates for " + this.ItemName + " then check the box!");
        this.checked=false;
        this.$emit('checkErr')
        return
      }
      if(this.retailOnly!=1){
        if(rate.MaxRate <= 0 || rate.MinRate <= 0){
          this.$alert("Please enter positive rates for " + this.ItemName + " then check the box!")
          this.checkbox = false;
          this.$emit('checkErr');
          return
        }
      }
      if(this.retailOnly!=2){
        if(rate.RetailRate <= 0){
          this.$alert("Please enter positive rate for " + this.ItemName + " then check the box!")
          this.checkbox = false;
          this.$emit('checkErr');
          return
        }
      }
      if(rate.MaxRate < rate.MinRate){
        this.$alert("Please enter rates min<max for " + this.ItemName + " then check the box!")
        this.checkbox = false;
        this.$emit('checkErr');
        return
      }
      if(!rate.UnitId){
         this.$alert("Please select a unit for " + this.ItemName + " then check the box!")
        this.checkbox = false;
        this.$emit('checkErr');
        return
      }
      else{
          console.log('sending rate')
          this.$emit('add-rate-list', rate);
          let predictForm = {}
          predictForm.ItemId = this.form.ItemId;
          predictForm.MandiId = this.form.MandiId;
          predictForm.Prediction = this.Prediction;
          if(predictForm.Prediction!=""){
          this.$emit('add-predict-list', predictForm);
        }
        }
    }
    else{
      this.$emit('remove-item', this.form.ItemId);
    }
    return
    },
    DisplayTime(record){
      if(!record){
        return ''
      }
      if(record=='None' || record==''){
        return record
      }
      let date = record.substring(0, 10);
      let day = date.substring(0,3);
      let time = record.substring(10);
      let hour = Number(time.substring(0,3)) + 5;
      if (hour > 24){
        hour = hour % 24;
        day = Number(day) + 1;
      }
      let Dhour = String(hour);
      if(hour < 10){
        Dhour = '0' + Dhour
      }
      return String(day) + date.substring(3) + ' ' + Dhour + time.substring(3)
    }
  },
  mounted(){
    this.Rate = this.Item.LastRate[0];
    this.Predict = this.Item.LastPrediction[0];
    if(this.Rate){
    this.form.MinRate = this.Rate.MinRate;
    this.form.MaxRate = this.Rate.MaxRate;
    this.form.RetailRate = this.Rate.RetailRate;
    this.form.RecordTime = this.Rate.RecordTime;
    this.form.UnitId = this.Rate.UnitId;
    this.unit = this.Rate.UnitName;
  }
  if(this.Predict)
  {
    this.Prediction = this.Predict.Prediction
  }
    this.form.ItemId = this.ItemId;
    this.form.MandiId = this.MandiId;
    },
  computed:{
    SelectUnit(){
      return this.Units.map(g => ({label: g.UnitName, value: g.UnitId}))
    },
    SelectPredict(){
      return this.predictions.map(g => ({label: g.name, value: g.value}))
    },
  }
  }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
