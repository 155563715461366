<template>
<div>
  <div id="AllSubscriptions" class="section first-section row justify-content-center">
        <div class="col-lg-11">
            <h3>To upload the mandi rates with a file:</h3>
            <div class="container">
            <li>Download the empty rates file:
                <button v-on:click="download()" class="btn btn-primary" style="margin-left: 20px; margin-top: 1px;"><span class="icon-download" style="margin-right: 5px; color: black"></span>Download Empty</button>
            </li>
            <li>Open the file and fill in the rates for any number of items</li>
            <li>For each rate add one of the follwoing Units: <br>
                <span v-for="(unit,index) in SelectUnit" :key="index">
                    {{unit}},
                </span>
            </li>
            <li>To add a prediction write on the following: <br>
                Same, Up, Down
            </li>
            <li>Upload the filled file to submit your rates:
                <input style="margin-left: 20px; margin-top: 1px;" type="file" ref="file" @change="upload" title="upload"/>
                <span v-if="inProgress">Uploading: <ProgressBar :value='ProgressValue'/></span>
            </li>
        </div>
        </div>
    </div>


    <div id="AllSubscriptions" class="section row justify-content-center">
    <div class="col-lg-11">
    <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row">
                    <div class="col-xs-4">
                        <h2>{{mandiName}} <b>Rates</b> <span v-if="retailMandi==1">- Retail Only</span><span v-if="retailMandi==2">- Bulk Only</span></h2>

                    </div>
                    <div class="col-xs-3">
                        <button v-on:click="sort=''; sortFruitOn = false; sortGrainOn=false; sortVegeOn=false" class="btn btn-primary"><span style="color: red" v-if="!sortFruitOn&& !sortGrainOn && !sortVegeOn">{{UrduNames.all}}</span><span v-else>{{UrduNames.all}}</span></button>

                        <button v-on:click="sort='Fruit'; sortFruitOn = !sortFruitOn; sortGrainOn=false; sortVegeOn=false" class="btn btn-primary"><span style="color: red" v-if="sort=='Fruit' && sortFruitOn">{{UrduNames.Fruit}}</span><span v-else>{{UrduNames.Fruit}}</span></button>

                        <button v-on:click="sort='Vegetable'; sortGrainOn = !sortGrainOn; sortVegeOn=false; sortFruitOn=false" class="btn btn-primary"><span style="color: red" v-if="sort=='Vegetable' && sortGrainOn">{{UrduNames.Vegetable}}</span><span v-else>{{UrduNames.Vegetable}}</span></button>

                        <button v-on:click="sort='Grain'; sortVegeOn = !sortVegeOn; sortGrainOn=false; sortFruitOn=false" class="btn btn-primary"><span style="color: red" v-if="sort=='Grain' && sortVegeOn">{{UrduNames.Grain}}</span><span v-else>{{UrduNames.Grain}}</span></button>
                    </div>
                    <div class="col-xs-4">
                        <form class="search-form" action="" style="margin-top: -5px; margin-left: 20px">
                          <input class="search-box" v-model="search" placeholder="...تلاش نام" style="font-size: 14px;">
                          <i class="faa icon-search" style="font-size: 14px"></i>
                        </form>
                    </div>

                </div>
            </div>


            <table v-if="!Loading" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <!-- <th>#</th> -->
                        <th>{{UrduNames.Name}}</th>
                        <th>English</th>
                        <th v-if="retailOnly!=1">{{UrduNames.MinRate}}</th>
                        <th v-if="retailOnly!=1">{{UrduNames.MaxRate}}</th>
                        <th v-if="retailOnly!=2">{{UrduNames.RetailRate}}</th>
                        <th>{{UrduNames.Date}}</th>
                        <th>{{UrduNames.Unit}}</th>
                        <th>{{UrduNames.Prediction}}</th>
                        <th></th>
                        <th><button class="btn btn-primary py-1 px-1 btn-block" v-on:click="AddRatePredictList()">{{UrduNames.SendRates}}</button></th>
                        <th v-if="retailMandi!=1">Bidding</th>
                    </tr>
                </thead>
                <tbody v-for="item in filteredList" :key="item.ItemId">
                    <RateLine v-if="checkDate(item)" :Units="Units" :Item="item" :ItemName="item.ItemName" :index="item.ItemId" :retailOnly="retailMandi" :MandiId="mandiId" :ItemId="item.ItemId" :mandiName="mandiName" style="background-color: #1ed24c" v-on:rate-added="RefreshTable()" v-on:add-rate-list="AddToList($event)" v-on:add-predict-list="AddToPredicts($event)" v-on:remove-item="RemoveItem($event)"/>
                    <RateLine v-else :Units="Units" :Item="item" :ItemName="item.ItemName" :index="item.ItemId" :retailOnly="retailMandi" :MandiId="mandiId" :ItemId="item.ItemId" :mandiName="mandiName" v-on:rate-added="RefreshTable()" v-on:add-rate-list="AddToList($event)" v-on:add-predict-list="AddToPredicts($event)" v-on:remove-item="RemoveItem($event)"/>
                </tbody>
                <tr>
                        <th><button class="btn btn-primary py-1 px-1 btn-block" v-on:click="AddRatePredictList()">{{UrduNames.SendRates}}</button></th>
                        <th>English</th>
                        <th v-if="retailOnly!=1">{{UrduNames.MinRate}}</th>
                        <th v-if="retailOnly!=1">{{UrduNames.MaxRate}}</th>
                        <th v-if="retailOnly!=2">{{UrduNames.RetailRate}}</th>
                        <th>{{UrduNames.Date}}</th>
                        <th>{{UrduNames.Unit}}</th>
                        <th>{{UrduNames.Prediction}}</th>
                        <th></th>
                        <th><button class="btn btn-primary py-1 px-1 btn-block" v-on:click="AddRatePredictList()">{{UrduNames.SendRates}}</button></th>
                        <th v-if="retailMandi!=1">Bidding</th>
                    </tr>
            </table>

        </div>
    </div>
    </div>
  </div>
</div>
</template>

<script>
import XLSX from 'xlsx'
import RateLine from '../../components/RateLineData'
import ProgressBar from '../../components/ProgressElement'
    export default {
        name: 'AllSubscriptions',
        components: {
      RateLine,
      ProgressBar
    },
    props:{
        mandiName: String,
        mandiId: Number,
        retailOnly: Number,
    },
    data(){
        return{
            file: Object,
            excelJson: Object,
            ItemRates: Object,
            msg: '',
            AddFail: false,
            AddDone: false,
            StatusFlag: false,
            AddRate: false,
            retailMandi: Boolean,
            sort: '',
            Rates: [],
            AddRateForm: false,
            Loading: true,
            sortFruitOn: false,
            sortGrainOn: false,
            sortVegeOn: false,
            search: '',
            UrduNames:{
                Fruit: 'پھل ',
                Vegetable: 'سبزی',
                Grain: 'اناج ',
                RetailRate: 'پرچون ریٹ',
                Date: 'تاریخ ',
                Prediction: 'پیشنگوئی',
                Name: 'نام',
                Unit: 'یونٹ',
                MinRate: 'کم سے  کم',
                MaxRate: 'زیادہ سے  زیادہ',
                SendRates: 'منتخب کردہ ریٹ بھیجیں',
                all: 'تمام'
            },
        UnitNames: [],
        sortedRates: [],
        AddedRatesList: [],
        AddedItemsList: [],
        AddedPredictList: [],
        Units: [],
        bla: false,
        submittedFile: [],
        Predictions: ['Up', 'Same', 'Down'],
        UploadUpdateMsg: '',
        inProgress: false,
        upladedRatesCount: 0,
        totalRates: 1
    }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    download(){
        this.$store.dispatch('downloadMandiSheet', this.mandiId)
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', this.mandiName +'.csv'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
    },
    upload: async function(){
        await this.submitFile();
        console.log(this.submittedFile);
        this.checkSubmittedRates();
    },
    submitFile: function(){
        let file = this.$refs.file.files[0];
        const types = file.name.split('.')[1];
          const fileType = [
            'xlsx', 'xlc', 'xlm', 'xls', 'xlt', 'xlw', 'csv'
          ].some(item => item == types);
          if (!fileType) {
                 alert('Format error! Please reselect');
            return
          }

          const reader = new FileReader();
          let result = [];
          reader.onload = function(e) {
            const data = e.target.result;
            const wb = XLSX.read(data, {
              type: 'binary'
            });
            wb.SheetNames.forEach((sheetName) => {
                console.log(sheetName);
                result.push({
                sheetName: sheetName,
                sheet: XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
              })
            });
          };
          reader.readAsBinaryString(file);
          this.submittedFile = result;
          return
    },
    checkSubmittedRates(){
        if(this.submittedFile.length > 0){
            let filledRates = [];
            let WrongRates = [];
            let correctRates = [];
            let wrongFileFlag = false;
            let filledPredictions = this.submittedFile[0].sheet.filter(rate => { return this.Predictions.includes(rate.Prediction)});
            if(this.retailOnly == 0){
                filledRates = this.submittedFile[0].sheet.filter(rate => { return (rate.MinRate && rate.MaxRate && rate.RetailRate)});
                WrongRates = filledRates.filter(rate => { return (rate.MinRate <= 0 || rate.MaxRate <= 0 || rate.RetailRate <= 0 || rate.MaxRate < rate.MinRate || !this.SelectUnit.includes(rate.UnitName) )})
            }
            if(this.retailOnly == 1){
                filledRates = this.submittedFile[0].sheet.filter(rate => { return (rate.RetailRate)});
                if(filledRates.filter(rate => {return rate.MinRate || rate.MaxRate}).length > 0){
                    wrongFileFlag = true;
                }
                WrongRates = filledRates.filter(rate => { return (rate.RetailRate <= 0 || !this.SelectUnit.includes(rate.UnitName) )})
            }
            if(this.retailOnly == 2){
                filledRates = this.submittedFile[0].sheet.filter(rate => { return (rate.MinRate && rate.MaxRate)});
                if(filledRates.filter(rate => {return rate.RetailRate}).length > 0){
                    wrongFileFlag = true;
                }
                WrongRates = filledRates.filter(rate => { return (rate.MinRate <= 0 || rate.MaxRate <= 0 || rate.MaxRate < rate.MinRate ||!this.SelectUnit.includes(rate.UnitName))})
            }
            if(filledRates.length == 0 || wrongFileFlag || WrongRates.length == filledRates.length){
                this.$alert("You have not entered any valid rates or you have uploaded a file for a different mandi. Please check your file and try again");
            }
            else{
                if(WrongRates.length > 0){
                    let ratesError = ""
                     WrongRates.forEach(rate => {
                        ratesError = ratesError + String(rate.ItemName) + ", "
                    });
                    this.$confirm("You have entered "+ filledRates.length+" rates + "+ filledPredictions.length + " predictions. The following "+ WrongRates.length + " rates have errors: \n" + ratesError + ". Proceed to sending the valid rates/predictions?").then(() => {
                        correctRates = filledRates.filter(rate => {return !WrongRates.includes(rate)});
                        this.viewUploadedRates(correctRates, filledPredictions)
                    })
                }
                else{
                    this.$confirm("You have entered "+ filledRates.length+" valid rates, and "+ filledPredictions.length + " valid predictions. send the rates/predictions?").then(() => {
                        correctRates = filledRates;
                        this.viewUploadedRates(correctRates, filledPredictions)
                    })
                }
            }

        }
        else{
            setTimeout(this.checkSubmittedRates, 5);
        }
    },
    splitArrayIntoChunksOfLen(arr, len) {
      var chunks = [], i = 0, n = arr.length;
      while (i < n) {
        chunks.push(arr.slice(i, i += len));
      }
      return chunks;
    },
    sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
    },
    async viewUploadedRates(correctRates, filledPredictions){
        this.inProgress = true;
        let ratesList = [];
        let predictList = filledPredictions.map(g => ({ItemId: g.ItemId, Prediction: g.Prediction, MandiId: this.mandiId}));
        if(this.retailOnly == 0){
            ratesList = correctRates.map(g => ({ItemId: g.ItemId, MinRate: g.MinRate, MaxRate: g.MaxRate, RetailRate: g.RetailRate, MandiId: this.mandiId, UnitId: this.findUnit(g.UnitName)}))
        }
        if(this.retailOnly == 1){
            ratesList = correctRates.map(g => ({ItemId: g.ItemId, MinRate: -1, MaxRate: -1, RetailRate: g.RetailRate, MandiId: this.mandiId, UnitId: this.findUnit(g.UnitName)}))
        }
        if(this.retailOnly == 2){
            ratesList = correctRates.map(g => ({ItemId: g.ItemId, MinRate: g.MinRate, MaxRate: g.MaxRate, MandiId: this.mandiId, UnitId: this.findUnit(g.UnitName)}))
        }
        this.totalRates = ratesList.length
        let rate_chunks = this.splitArrayIntoChunksOfLen(ratesList, 50);
        let predict_chunks = this.splitArrayIntoChunksOfLen(predictList, 50);
        let j = 0;
        var multiForm = {};
        this.upladedRatesCount = 0;
        while(j < rate_chunks.length){
            if(j < predict_chunks.length){
                multiForm = {
                'rates': rate_chunks[j],
                'predictions': predict_chunks[j]
                };
            }
            else{
                multiForm = {
                'rates': rate_chunks[j],
                'predictions': []
                };
            }
            // upladedRatesCount += rate_chunks[j].length
            console.log('call number ' + String(j))
            console.log(multiForm)
            await this.$store.dispatch('addMulti', multiForm).then(resp => {
                    this.inProgress = false;
                    console.log(multiForm.rates.length + 1)
                    if(j < rate_chunks.length - 1){
                        this.upladedRatesCount += 50;
                    }
                    else{
                        this.upladedRatesCount += multiForm.rates.length;
                    }
                    this.UploadUpdateMsg = 'Uploaded chunk ' + String(j) + '/' + String(rate_chunks.length) + ' of rates:' + resp.data.rates+ ' / ' + resp.data.predictions;
                    this.inProgress = true;
                    // this.$alert(this.UploadUpdateMsg + resp.data.rates+ ' / ' + resp.data.predictions);
                });
            // .catch(err => {
            //         this.$alert(err)
            // });
            j += 1;
        }
        this.RefreshTable();
        
    },
    findUnit(unitName){
        for(var x in this.Units){
            if(this.Units[x].UnitName == unitName) return this.Units[x].UnitId
          }
    },
    refreshRates: function(){
        this.Loading = true;
        this.$store.dispatch('getMandiItems', this.mandiId).then(resp => {this.ItemRates = resp.data.data.items;
        this.Loading=false});
      if(this.farmerGroup)
        {
            this.Rates = this.Items;
        }
    this.AddRateForm=false;
    },
    checkDate: function(item){
        if(item.LastRate[0]){
        var datetime = item.LastRate[0].RecordTime;
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        let day = datetime.slice(8, 10);
        let month = datetime.slice(5, 7);
        let year = datetime.slice(0, 4);
        if(day==dd && month==mm && year==yyyy){
            return true
        }
    }
        return false
    },
    RefreshTable: function(){
        this.Loading = true;
        this.AddedRatesList = [];
        this.AddedPredictList = [];
        this.AddedItemsList = [];
        this.$store.dispatch('getMandiItems', this.mandiId).then(resp => {this.ItemRates = resp.data.data.items.sort(function(a, b){
                if(a.ItemName < b.ItemName) { return -1; }
                if(a.ItemName > b.ItemName) { return 1; }
                return 0;
            });
        this.Loading=false});
    },
    AddToList: function(rate){
        console.log(rate);
        const index = this.AddedItemsList.indexOf(rate.ItemId);
        if (index > -1) {
            console.log('item already in!')
            this.AddedItemsList.splice(index, 1);
            this.AddedRatesList.splice(index, 1);
        }
        this.AddedItemsList.push(rate.ItemId)
        this.AddedRatesList.push(rate)
    },
    AddToPredicts: function(predict){
        this.AddedPredictList.push(predict)
    },
    RemoveItem: function(itemId){
        const index = this.AddedItemsList.indexOf(itemId);
        if (index > -1) {
            this.AddedItemsList.splice(index, 1);
            this.AddedRatesList.splice(index, 1);
            this.AddedPredictList.splice(index, 1);
        }
    },
    AddRatePredictList(){
        let multiForm = {
            'rates': this.AddedRatesList,
            'predictions': this.AddedPredictList
        };
        this.$store.dispatch('addMulti', multiForm).then(resp => {
            console.log(resp);
            this.$alert(resp.data.rates+ ' / ' + resp.data.predictions);
            this.RefreshTable();
        }).catch(err => {
            this.$alert(err)
        });
    }
    },
    mounted(){
    this.retailMandi = this.retailOnly;
    this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units);
    this.$store.dispatch('getMandiItems', this.mandiId).then(resp => {this.ItemRates = resp.data.data.items.sort(function(a, b){
                if(a.ItemName < b.ItemName) { return -1; }
                if(a.ItemName > b.ItemName) { return 1; }
                return 0;
            });
        this.Loading=false});
    },
    computed:{
        ProgressValue(){
        return Math.round(100 * this.upladedRatesCount / this.totalRates,2)
    },
    SelectUnit(){
      return this.Units.map(g => (g.UnitName))
    },
    filteredList() {
        if(!this.Loading)
        {
            if(this.sortFruitOn || this.sortVegeOn || this.sortGrainOn)
            {
                return this.ItemRates.filter(item => {
                return item.ItemCategory == this.sort && item.ItemName.toLowerCase().includes(this.search.toLowerCase())
            })
            }
        else{
            return this.ItemRates.filter(item => {
                return item.ItemName.toLowerCase().includes(this.search.toLowerCase()) || item.ItemUrduName.toLowerCase().includes(this.search.toLowerCase())
                })
            }
        }
        else{
            return this.ItemRates
        }
    }
}
}
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
        color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
    border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 13px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
    li{
        color: black;
    }
</style>
