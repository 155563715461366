<template>
  <tr>
    <td><span v-if="mandi.RatesCount < 1" class="btn-inv icon-circle" style="color: red; font-size: 20px"></span>
      <span v-if="mandi.RatesCount >= 1 && mandi.RatesCount < 50" class="btn-inv icon-circle" style="color: orange; font-size: 20px"></span>
      <span v-if="mandi.RatesCount >= 50" class="btn-inv icon-circle" style="color: green; font-size: 20px"></span>
      {{index}}
    </td>
    <td>{{mandi.MandiName}}</td>
    <td v-if="mandi.MandiUser">
        {{mandi.MandiUser.FirstName}} {{mandi.MandiUser.LastName}}
    </td>
    <td v-else>
        Not assigned!
    </td>
    <td v-if="mandi.MandiUser">
        {{mandi.MandiUser.PhoneNumber}}
    </td>
    <td v-else>
        -
    </td>
    <td>{{mandi.RatesCount}}</td>
    <td><button class="btn-inv" v-on:click="goToMandiRate(mandi.MandiName, mandi.MandiId)">Rates & Predictions</button></td>
    <td><button class="btn-inv" v-on:click="download()" title="Download"><span class="icon-download"></span></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'TrackMandiLine',
        components: {
          // ToggleSubscription
    },
    props:{
      mandi: Object,
      index: Number,
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            todayItems: Object,
            items: Object,
            MandiUpdated: Boolean,
            UpdatedRatesNumber: Number,
            UpdatedPredictionsNumber: Number,
            retailMandi: Boolean,
            MyMandi: Object
        }
    },
    methods:{
      async goToMandiRate(mandiName, mandiId){
      await this.getTodayRates(mandiId)
      this.$router.push({
          name: 'AllItemRates',
          params: {
              Items: this.todayItems,
              itemName: "",
              Type: "MandiTrack",
              mandiName: mandiName,
              mandiId: mandiId,
              retailOnly: this.mandi.RetailOnly,
              mandi: Object
          }
      })
    },

    currentDate() {
      const current = new Date();
      const date = current.getDate()+'-'+(current.getMonth()+1)+'-'+current.getFullYear();
      return date;
    },
    download(){
        this.$store.dispatch('downloadTodaysRates', this.mandi.MandiId)
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', this.mandi.MandiName+' Dashboard '+this.currentDate()+'.csv'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

    },


    async getTodayRates(MandiName){
      await this.$store.dispatch("getTodayRates", MandiName).then(resp => this.todayItems = resp.data.data)
    }

      },
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
