<template>
  <div id="login">

  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-7">
            <h2 class="section-title mb-3">User Sign Up</h2>
            <form v-on:submit.prevent="UserSignUp">
              <SignUpForm FormType='Add' :Inputform="form" v-on:form-updated="form = $event"/>

              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="SignupFail == true" v-on:alert-closed="SignupFail = false" :isWarning="SignupFail" :msg="errorMsg"/>
                    <Alert v-if="SignupDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!SignupDone" msg='Signed Up Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Sign Up">
                </div>
              </div>
            </form>
          </div>
        </div>
         <div>
      </div>
    </div>
      </div>
</template>

<script>
import Alert from '../../components/Alert.vue';
import SignUpForm from '../../components/SignUpForm'
    export default {
        name: 'login',
        components: {
            Alert,
            SignUpForm,
          },
    data(){
        return{
            form: {
              Email: "",
              Password: "",
              PhoneNumber: "",
              FirstName: "",
              LastName: "",
              UserTypeId: Number,
            },
            msg: '',
            isHidden: true,
            SignupFail: false,
            SignupDone: false,
            errorMsg: 'Error Signing Up!'
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    async UserSignUp(){
        await this.$store.dispatch('signup', this.form)
        .then(resp => {
          if(resp.error){
            this.SignupFail = true;
            this.errorMsg = 'bla'; //resp.error.msg;
          }
          else{
            this.SignupDone = true
          }
          }).catch(() => this.SignupFail = true)
  }
},

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
