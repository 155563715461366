<template>
  <div id="login">
    <div class="section">
  <div class="section last-section">
    <div class="row justify-content-center ">
        <div class="col-md-12">
          <h1 class="mb-3">Producer: <b>{{Producer.ProducerName}}</b> <button class="icon-settings" v-on:click="ProducerEdit()" title="edit"></button></h1>
          <ProducerForm v-if="!loading" FormType='Input' :Inputform="form" v-on:form-updated="form = $event"/>
          <span v-else>Loading ...</span>
        </div>
    </div>

    <div class="row justify-content-center ">
        <div class="col-md-12">
          <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row">
                    <div class="col-xs-5">
                        <h2>Producer Items</h2>
                    </div>
                    <div class="col-xs-7">
                        <button v-on:click="addItem=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Item</span></button>
                    </div>
                </div>
            </div>
        <table v-if="!refresh" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Category</th>
                        <th>Name: English</th>
                        <th>Urdu</th>
                        <th>Volume</th>
                        <th>Area Under Cultivation</th>
                    </tr>
                </thead>
                <tbody v-if="addItem">
                 <ProducerItemLine type="add" :ProducerId="Producer.ProducerId" v-on:item-added="refreshTable()"/>
                </tbody>
                <tbody v-for="item in form.ProducerItems" :key="item.ItemId">
                    <ProducerItemLine type="input" :item="item" :index="item.ItemId" v-on:update="refreshTable()" v-on:item-edited="refreshTable()"/>
                </tbody>
            </table>
          </div>
        </div>
        </div>
    </div>


         <div>
      </div>
    </div>
</div>
      </div>
</template>

<script>
import ProducerForm from '../../components/ProducerForm'
import ProducerItemLine from '../../components/ProducerItemLine'
    export default {
        name: 'login',
        props:{
          Producer: Object
        },
        components: {
            ProducerForm,
            ProducerItemLine
          },
    data(){
        return{
          form: Object,
          addItem: false,
          loading: true,
          refresh: false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    ProducerEdit: function(){
       this.$router.push({
          name: 'EditProducer',
          params: {
              Producer: this.Producer,
          }
      })
    },
    refreshTable(){
      this.refresh = true;
      this.$store.dispatch('getProducerById', this.Producer.ProducerId).then(resp => {this.form = resp.data.data;
        this.loading = false;
        this.refresh = false});
    }
},
    created(){
      // this.form = this.Producer;
      this.loading = true;
      this.refreshTable()
    }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
