<template>
  <tr>
    <th><button class="btn-inv" v-on:click="gotoBuyer(bid.BuyerId)">{{bid.BuyerName}}, {{bid.BuyerCity}}</button></th>
    <th>{{bid.BiddingPrice}} Rs.</th>
  </tr>
</template>

<script>
    export default {
    name: 'BidLine',
    props:{
      bid: Object
    },
    data(){
        return{
            state: '',
            date: '',
            Units: Object,
            addNewBid: false
        }
    },
    methods:{
      AddBids(){
       this.addNewBid = !this.addNewBid;
      },
      gotoBuyer: async function(userId){
      this.$router.push({
          name: 'BuyerPage',
          params: {
              BuyerId: userId,
          }
      })
      },
    }
  }
</script>

