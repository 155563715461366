<template>
  <div id="client-page">
    <ClientPage :ClientInfo="ClientInfo" :ClientNumber="ClientNumber"/>
    <div class="row">
        <div class="col-md-4"/>
        <div class="col-md-4">
          <button class="btn btn-primary py-3 px-5 btn-block" v-on:click="EditClient()">Edit</button>
        </div>
      </div>
  </div>
</template>

<script>
import ClientPage from '../../components/ClientPageOperatorView'
    export default {
        name: 'ClientInfoPage',
        components:{
          ClientPage
        },
    props: {
      ClientNumber: String,
      ClientInfo: Object,
      Type: String
    },
    data(){
        return{
          Client: Object
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    displayDate(date){
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    let year = date.slice(0, 4);
    return String(day+'/'+month+'/'+year)
  },
    EditClient: function(){
      if(this.Type == "Admin"){
        this.$router.push({
          name: 'EditClient',
          params: {
              ClientInfo: this.Client,
              Type: this.Type
          }
        })
      }
      else{
        this.$router.push({
          name: 'EditClientOperator',
          params: {
              ClientInfo: this.Client,
              Type: this.Type
          }
        })

      }

      },
    },
    created(){
      if(this.ClientInfo){
        this.Client = this.ClientInfo;
      }
      else{
        this.$store.dispatch('getClientById', this.ClientNumber).then(resp => this.Client = resp.data.data)
      }
    }

    }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.line {
  width: 95%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 3px;
  display:inline-block;
}
</style>
