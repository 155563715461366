<template>
  <div id="client-page">
    <div class="row container ">
    <h1 class="section-title-mod" >Client {{Client.ClientNumber}} <button class="icon-settings" v-on:click="EditClient()" title="Edit"></button></h1>
  </div>
  <div v-if="Client.ClientSubscription=='Left'"><p style="font-size: 24px; color: red">UnSubscription Date: <span v-if="Client.UnsubscriptionDate">{{displayDate(Client.UnsubscriptionDate)}}</span><span v-else>NA</span></p></div>
    <div class="row mb-3">
      <div class="col-lg-7">
        <p style="font-size: 24px">
          Name: {{Client.ClientName}}
          <br>
        <span class="line"></span>
        <br>
        Nearest Mandi: <button class="btn-inv" v-on:click="goToMandiRate(Client.NearestMandiName, Client.NearestMandiId)">{{Client.NearestMandiName}}</button>
        <br>
        <span class="line"></span>
        <br>
        Address: {{Client.ClientAddress}}
        <br>
        <span class="line"></span>
        <br>
        Preffered Category: {{Client.PrefferedCategory}}
         </p>
      </div>
      <div class="col-lg-1 text-center">
        <span class="vline"></span>
      </div>
      <div class="col-lg-4">
        <p style="font-size: 24px">
          <span v-if="Client.isFarmer =='1'">Farmer</span>
          <span v-else>Not Farmer</span>
          <br>
        <span class="line"></span>
        <br>
        District: {{Client.ClientDistrict}}
        <br>
        <span class="line"></span>
        <br>
        Locale: {{Client.ClientLocale}}
        <br>
        <span class="line"></span>
        <br>
         </p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-12">
        <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row">
                    <div class="col-xs-5">
                        <h2>Call <b>Record</b></h2>
                    </div>
                    <div class="col-xs-7">
                        <button v-on:click="AddrecForm=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add Call Record</span></button>
                    </div>
                </div>
            </div>
        <table v-if="RefreshRecTable" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Motive</th>
                        <th>Notes</th>
                        <th>Operator</th>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in Client.records" :key="index">
                    <RecordLine :rec="item" :index="index"/>
                </tbody>
                <tbody>
                  <AddRecordLine v-if="AddrecForm" :ClientNumber="ClientNumber" type='Add' v-on:record-added="refreshRecs()"/>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-lg-12">
        <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row">
                    <div class="col-xs-5">
                        <h2>Preferred <b>Items</b></h2>
                    </div>
                    <div class="col-xs-7">
                        <button v-on:click="AddItemForm=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add Preferred Item</span></button>
                    </div>
                </div>
            </div>
        <table v-if="RefreshTable" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name (English)</th>
                        <th>Name (Urdu)</th>
                        <th>Category</th>
                        <th>Default Unit</th>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in PreferredItems" :key="index">
                    <ItemLine :item="item" :index="index" type='Preferred' :dataOperator="true" v-on:item-removed="refreshItems()"/>
                </tbody>
                <tbody>
                  <AddItemLine v-if="AddItemForm" :ClientNumber="ClientNumber" :index="index" type='Preferred' v-on:item-added="refreshItems()"/>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ItemLine from './ItemLine'
import AddItemLine from './AddItemLine'
import AddRecordLine from './AddRecordLine'
import RecordLine from './CallRecordLine'
    export default {
        name: 'ClientPage',
    components:{
      ItemLine,
      AddItemLine,
      RecordLine,
      AddRecordLine
    },
    props: {
      ClientNumber: String,
      ClientInfo: Object
    },
    data(){
        return{
          Client: Object,
          PreferredItems: [],
          AddItemForm: false,
          RefreshTable: true,
          AddrecForm: false,
          RefreshRecTable: true,
          todayItems: [],
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    async goToMandiRate(mandiName, mandiId){
      await this.getTodayRates(mandiId)
      this.$router.push({
          name: 'ItemBestRates',
          params: {
              Items: this.todayItems,
              itemName: "",
              Type: "MandiTrack",
              mandiName: mandiName,
              mandiId: mandiId,
          }
      })
    },
    async getTodayRates(MandiId){
      await this.$store.dispatch("getTodayRates", MandiId).then(resp => this.todayItems = resp.data.data)
    },
    displayDate(date){
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    let year = date.slice(0, 4);
    return String(day+'/'+month+'/'+year)
  },
  refreshItems(){
    this.RefreshTable = false;
    this.$store.dispatch('getClientItems', this.ClientNumber).then(resp =>
      {
        this.PreferredItems = resp.data.data.PreferredItems;
        this.RefreshTable = true;
      });
    this.AddItemForm=false;
  },
    refreshRecs(){
    this.AddrecForm=false;
    this.RefreshRecTable = false;
    this.$store.dispatch('getClientById', this.ClientNumber).then(resp => {this.Client = resp.data.data;
      this.AddrecForm=false;
      this.RefreshRecTable = true;
    });
  },
  EditClient: function(){
        this.$router.push({
          name: 'EditClientOperator',
          params: {
              ClientInfo: this.Client,
              Type: this.Type
          }
        })
      },
    },
    created(){
      if(this.ClientInfo){
        this.Client = this.ClientInfo;
      }
      else{
        this.$store.dispatch('getClientById', this.ClientNumber).then(resp => this.Client = resp.data.data)
      }
      this.$store.dispatch('getClientItems', this.ClientNumber).then(resp => this.PreferredItems = resp.data.data.PreferredItems)
    }

    }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.line {
  width: 95%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 3px;
  display:inline-block;
}
</style>
