<template>
  <div id="AllSubscriptions">
    <ItemHistory v-if="Type=='Item' && !farmerGroup & !bestRates" :item="Items"/>
    <p v-if="Loading">Loading ...</p>
    <div class="col-lg-12">
    <p v-if="Type=='Item' && !Loading && maxRate.RetailOnly==0">Today's highest max rate in mandis is in <b>{{maxRate.MandiName}}</b> mandi. <br>Recorded max of <b>{{maxRate.MaxRate}}Rs.</b> min of <b>{{maxRate.MinRate}}Rs.</b> and a retail rate of <b>{{maxRate.RetailRate}}Rs.</b> per {{maxRate.UnitName}}</p>
    <p v-if="Type=='Item' && !Loading && maxRate.RetailOnly==1">Today's highest max rate in mandis is in <b>{{maxRate.MandiName}}</b> mandi. <br>Recorded retail rate of <b>{{maxRate.RetailRate}}Rs.</b> per {{maxRate.UnitName}}</p>
    <p v-if="Type=='Item' && !Loading && maxRate.RetailOnly==2">Today's highest max rate in mandis is in <b>{{maxRate.MandiName}}</b> mandi. <br>Recorded max of <b>{{maxRate.MaxRate}}Rs.</b> and a min of <b>{{maxRate.MinRate}}Rs.</b> per {{maxRate.UnitName}}</p>
    <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row">
                    <div class="col-xs-5" v-if="bestRates!='1'">
                        <h2 v-if="Type=='Item'">{{itemName}} <b>Rates</b></h2>
                        <h2 v-if="Type=='Mandi'">{{mandiName}} <b>Rates</b> <span v-if="retailOnly==1">- Retail Only</span></h2>
                        <h2 v-if="Type=='MandiTrack'">{{mandiName}} <b>Rates</b> Today</h2>
                    </div>
                    <div class="col-xs-5" v-else>
                        <h2 v-if="Type=='Item'">{{itemName}} <b>Best Rates</b></h2>
                    </div>
                    <div class="col-xs-7" v-if="bestRates!='1'">
                        <button v-if="Type!='MandiTrack' && user!='Operator'" v-on:click="AddRateForm=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Rate</span></button>

                        <button v-if="Type=='MandiTrack' && !AddRate" v-on:click="AddRateForm=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add Rate-Prediction</span></button>

                        <button v-if="Type=='MandiTrack' && !AddRate" v-on:click="download()" class="btn btn-primary" style="margin-right: 10px;"><span class="icon-download" style="margin-right: 5px; color: black"></span>Download</button>

                        <button v-if="AddRate" v-on:click="AddRate=false" class="btn btn-primary"><i class="material-icons">&#8722;</i> <span>Quite Rate-Prediction</span></button>
                    </div>
                    <div class="col-xs-7" v-else>
                        <button v-on:click="bestBy='RetailRate'" class="btn btn-primary"><span style="color: red" v-if="bestBy=='RetailRate'">By Retail</span><span v-else>By Retail</span></button>

                        <button v-on:click="bestBy='MaxRate'" class="btn btn-primary"><span style="color: red" v-if="bestBy=='MaxRate'">By Max</span><span v-else>By Max</span></button>

                        <button v-on:click="bestBy='MinRate'" class="btn btn-primary"><span style="color: red" v-if="bestBy=='MinRate'">By Min</span><span v-else>By Min</span></button>
                    </div>
                    <div class="col-xs-4" v-if="Type=='Item'">
                        <form class="search-form" action="" style="margin-top: -1px; margin-left: 20px">
                          <input class="search-box" v-model="search" placeholder="Search Mandi/Operator Name..." style="font-size: 14px;">
                          <i class="faa icon-search" style="font-size: 14px"></i>
                        </form>
                    </div>
                </div>
                <br>
                <div class="row justify-content-center">
                    <div class="col-xs-7" v-if="Type=='Item'">
                        <button v-on:click="sort=mandiTypeOptions[3]; sortNormalOn = false; sortRetailOn=false; sortBulkOn=false; sortSourceOn=!sortSourceOn" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[3] && sortSourceOn">
                            <span class="btn-inv icon-star" style="color: green"></span> {{mandiTypeOptions[3]}}</span><span v-else><span class="btn-inv icon-star" style="color: green"></span> {{mandiTypeOptions[3]}}</span></button>

                        <button v-on:click="sort=mandiTypeOptions[2]; sortNormalOn = !sortNormalOn; sortRetailOn=false; sortBulkOn=false; sortSourceOn=false" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[2] && sortNormalOn">
                            <span class="btn-inv icon-star" style="color: black"></span> {{mandiTypeOptions[2]}}</span><span v-else><span class="btn-inv icon-star" style="color: black"></span> {{mandiTypeOptions[2]}}</span></button>

                        <button v-on:click="sort=mandiTypeOptions[1]; sortRetailOn = !sortRetailOn; sortBulkOn=false; sortNormalOn=false; sortSourceOn=false" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[1] && sortRetailOn"><span class="btn-inv icon-star" style="color: blue"></span>{{mandiTypeOptions[1]}}</span><span v-else><span class="btn-inv icon-star" style="color: blue"></span>{{mandiTypeOptions[1]}}</span></button>

                        <button v-on:click="sort=mandiTypeOptions[0]; sortBulkOn = !sortBulkOn; sortRetailOn=false; sortNormalOn=false; sortSourceOn=false" class="btn btn-primary"><span style="color: red" v-if="sort==mandiTypeOptions[0] && sortBulkOn">{{mandiTypeOptions[0]}}</span><span v-else>{{mandiTypeOptions[0]}}</span></button>

                       <!--  <button v-on:click="sortFlagOn= !sortFlagOn" class="btn btn-primary"><span style="color: red" v-if="sortFlagOn"><span class="icon-flag"></span> Flagged</span><span v-else><span class="icon-flag"></span> Flagged</span></button> -->
                    </div>
                </div>
            </div>
            <AddRate v-if="AddRate" :MandiName="mandiName" :MandiId="mandiId"/>
            <table v-if="Type=='Item' && bestRates=='1'" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Mandi</th>
                        <th>Min Rate</th>
                        <th>Max Rate</th>
                        <th>Retail Rate</th>
                        <th>Unit</th>
                        <th>Record Time</th>
                        <th>Added By:</th>
                    </tr>
                </thead>
                <tbody v-for="(rate, index) in sortedArray" :key="index">
                    <RateLine :Rate="rate" :Type="Type" :ItemName="itemName" :retailOnly="rate.RetailOnly" :Flags="ItemFlags"/>
                </tbody>
                <!-- <tbody v-for="(rate, index) in ItemRates.LastRate" :key="index">
                    <RateLine :Rate="rate" :Type="Type" :ItemName="itemName" :retailOnly="rate.RetailOnly"/>
                </tbody> -->
            </table>
            <table v-if="bestRates!='1' && Type=='Item'&& !Loading" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Mandi</th>
                        <th>Min Rate</th>
                        <th>Max Rate</th>
                        <th>Retail Rate</th>
                        <th>Unit</th>
                        <th>Record Time</th>
                        <th>Added By:</th>
                    </tr>
                </thead>
                <tbody>
                    <AddRateLine v-if="AddRateForm" :itemId="ItemRates.ItemId" :Type="Type" v-on:rate-added="refreshRates()"/>
                </tbody>
                <tbody v-for="(rate, index) in filteredList" :key="index">
                    <RateLine :Rate="rate" :Type="Type" :ItemName="itemName" :retailOnly="rate.RetailOnly" :Flags="ItemFlags"/>
                </tbody>
            </table>
            <table v-if="Type!='Item' && !Loading" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Item</th>
                        <th v-if="retailOnly!=1">Min Rate</th>
                        <th v-if="retailOnly!=1">Max Rate</th>
                        <th v-if="retailOnly!=2">Retail Rate</th>
                        <th>Unit</th>
                        <th>Record Time</th>
                        <th v-if="Type=='MandiTrack'">Prediction</th>
                        <th v-if="Type=='MandiTrack'">Prediction Time</th>
                        <th v-if="Type!='MandiTrack'">Added By:</th>
                    </tr>
                </thead>
                 <tbody>
                    <AddRateLine v-if="AddRateForm" :mandiId="mandiId" :Type="Type" v-on:rate-added="refreshRates()" :retailOnly="retailOnly"/>
                </tbody>
                <tbody v-for="(item, index) in ItemRates" :key="index">
                    <RateLine v-if="Type=='MandiTrack'" :Rate="item" :Type="Type" :ItemName="item.ItemName" :index="index" :retailOnly="retailOnly" :Flags="ItemFlags"/>
                    <RateLine v-else :Rate="item.LastRate[0]" :Type="Type" :ItemName="item.ItemName" :index="index" :retailOnly="retailOnly" :Flags="ItemFlags"/>
                </tbody>
            </table>
        </div>
    </div>
</div>
      </div>
</template>

<script>
import RateLine from './RateLine'
import AddRate from '../views/pages/AddRatePredict'
import AddRateLine from './NewRateLine'
import ItemHistory from '../views/pages/ItemPage'
    export default {
        name: 'AllSubscriptions',
        components: {
      RateLine,
      AddRate,
      AddRateLine,
      ItemHistory
      // Alert,
    },
    props:{
        Items: Array,
        itemName: String,
        Type: String,
        mandiName: String,
        mandiId: Number,
        retailOnly: Number,
        bestRates: String,
        farmerGroup: Boolean,
        user: String
    },
    data(){
        return{
            ItemRates: Object,
            msg: '',
            AddFail: false,
            AddDone: false,
            StatusFlag: false,
            AddRate: false,
            maxRate: Object,
            retailMandi: Number,
            bestBy: 'RetailRate',
            Rates: [],
            AddRateForm: false,
            Loading: false,
            sort: String,
            mandiTypeOptions: ['Normal (Bulk + Retail)', 'Retail Only', 'Bulk Only', 'Source Only'],
            sortNormalOn: false,
            sortRetailOn: false,
            sortBulkOn: false,
            sortSourceOn: false,
            search: "",
            ItemFlags: [],
            sortFlagOn:false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    refreshRates: function(){
        this.Loading = true;
    if(this.Type == 'Item'){
        this.$store.dispatch('getItemRates', this.Items.ItemId).then(resp => {this.ItemRates = resp.data.data.item;
            this.Rates = this.ItemRates.LastRate;
        this.Loading = false;
        });
      if(this.farmerGroup)
        {
            this.Rates = this.Items;
        }
    }
    if(this.Type == 'Mandi'){
        this.$store.dispatch('getMandiItems', this.mandiId).then(resp => {this.ItemRates = resp.data.data.items;
        this.Loading=false});
      if(this.farmerGroup)
        {
            this.Rates = this.Items;
        }
    }
    if(this.Type == 'MandiTrack')
    {
        this.$store.dispatch("getTodayRates", this.mandiId).then(resp => {this.ItemRates = resp.data.data;
            this.Loading=false})
    }
    this.AddRateForm=false;
    },
    currentDate() {
      const current = new Date();
      const date = current.getDate()+'-'+(current.getMonth()+1)+'-'+current.getFullYear();
      return date;
    },
    download(){
        this.$store.dispatch('downloadTodaysRates', this.mandiId)
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', this.mandiName+' Dashboard '+this.currentDate()+'.csv'); //any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });

    }
    },
    mounted(){
    this.retailMandi = this.retailOnly;
    console.log("test");
    console.log(this.retailMandi);
    if(this.Type == 'Item' && !this.farmerGroup){
        this.Loading = true;
        this.$store.dispatch('getItemRates', this.Items.ItemId).then(resp => {this.ItemRates = resp.data.data.item;
            this.Rates = this.ItemRates.LastRate;
            this.maxRate = this.ItemRates.highest_MaxRate;
            this.Loading = false;
        });
        let FlagsObject ={
                'ItemId': this.Items.ItemId,
                'MandisList': {
                    'RateList': this.FlagsList
                }
            };
            this.$store.dispatch('getItemFlags', FlagsObject).then(resp => {this.ItemFlags = resp.data.Flagged;
                this.Loading = true;
                this.Loading = false;
                })
    }
    else{
        this.ItemRates = this.Items;
    }

    if(this.farmerGroup)
        {
            this.Rates = this.Items;

        }
    },
    computed:{

      FlagsList() {

        if(this.Type=='Item'){
            return this.ItemRates.LastRate.filter(rate => {return rate.RetailOnly!=1}).map(rate => {
                    return [rate.MandiId, rate.MaxRate]
            })
        }
        else{
            return []
        }
             

        },

        filteredList() {
        var mandiSub = {};
        if(!this.Loading)
        {
            if(!this.sortRetailOn && !this.sortNormalOn && !this.sortBulkOn && !this.sortSourceOn)
            {
                if(this.sortFlagOn)
                {
                    return this.ItemRates.LastRate.filter(mandi => {return this.ItemFlags.includes(mandi.MandiId)}).filter(mandi => {
                    return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase()) || mandi.FirstName.toLowerCase().includes(this.search.toLowerCase()) || mandi.LastName.toLowerCase().includes(this.search.toLowerCase())
                  })

                }
                else{
                    return this.ItemRates.LastRate.filter(mandi => {
                    return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase()) || mandi.FirstName.toLowerCase().includes(this.search.toLowerCase()) || mandi.LastName.toLowerCase().includes(this.search.toLowerCase())
                  })
                }
            }
            else{
                if(this.sort==this.mandiTypeOptions[0])
                {
                    mandiSub = this.ItemRates.LastRate.filter(mandi => {return mandi.RetailOnly==0});
                }
                else if(this.sort==this.mandiTypeOptions[1]){
                    mandiSub = this.ItemRates.LastRate.filter(mandi => {return mandi.RetailOnly==1});
                }
                else if(this.sort==this.mandiTypeOptions[2]){
                    mandiSub = this.ItemRates.LastRate.filter(mandi => {return mandi.RetailOnly==2});
                }
                else{
                    mandiSub = this.ItemRates.LastRate.filter(mandi => {return mandi.RetailOnly==3});
                }
                if(this.sortFlagOn){
                    return mandiSub.filter(mandi => {return this.ItemFlags.includes(mandi.MandiId)}).filter(mandi => {
                    return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase()) || mandi.FirstName.toLowerCase().includes(this.search.toLowerCase()) || mandi.LastName.toLowerCase().includes(this.search.toLowerCase())
                  })
                }
                else{
                    return mandiSub.filter(mandi => {
                    return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase()) || mandi.FirstName.toLowerCase().includes(this.search.toLowerCase()) || mandi.LastName.toLowerCase().includes(this.search.toLowerCase())
                  })
                }

            }
        }
        else{
            return {}
            }
        },

        sortedArray: function() {
            let myIndex = String(this.bestBy);
            function compare(a, b) {
              if (a[myIndex] < b[myIndex])
                return -1;
              if (a[myIndex] > b[myIndex])
                return 1;
              return 0;
            }
            let myRates = this.Rates;
            // return myRates.sort(compare);
            var mandiSub = {};
            if(!this.sortRetailOn && !this.sortNormalOn && !this.sortBulkOn && !this.sortSourceOn)
            {
                return myRates.filter(mandi => {
                return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase()) || mandi.FirstName.toLowerCase().includes(this.search.toLowerCase()) || mandi.LastName.toLowerCase().includes(this.search.toLowerCase())
              }).sort(compare)
            }
            else{
                if(this.sort==this.mandiTypeOptions[0])
                {
                    mandiSub = myRates.filter(mandi => {return mandi.RetailOnly==0});
                }
                else if(this.sort==this.mandiTypeOptions[1]){
                    mandiSub = myRates.filter(mandi => {return mandi.RetailOnly==1});
                }
                else if(this.sort==this.mandiTypeOptions[2]){
                    mandiSub = myRates.filter(mandi => {return mandi.RetailOnly==2});
                }
                else{
                    mandiSub = myRates.filter(mandi => {return mandi.RetailOnly==3});
                }
                return mandiSub.filter(mandi => {
                return mandi.MandiName.toLowerCase().includes(this.search.toLowerCase()) || mandi.FirstName.toLowerCase().includes(this.search.toLowerCase()) || mandi.LastName.toLowerCase().includes(this.search.toLowerCase())
              }).sort(compare)
            }
          },
    }

    }
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
        color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
    border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 13px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
</style>
