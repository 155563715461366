<template>
  <tr>
    <td>{{index}}</td>
    <td><input type="text" placeholder="English Name" v-model="NewItem.ItemName"></td>
    <td><input type="text" placeholder="Urdu Name" v-model="NewItem.ItemUrduName"></td>
    <td v-if="type!='Livestalk'"> <v-select class="btn-vselect" v-on:input="OnChangeSelectCategory"
                          :value.sync="value"
                          :options="Categories"
                          :v-model="NewItem.ItemCategory"
                          >
          </v-select></td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          :v-model="NewItem.UnitId"
                          >
          </v-select></td>
    <td><button class="btn-inv icon-plus" v-on:click="AddItem(NewItem)"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'NewItemLine',
        components: {
          // ToggleSubscription
    },
    props:{
      index: Number,
      type: String
    },
    data(){
        return{
            Units: [],
            NewItem: {
              ItemUrduName: '',
              ItemCategory: '',
              DefaultUnitId: -1,
            },
            Categories: ['Fruit', 'Vegetable', 'Grain']
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    AddItem: async function(item){
      console.log(item);
      if(this.type=='Livestalk'){
        item.ItemCategory = 'Livestalk'
      }
      await this.$store.dispatch('addItem', item)
        .then(() => {
          this.$emit('item-added');
          this.$alert("Item added");
          }).catch(() => {
            this.$alert("Error! Item not added")
          })
      },
      OnChangeSelectCategory(e){
          this.NewItem.ItemCategory = e
        },
      OnChangeSelectUnit(e){
          this.NewItem.DefaultUnitId = Number(e.value)
        },
  },
  created(){
    this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units)
  },
  computed:{
    SelectUnit(){
      return this.Units.map(g => ({label: g.UnitName, value: g.UnitId})) || ""
    },
  }
  }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
