<template>
  <div id="login">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h2 class="section-title mb-3">Add Buyer</h2>
            <form v-on:submit.prevent="AddBuyer">
              <BuyerForm type='buyer' :Inputform="form" v-on:form-updated="form = $event"/>

              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Add">
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
</div>
</template>

<script>
import BuyerForm from '../../components/BuyerForm'
    export default {
        name: 'login',
        components: {
            BuyerForm,
          },
    data(){
        return{
            form:{
              BuyerName: '',
              BuyerAddress: '',
              BuyerContact: '',
              BuyerCity: '',
              BuyerPreference: [],
              isExporter: 0,
              onlySeller: 0,
              isSeller: 0
            },
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    async AddBuyer(){
      this.$store.dispatch('addBuyer', this.form).then(() => {
        this.$alert("Buyer added!");
        this.goTo('all-buyers');
        }).catch(err => {
          this.$alert(err.error.message);
        });
  }
},

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
