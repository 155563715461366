<template>
  <div id="mandi-form">
      <div v-if="Items" class="form-group row">
        <div class="col-md-12">
        <p>Select predicted item:</p>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select>
      </div>
      </div>

      <div class="form-group row" v-if="Mandis">
        <div class="col-md-12">
        <p>Select predicted mandi: </p>
          <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          :v-model="form.UserMandiId"
                          >
          </v-select>
      </div>
      </div>

       <div class="form-group row">
        <div class="col-md-12">
        <p>Enter Prediction: </p>
          <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectPredict"
                          :value.sync="value"
                          :options="predictions"
                          :v-model="form.Prediction"
                          >
          </v-select>
      </div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'PredictForm',
        components: {
    },
    props:{
      Inputform: Object,
    },
    data(){
        return{
            form: Object,
            predictions: ['Up', 'Same', 'Down'],
            Mandis: Object,
            Items: Object,
            unitId: Number
        }
    },
    methods:{
        ChangeFormEmit(){
          this.$emit('form-updated', this.form)
        },
        OnChangeSelectItem(e){
          this.form.ItemId = e.value
        },
        OnChangeSelectMandi(e){
          this.form.MandiId = e.value
        },
        OnChangeSelectPredict(e){
          this.form.Prediction = e
          this.ChangeFormEmit()
        },
    },
    created () {
        this.form = this.Inputform;
        this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis)
        this.$store.dispatch('getAllItemsNames').then(resp => {this.Items = resp.data.data.items;
        this.$emit('ready')})
  },
  computed:{
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName, value: g.ItemId}))
    },
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId}))
    },
  }
}
</script>

