<template>
   <header class="site-navbar py-4 js-sticky-header site-navbar-target" role="banner" style="position: fixed; text-align: center !important;  padding: 0px !important">
      <div class="container-fluid" style="text-align: center !important;">
        <div class="d-flex align-items-center text-center">
          <div class="site-logo" >
            <button class="nav-link btn-inv site-logo prim-btn" style="color: #fff; font-size: 25px; margin: 5px" >DEHQAAN AI</button>
            </div>
          <div class="ml-auto">
            <nav class="site-navigation position-relative text-right" role="navigation">
            </nav>
          </div>

          <div class="ml-auto">
            <nav class="site-navigation position-relative text-right" role="navigation">
              <ul class="site-menu main-menu site-menu-dark js-clone-nav mr-auto d-none d-lg-block">
                <li v-if="isLoggedIn && !isMandiOperator"><button class="btn btn-primary smoothscroll py-3 px-4 rounded border border-primary" v-on:click="logout()" style="margin: 5px">Log Out</button></li>
                <li v-if="isLoggedIn && isMandiOperator"><button class="btn btn-primary smoothscroll py-3 px-4 rounded border border-primary" v-on:click="logout()" style="margin: 5px">لاگ آوٹ</button></li>
                <li v-if="isAdmin"><button class="btn btn-primary smoothscroll py-3 px-4 rounded border border-primary" v-on:click="goTo('/select')" style="margin: 5px">Managment</button></li>
                <li v-if="isMandiOperator"><button class="btn btn-primary smoothscroll py-3 px-4 rounded border border-primary" v-on:click="goTo('/mandi-operator/select')">منڈی کو منتخب کریں</button></li>
              </ul>
            </nav>
          </div>

        </div>
      </div>
    </header>
</template>
<script>
// Utilities
export default {
  name: "Header",

  components: {},

  props: {
    value: {
      type: Boolean,
      default: true
    }
  },
    computed : {
      isLoggedIn: function(){if(localStorage.getItem('token')){return true}
                              else{return false}},
      isAdmin: function(){if(localStorage.getItem('isAdmin')){return true}
                              else{return false}},
      isMandiOperator: function(){if(localStorage.getItem('isMandiOperator')){return true}
                              else{return false}}
    },
    mounted(){
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    logout: async function (){
        await this.$store.dispatch('logout');

      },
    },
  data: () => ({
  }),
};
</script>

<style>
.nav-link .btn-inv .site-logo{
  color: #000;
}
.site-navbar{
  padding-top: 1.4vh !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

</style>
