<template>
  <tr v-if="!loading">
    <td>{{index}}</td>
    <td>{{StartNode.NodeCity}}, {{StartNode.NodeLocale}}</td>
    <td>{{EndNode.NodeCity}}, {{EndNode.NodeLocale}}</td>
    <td>{{Truck.TruckName}} ({{Truck.TruckMaxLoad}} MT)</td>
    <td v-if="Cost>0 && userType!='Operator'"><input type="number" style="width: 110px; background-color: green" v-model="Cost" v-on:input="OnChangeSelectCost"></td>
    <td v-if="Cost<=0 && userType!='Operator'"><input type="number" style="width: 110px" placeholder="Cost" v-model="form.RouteCost"></td>
    <td v-if="userType=='Operator'">{{form.RouteCost}}</td>
    <td v-if="userType!='Operator'"><button class="btn-inv icon-plus" title="add" v-on:click="addRoute()"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'RouteLine',
        components: {
    },
    props:{
      StartNode: Object,
      EndNode: Object,
      Truck: Object,
      index: Number,
      Cost: Number,
      userType: String
    },
    data(){
        return{
          form:{},
          loading: true

        }
    },
    mounted(){
      if(this.Cost){
        this.form.RouteCost = this.Cost;
      }
      this.loading = false
    },
    methods:{
      addRoute(){
        this.form.StartNodeId = this.StartNode.NodeId;
        this.form.EndNodeId = this.EndNode.NodeId;
        this.form.TruckId = this.Truck.TruckId;
        this.$store.dispatch('addRoute', this.form).then(() => {
          this.$emit('added');
          this.$alert("added route!");
          }).catch(() => {
            this.$alert("Error adding route!");
          })
      },
      OnChangeSelectCost(){
        this.form.RouteCost = this.Cost
      }
      },
    }
</script>

