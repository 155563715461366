<template>
   <v-app id="inspire">

    <Header ></Header>
    <Sidebar v-on:item-click="sidebarItem = $event" v-on:bar-collapsed="sidebarCollapsed = $event"></Sidebar>

  <div>
    <div class="row">
      <div class='col-md-1' v-if="sidebarCollapsed == true"/>
      <div class='col-md-3' v-else/>
      <div class="col-md-11" v-if="sidebarCollapsed == true">
    <v-content>
      <v-container class="fill-height" fluid>
        <router-view/>
      </v-container>
    </v-content>
  </div>
  <div class="col-md-9" v-if="sidebarCollapsed == false">
    <v-content>
      <v-container class="fill-height" fluid>
        <router-view/>
      </v-container>
    </v-content>
  </div>
  </div>
</div>



  <Footer id="footer" style="margin-top: 100px"></Footer>
  </v-app>
</template>

<script>
import Header from "./header/Header";
import Footer from "./footer/Footer";
import Sidebar from "./sidebar/OperatorSideBar";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Layout",

  components: {
    Header,
    Sidebar,
    Footer
  },

  props: {
    source: String
  },
  data: () => ({
    expandOnHover: true,
    sidebarCollapsed: false,
    sidebarItem: '',
  }),
  computed: {
    ...mapState(["Customizer_drawer"])
  },

  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER"
    })
  }
};
</script>
