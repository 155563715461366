<template>
  <div id="client-page">
    <div class="row container ">
    <h1 class="section-title-mod" >Client {{Client.ClientNumber}}
      <span v-if="Client.ClientSubscription=='Yes'" class="icon-check-square-o" style="color: green"></span>
      <span v-else class="icon-stop" style="color: red; font-size: 30px">UnSubscribed</span></h1>
  </div>
  <div v-if="Client.ClientSubscription=='Left'"><p style="font-size: 24px; color: red">UnSubscription Date: <span v-if="Client.UnsubscriptionDate">{{displayDate(Client.UnsubscriptionDate)}}</span><span v-else>NA</span></p></div>
    <div class="row mb-3">
      <div class="col-lg-7">
        <p style="font-size: 24px">
          Nearest Mandi: {{Client.NearestMandi}}
          <br>
        <span class="line"></span>
        <br>
        Name: {{Client.ClientName}}
        <br>
        <span class="line"></span>
        <br>
        Subscription Date: <span v-if="Client.SubscriptionDate">{{displayDate(Client.SubscriptionDate)}}</span>
        <span v-else>NA</span>
        <br>
        <span class="line"></span>
        <br>
        Last Bill Date: <span v-if="Client.LastBillDate">{{displayDate(Client.LastBillDate)}}</span>
        <span v-else>NA</span>
        <br>
        <span class="line"></span>
        <br>
        Preffered Category: {{Client.PrefferedCategory}}
         </p>
      </div>
      <div class="col-lg-1 text-center">
        <span class="vline"></span>
      </div>
      <div class="col-lg-4">
        <p style="font-size: 24px">
          <span v-if="Client.isFarmer =='1'">Farmer</span>
          <span v-else>Not Farmer</span>
          <br>
        <span class="line"></span>
        <br>
        District: {{Client.ClientDistrict}}
        <br>
        <span class="line"></span>
        <br>
        Locale: {{Client.ClientLocale}}
        <br>
        <span class="line"></span>
        <br>
         </p>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-lg-12">
        <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row">
                    <div class="col-xs-5">
                        <h2>Preferred <b>Items</b></h2>
                    </div>
                    <div class="col-xs-7">
                        <button v-on:click="AddItemForm=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add Preferred Item</span></button>
                    </div>
                </div>
            </div>
        <table v-if="RefreshTable" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name (English)</th>
                        <th>Name (Urdu)</th>
                        <th>Category</th>
                        <th>Default Unit</th>
                    </tr>
                </thead>
                <tbody v-for="(item, index) in PreferredItems" :key="index">
                    <ItemLine :item="item" :index="index" type='Preferred' v-on:item-removed="refreshItems()"/>
                </tbody>
                <tbody>
                  <AddItemLine v-if="AddItemForm" :ClientNumber="ClientNumber" :index="index" type='Preferred' v-on:item-added="refreshItems()"/>
                </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemLine from './ItemLine'
import AddItemLine from './AddItemLine'
    export default {
        name: 'ClientPage',
    components:{
      ItemLine,
      AddItemLine
    },
    props: {
      ClientNumber: String,
      ClientInfo: Object
    },
    data(){
        return{
          Client: Object,
          PreferredItems: [],
          AddItemForm: false,
          RefreshTable: true
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    displayDate(date){
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    let year = date.slice(0, 4);
    return String(day+'/'+month+'/'+year)
  },
  refreshItems(){
    this.RefreshTable = false;
    this.$store.dispatch('getClientItems', this.ClientNumber).then(resp =>
      {
        this.PreferredItems = resp.data.data.PreferredItems;
        this.RefreshTable = true;
      });
    this.AddItemForm=false;
  }
    },
    created(){
      if(this.ClientInfo){
        this.Client = this.ClientInfo;
      }
      else{
        this.$store.dispatch('getClientById', this.ClientNumber).then(resp => this.Client = resp.data.data)
      }
      this.$store.dispatch('getClientItems', this.ClientNumber).then(resp => this.PreferredItems = resp.data.data.PreferredItems)
    }

    }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.line {
  width: 95%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 3px;
  display:inline-block;
}
</style>
