<template>
  <tr>
    <td>{{index}}
    </td>
    <td>{{mandi.MandiName}}</td>
    <td>{{mandi.City}}, {{mandi.District}}</td>
    <td><span v-if="mandi.RetailOnly==1">Retail</span>
      <span v-if="mandi.RetailOnly==2">Bulk</span>
      <span v-if="mandi.RetailOnly==0">Bulk + Retail</span></td>
    <td><button class="btn-inv" v-on:click="goToMandiRate(mandi.MandiName, mandi.MandiId)">Rates & Predictions</button></td>
</tr>
</template>

<script>
    export default {
        name: 'MandiLine',
        components: {
    },
    props:{
      mandi: Object,
      index: Number,
      user: String
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            items: Object,
            todayItems: []
        }
    },
    methods:{
      async goToMandiRate(mandiName, mandiId){
      await this.getTodayRates(mandiId)
      this.$router.push({
          name: 'ItemBestRates',
          params: {
              Items: this.todayItems,
              itemName: "",
              Type: "MandiTrack",
              mandiName: mandiName,
              mandiId: mandiId,
              retailOnly: this.mandi.RetailOnly,
              mandi: Object
          }
      })
    },
    async getTodayRates(MandiId){
      await this.$store.dispatch("getTodayRates", MandiId).then(resp => this.todayItems = resp.data.data)
    },

    async getItems(MandiId){
      await this.$store.dispatch("getMandiItems", MandiId).then(resp => this.items = resp.data.data.items)
      console.log(this.items)
    },
    EditMandi: function(mandi){
        this.$router.push({
          name: 'EditMandi',
          params: {
              Mandi: mandi,
          }
      })
      }

      },
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
