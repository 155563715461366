<template>
  <tr>
    <td></td>
    <td v-if="Type=='Item'"><v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          :v-model="form.UserMandiId"
                          >
          </v-select></td>
    <td v-if="Type=='Mandi'"><v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select></td>
    <td><v-select class="btn-vselect rounded" v-on:input="OnChangeSelectPredict"
                          :value.sync="value"
                          :options="predictions"
                          :v-model="form.Prediction"
                          >
          </v-select></td>
    <td><button class="btn-inv icon-plus" v-on:click="AddPredict(form)"></button></td>
    <td></td>
    <td></td>
</tr>
</template>
<script>
    export default {
    name: 'PredictionLine',
    props:{
      mandiId: Number,
      itemId: Number,
      Type: String,
    },
    data(){
        return{
          form: {},
          predictions: ['Up', 'Same', 'Down'],
          Mandis: [],
          Items: []
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    OnChangeSelectMandi(e){
          this.form.MandiId = e.value
        },
    OnChangeSelectItem(e){
          this.form.ItemId = e.value
        },
    OnChangeSelectPredict(e){
          this.form.Prediction = e
        },
    AddPredict: async function(pred){
      await this.$store.dispatch('addPredict', pred)
        .then(() => {
          this.$emit('pred-added');
          this.$alert("Prediction added");
          }).catch(() => {
            this.$alert("Error! Prediction not added")
          })
      },
  },
  mounted(){
    if(this.Type=='Item'){
      this.form.ItemId = this.itemId;
    }
    if(this.Type=='Mandi'){
      this.form.MandiId = this.mandiId;
    }
    this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis)
    this.$store.dispatch('getAllItemsNames').then(resp => {this.Items = resp.data.data.items;
        })
    },
  computed:{
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName, value: g.ItemId}))
    },
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId}))
    },
  }
  }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
