<template>
<progress-bar
      :options="options"
      :value="value"
      />

</template>

<script>
    export default {
        name: 'ProgressElement',
    props:{
      value: Number
    },
    data(){
        return{
            options: {
            text: {
              color: '#FFFFFF',
              shadowEnable: true,
              shadowColor: '#000000',
              fontSize: 14,
              fontFamily: 'Helvetica',
              dynamicPosition: false,
              hideText: false
            },
            progress: {
              color: '#f0c419',
              backgroundColor: '#C0C0C0'
            },
            layout: {
              height: 35,
              width: 140,
              verticalTextAlign: 61,
              horizontalTextAlign: 43,
              zeroOffset: 0,
              strokeWidth: 30,
              progressPadding: 0,
              type: 'line'
            }
          }
        }
    },
    methods:{

  },
  mounted(){

  },
  computed:{

},}
</script>


