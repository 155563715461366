<template>
  <div id="mandi-form">
      <div v-if="Items" class="form-group row">
        <div class="col-md-12">
        <p>Select rated item:</p>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select>
      </div>
      </div>

      <div v-if="Units" class="form-group row">
        <div class="col-md-12">
        <p>Select rate unit: </p>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          :v-model="unitId"
                          >
          </v-select>
      </div>
      </div>

      <div class="form-group row" v-if="Mandis">
        <div class="col-md-12">
        <p>Select rate mandi: </p>
          <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          :v-model="form.UserMandiId"
                          >
          </v-select>
      </div>
      </div>

      <div v-if="!retailOnly" class="form-group row">
        <div class="col-md-12">
        <p>Enter Min/Max Rates:</p>
      </div>
        <div class="col-md-6">
          <input type="number" class="form-control" placeholder="Min Rate" v-model="form.MinRate" v-on:change="ChangeFormEmit">
        </div>
        <div class="col-md-6">
          <input type="number" class="form-control" placeholder="Max Rate" v-model="form.MaxRate" v-on:change="ChangeFormEmit">
        </div>
      </div>

            <div class="form-group row">
        <div class="col-md-12">
        <p>Retail Rate:</p>
      </div>
        <div class="col-md-12">
          <input type="number" class="form-control" placeholder="Retail Rate" v-model="form.RetailRate" v-on:change="ChangeFormEmit">
        </div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'ItemForm',
        components: {
    },
    props:{
      Inputform: Object,
    },
    data(){
        return{
            form: Object,
            Units: {
              UnitName: String,
              UnitId: Number
            },
            Mandis: Object,
            Items: Object,
            unitId: Number,
            Loading: true,
            retailOnly: false
        }
    },
    methods:{
        ChangeFormEmit(){
          if(this.retailOnly)
          {
            this.form.MaxRate = -1;
          this.form.MinRate = -1;
          }
          else{
            this.form.MaxRate = Number(this.form.MaxRate);
          this.form.MinRate = Number(this.form.MinRate);
          }
          this.form.RetailRate = Number(this.form.RetailRate)
          this.$emit('form-updated', this.form)
        },
        OnChangeSelectUnit(e){
          this.form.UnitId = e.value
        },
        OnChangeSelectItem(e){
          this.form.ItemId = e.value
        },
        OnChangeSelectMandi(e){
          this.form.MandiId = e.value;
          this.retailOnly = e.retailOnly
        },
    },
    created () {
        this.form = this.Inputform;
        this.Loading = true;
        this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units)
        this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis)
        this.$store.dispatch('getAllItemsNames').then(resp => {this.Items = resp.data.data.items;
          this.$emit('ready')})
  },
  computed:{
    SelectUnit(){
      return this.Units.map(g => ({label: g.UnitName, value: g.UnitId}))
    },
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName, value: g.ItemId}))
    },
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId, retailOnly: g.RetailOnly}))
    },
  }
}
</script>

