<template>
 <div id="main" >
  <div class="container col-lg-12">
   <div class="row align-items-center mb-3">

    <div class="col-md-6 col-lg-6 mb-4 mb-lg-0">
      <a class="work-thumb float-left" v-on:click="SelectedType('zoom-classes')">
         <div class="work-text">
              <h3>Make New Order</h3>
            </div>
          <img src="../../assets/images/fruits-vegetables.jpg" alt="Image" class="img-fluid" style="width:380px; height: 240px;">
        </a>
        <br>
    </div>
    <div class="col-md-6 col-lg-6 mb-4 mb-lg-0">
      <a class="work-thumb float-left" v-on:click="SelectedType('all')">
        <div class="work-text">
              <h3>Track My Orders</h3>
            </div>
          <img src="../../assets/images/order-track-shipping.jpg" class="img-fluid" style="width:380px; height: 240px; background-color: #fff">
        </a>
        <br>
    </div>

  </div>
  <div class="row align-items-center">
    <div class="col-md-6 col-lg-6 mb-4 mb-lg-0">
      <a class="work-thumb float-left" v-on:click="SelectedType('recorded-classes')">
        <div class="work-text">
              <h3>My Orders</h3>
            </div>
          <img src="../../assets/images/shopping-trolley-grocery.jpg" alt="Image" class="img-fluid" style="width:380px; height: 240px; background-size: length">
        </a>
        <br>
    </div>
    <div class="col-md-6 col-lg-6 mb-4 mb-lg-0">
      <a class="work-thumb float-left" v-on:click="SelectedType('all')">
        <div class="work-text">
              <h3>Edit Personal Info</h3>
            </div>
          <img src="../../assets/images/profile.jpg" class="img-fluid" style="width:380px; height: 240px; background-color: #fff">
        </a>
        <br>
    </div>
    </div>
  </div>
  </div>
</template>

<script>
  export default {
      name: 'OrderMain',
      components: {

        },
  data(){
      return{
      }
  },
  methods:{
    goTo: function(path){
    this.$store.dispatch('goTo', path);
  },
},
mounted(){

}

  }
</script>

<style>
</style>
