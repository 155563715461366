<template>
  <div id="mandi-form">
      <div class="form-group row">
        <div class="col-md-12">
        <p>English Name:</p>
      </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Name" v-model="form.ItemName" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Urdu Name (Use Urdu script):</p>
      </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Name" v-model="form.ItemUrduName" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div v-if="Units" class="form-group row">
        <div class="col-md-12">
        <p>Select a default unit: <b style="text-" v-if="FormType=='Edit'">{{form.Unit}}</b></p>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          :v-model="unitId"
                          >
          </v-select>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Select a category: <b v-if="FormType=='Edit'">{{form.ItemCategory}}</b></p>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectCategory"
                          :value.sync="value"
                          :options="Categories"
                          :v-model="form.ItemCategory"
                          >
          </v-select>
      </div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'ItemForm',
        components: {
    },
    props:{
      Inputform: Object,
      FormType: String,
    },
    data(){
        return{
            form: Object,
            Units: {
              UnitName: String,
              UnitId: Number
            },
            unitId: Number,
            Categories: ['Fruit', 'Vegetable', 'Grain']
        }
    },
    methods:{
        ChangeFormEmit(){
            this.$emit('form-updated', this.form)
        },
        OnChangeSelectUnit(e){
          this.form.DefaultUnitId = e.value
        },
        OnChangeSelectCategory(e){
          this.form.ItemCategory = e
        }
    },
    created () {
        this.form = this.Inputform;
        this.form.DefaultUnitId = this.Inputform.UnitId;
        this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units)
  },
  computed:{
    SelectUnit(){
      return this.Units.map(g => ({label: g.UnitName, value: g.UnitId})) || ""
    },
  }
}
</script>

