<template>
  <tr>
    <td>{{index}}</td>
    <td>{{user.BuyerName}}</td>
    <td>{{user.BuyerAddress}}, {{user.BuyerCity}}</td>
    <td>{{user.BuyerContact}}</td>
    <td>{{user.BuyerType}}</td>
    <td><span v-for="(pref,index) in user.BuyerPreference" :key="index">{{pref}}, </span></td>
    <td>{{user.UserName}}</td>
    <td><button class="btn-inv icon-settings" v-on:click="getBuyer(user.BuyerId)" hint="more"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'BuyerLine',
        components: {
          // ToggleSubscription
    },
    props:{
      user: Object,
      index: Number
    },
    data(){
        return{
        }
    },
    methods:{
      getBuyer: function(number){
        this.$router.push({
          name: 'BuyerPage',
          params: {
              BuyerId: number,
          }
      })
      },
      },
    mounted(){
    }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
