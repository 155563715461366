<template>
  <div id="login">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-7">
            <h2 class="section-title mb-3">Add Rate</h2>
            <div v-if="Loading"><p>Loading All Items...</p></div>
            <form v-on:submit.prevent="AddMandi">
              <RateForm FormType='Add' :Inputform="form" v-on:form-updated="form = $event" v-on:ready="Loading = false"/>

              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="AddFail == true" v-on:alert-closed="AddFail = false" :isWarning="AddFail" :msg="'Oops! Please Complete The Form'"/>
                    <Alert v-if="AddDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!AddDone" msg='Rate Added Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Add">
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
</div>
</template>

<script>
import Alert from '../../components/Alert.vue'
import RateForm from '../../components/RateForm'
    export default {
        name: 'login',
        components: {
            Alert,
            RateForm,
          },
    data(){
        return{
            form: {
            // ItemId: -1,
            // MandiId: -1,
            // UnitId: -1,
            // MinRate: 0,
            // MaxRate: 0
            },
            msg: '',
            isHidden: true,
            AddFail: false,
            AddDone: false,
            Loading: true
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    async AddMandi(){
        await this.$store.dispatch('addRate', this.form)
        .then(resp => {
          if(resp.data.error)
          {
            this.AddFail = true
          }
          else{this.AddDone = true}
          }).catch(() => this.AddFail = true)
  }
},

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
