<template>
  <div id="login">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h2 class="mb-3">Add Rate/Prediction In {{MandiName}} Mandi: </h2>
            <div v-if="Loading"><p>Loading All Items...</p></div>
            <form v-on:submit.prevent="AddRate">
              <RateForm FormType='Add' :Inputform="form" v-on:form-updated="form = $event" v-on:prediction-updated="Prediction= $event" v-on:ready="Loading = false" :retailOnly="retailOnly"/>

              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="AddRateFail == true" v-on:alert-closed="AddFail = false" :isWarning="AddRateFail" :msg="'Rate not added'"/>
                    <Alert v-if="AddRateDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!AddRateDone" msg='Rate Added Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="AddPredictFail == true" v-on:alert-closed="AddFail = false" :isWarning="AddPredictFail" :msg="'Prediction not added'"/>
                    <Alert v-if="AddPredictDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!AddPredictDone" msg='Prediction Added Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Add">
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
</div>
</template>

<script>
import Alert from '../../components/Alert.vue'
import RateForm from '../../components/RatePredictionForm'
    export default {
        name: 'login',
        components: {
            Alert,
            RateForm,
          },
          props:{
            MandiName: String,
            MandiId: Number,
            retailOnly: Boolean
          },
    data(){
        return{
            form: {
            },
            predictionForm:{
              ItemId: Number,
              MandiId: Number,
              Prediction: String
            },
            msg: '',
            isHidden: true,
            AddRateFail: false,
            AddRateDone: false,
            AddPredictFail: false,
            AddPredictDone: false,
            Loading: true,
            Prediction: String,
            retailMandi: Boolean
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    async AddRate(){
        this.form.MandiId = this.MandiId
        this.predictionForm.ItemId = this.form.ItemId;
        this.predictionForm.MandiId = this.form.MandiId;
        this.predictionForm.Prediction = this.Prediction;
        await this.$store.dispatch('addRate', this.form)
        .then(resp => {
          if(resp.data.error)
          {
            this.AddRateFail = true
          }
          else{this.AddRateDone = true}
          }).catch(() => this.AddRateFail = true)
        await this.$store.dispatch('addPredict', this.predictionForm)
        .then(resp => {
          if(resp.data.error)
          {
            this.AddPredictFail = true
          }
          else{this.AddPredictDone = true}
          }).catch(() => this.AddPredictFail = true)
  },
  mounted(){
    this.retailMandi = this.retailOnly;
  }
},

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
