<template>
  <form v-on:submit.prevent="ChangeFarm" class="container" style="margin-left: -7px">
       <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
          <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Change" style="background-color: red; border-color: black">
        </div>
      </div>

    </form>
</template>

<script>
    export default {
        name: 'changeMandi',
        components: {
    },
    props:{
      ClientInfo: Object
    },
    data(){
        return{
          form: {}
        }
    },
    methods:{
        ChangeFarm(){
          let alert = "";
          let value = "";
          if(this.ClientInfo.isFarmer=="1"){
            alert = "Unassociate Client With Farming?";
            value = "0"
          }
          else{
            alert = "Associate Client With Farming?";
            value = "1"
          }
          this.$confirm(alert).then(() =>{
            this.form = this.ClientInfo;
            this.form.isFarmer = value;
            let catForm = this.form;
          let ChangeForm ={
            'ClientNumber': this.ClientInfo.ClientNumber,
            'form': catForm
          };
          this.$store.dispatch('editClientById', ChangeForm).then(() =>  {
            this.$alert("Client Farming Association Changed!");
          this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientNumber: this.ClientInfo.ClientNumber,
          }
          })}).catch(() =>  this.$alert("Error! Client Farming Association Did Not Change"))
          }).catch(() =>{
             this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientInfo: this.ClientInfo,
          }
          })
        })
        }
    },
    created () {
        this.$store.dispatch('getAllMandis').then(resp =>
                                                  {this.Mandis = resp.data.data.mandis;
                                                    this.gotMandis = true;});

  },
  computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: String(g.MandiName)+', '+String(g.City), value: g.MandiId}))
    },
  }
}
</script>

