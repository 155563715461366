<template>
  <div id="login">
    <div class="section">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h1 class="mb-3">Edit
        <span v-if="Buyer.isExporter">Exporter</span>
        <span v-if="Buyer.isSeller && Buyer.onlySeller">Seller</span>
        <span v-if="Buyer.isSeller && !Buyer.onlySeller">Buyer & Seller</span>
        <span style="color: red" v-if="!Buyer.isSeller && !Buyer.isExporter">Buyer</span>
        <b> {{Buyer.BuyerName}}</b>:</h1>
            <form v-on:submit.prevent="BuyerEdit">
              <BuyerForm FormType='Edit' :Inputform="Buyer" :type="type" fromType='edit' v-on:form-updated="form = $event"/>
              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="SignupFail == true" v-on:alert-closed="SignupFail = false" :isWarning="SignupFail" :msg="'Oops! Please Complete The Form'"/>
                    <Alert v-if="SignupDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!SignupDone" msg='Edited Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Edit">
                </div>
              </div>
            </form>
          </div>
        </div>
         <div>
      </div>
    </div>
</div>
      </div>
</template>

<script>
import Alert from './Alert.vue'
import BuyerForm from './BuyerForm'
    export default {
        name: 'login',
        props:{
          Buyer: Object,
          type: String
        },
        components: {
            Alert,
            BuyerForm,
          },
    data(){
        return{
          form: Object,
            msg: '',
            isHidden: true,
            SignupFail: false,
            SignupDone: false,
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    BuyerEdit: function(){
      let Form = {
        'BuyerId': this.Buyer.BuyerId,
        'BuyerForm': this.form
      }
      this.$store.dispatch('editBuyer', Form).then(() => {
          this.SignupDone = true}).catch(() => this.SignupFail = true)
    }
},
    created(){
      this.form = this.Buyer
    }

  }
</script>
