<template>
  <div id="mandi-form">
      <div class="form-group row">
        <div class="col-md-12">
        <p>Mandi Name:</p>
      </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Name" v-model="form.MandiName" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Mandi Type: <span v-if="form.RetailOnly==1">{{mandiTypeOptions[1]}}</span> <span v-if="form.RetailOnly==2">{{mandiTypeOptions[2]}}</span> <span v-if="form.RetailOnly==0">{{mandiTypeOptions[0]}}</span><span v-if="form.RetailOnly==3">{{mandiTypeOptions[3]}}</span></p>
      </div>
        <div class="col-md-12">
          <v-select class="btn-vselect" v-on:input="OnChangeSelectRetail"
                          :value.sync="value"
                          :options="mandiTypeOptions"
                          >
          </v-select>
        </div>
      </div>

      <div v-if="form.RetailOnly==1" class="form-group row">
        <div class="col-md-12">
        <p>Associated bulk mandi: <span v-if="form.BulkMandiId">{{form.BulkMandiName}}</span></p>
      </div>
      <div class="col-md-12">
        <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          >
          </v-select>
      </div>
    </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Mandi Location (City/District):</p>
      </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="City Name" v-model="form.City" v-on:change="ChangeFormEmit">
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="District Name" v-model="form.District" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Mandi Coordinates (X, Y):</p>
      </div>
        <div class="col-md-6">
          <input type="number" step="any" class="form-control" placeholder="X" v-model="form.MandiX" v-on:change="value.replace(/\d+|^\s+|\s+$/g, '').trim(); ChangeFormEmit" @keydown.space.prevent dense >
        </div>
        <div class="col-md-6">
          <input type="number" step="any" class="form-control" placeholder="Y" v-model="form.MandiY" v-on:change="value.replace(/\d+|^\s+|\s+$/g, '').trim(); ChangeFormEmit" @keydown.space.prevent dense>
        </div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'MandiForm',
        components: {
    },
    props:{
      Inputform: Object,
      FormType: String,
    },
    data(){
        return{
            form: Object,
            mandiTypeOptions: ['Normal (Bulk + Retail)', 'Retail Only', 'Bulk Only', 'Source Only'],
            RetailOnly: String,
            Mandis: Object,
            Loading: true,
            BulkMandis: [],
            BulkMandi: ""
        }
    },
    methods:{
      OnChangeSelectRetail(e){
        this.form.RetailOnly = 0;
        if(e == this.mandiTypeOptions[0])
        {
          this.form.RetailOnly = 0;
        }
        if(e == this.mandiTypeOptions[1])
        {
          this.form.RetailOnly = 1;
        }
        if(e == this.mandiTypeOptions[2])
        {
          this.form.RetailOnly = 2;
        }
        if(e == this.mandiTypeOptions[3])
        {
          this.form.RetailOnly = 3;
        }
        console.log(this.form);
        this.$emit('form-updated', this.form)
        },
        ChangeFormEmit(){
          this.form.MandiX = Number(this.form.MandiX);
          this.form.MandiY = Number(this.form.MandiY);
          this.$emit('form-updated', this.form);
        },
        OnChangeSelectMandi(e){
          this.form.BulkMandiId = e.value;
          this.BulkMandi = e.label;
        },
        Trim(e) {
            this.form.MandiX = e.replace(/\d+|^\s+|\s+$/g, '').trim();
            this.ChangeFormEmit();
          }
    },
    created () {
        this.form = this.Inputform;
        console.log(this.form);
        this.$store.dispatch('getAllMandis').then(resp => {this.BulkMandis = resp.data.data.mandis.filter(mandi => {return mandi.RetailOnly==2});})
  },
  computed:{
    SelectMandi(){
      return this.BulkMandis.map(g => ({label: g.MandiName, value: g.MandiId}))
    },
  }
}
</script>

