<template>
  <div id="AllSubscriptions">
    <div v-if="userType=='Mandi'" class="section mb-5 raw first-section justify-content-center">
    </div>
    <div v-if="Loading"><p>Loading ...</p></div>
    <div class="container col-lg-12">
    <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row mb-3 justify-content-center">
                    <div class="col-xs-4">
                        <h2 v-if="itemId">{{itemName}} <b>Rates</b></h2>
                        <h2 v-else>Livestock <b>Rates</b></h2>
                    </div>
                </div>
                <div class="row mb-3 justify-content-center">
                    <div v-if="userType!='Operator'" class="col-xs-4">
                        <button v-on:click="AddItemForm=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add Livestock Rate</span></button>
                    </div>
                    <div class="col-xs-4">
                        <form class="search-form" action="" style="margin-top: -1px; margin-left: 20px">
                          <input class="search-box" v-model="search" placeholder="Search Name (en/urdu)" style="font-size: 14px;">
                          <i class="faa icon-search" style="font-size: 14px"></i>
                        </form>
                    </div>
                </div>
                <div class="row justify-content-center">
                        <p>Display From:</p>
                        <input type="date" name="from"
                               v-model="fromDate"
                               :max="yesterday">
                        <p>   - To: </p>
                        <input type="date" name="to"
                               v-model="toDate"
                               :max="today" :min="fromDate">
                        <button class="btn-inv icon-search" v-on:click="getRates()" title="search" style="margin-top: -20px; margin-left: 20px"></button>
                </div>
                <div class="row justify-content-center">
                    <input type="radio" id="Available" value="Available" v-model="picked">
                    <label for="Available"><p style="margin-top: 20px">   Available          </p></label>
                    <input type="radio" id="Sold" value="Sold" v-model="picked">
                    <label for="Sold"><p style="margin-top: 20px">   Sold</p></label>
                   <!--  <label class="btn-inv icon-photo_camera" style="font-size: 25px; color: green; margin-top: 3px;">
                      </label><p>= Photo Uploaded,   </p>
                      <label class="btn-inv icon-photo_camera" style="font-size: 25px; margin-top: 3px;">
                      </label><p>= No Photos Found</p> -->
                </div>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Seller</th>
                        <th>Mandi</th>
                        <th>Age</th>
                        <th>Number</th>
                        <th>Asking Price</th>
                        <th>Sold</th>
                        <th>Avg. Weight</th>
                        <th>Notes</th>
                    </tr>
                </thead>
                <tbody v-if="!Loading">
                  <AddLivestalkRateLine v-if="AddItemForm" :index="index" v-on:rate-added="refreshItems()" type='Livestalk'/>
                </tbody>
                <tbody v-for="(item,index) in displayList" :key="index">
                    <LivestalkRateLine :item="item" :index="item.LivestalkRateId" v-on:rate-edited="refreshItems()" :userType='userType'/>
                </tbody>
            </table>
        </div>
    </div>
</div>

      </div>
</template>

<script>
import LivestalkRateLine from '../../components/LivestalkRateLine'
import AddLivestalkRateLine from '../../components/NewLivestalkRateLine'
    export default {
        name: 'AllSubscriptions',
        components: {
      LivestalkRateLine,
      AddLivestalkRateLine
    },
    props:{
        itemId: Number,
        itemName: String,
        userType: String,
    },
    data(){
        return{
            items: [],
            msg: '',
            AddFail: false,
            AddDone: false,
            StatusFlag: false,
            Loading: true,
            search: "",
            AddItemForm: false,
            yesterday: "",
            lastweek: "",
            today: "",
            fromDate: "",
            toDate: "",
            picked: "Available"
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    setFrom(e){
        this.fromDate = e;
    },
    setTo(e){
        this.toDate = e;
    },
    dateFormate(date){
        let month = '';
        let day = '';
        if(date.getMonth()+1 < 10){
            month = '0' + (date.getMonth()+1)
        }
        else{
            month = (date.getMonth()+1)
        }
        if(date.getDate() < 10){
            day = '0' + (date.getDate())
        }
        else{
            day = (date.getDate())
        }
        return date.getFullYear()+'-'+month+'-'+day;
    },
     setDates(){
      const today_row = new Date();
      const yesterday_row = new Date(today_row);
      const lastweek_row = new Date(today_row);
      today_row.setDate(today_row.getDate() + 1);
      yesterday_row.setDate(yesterday_row.getDate() - 1);
      lastweek_row.setDate(lastweek_row.getDate() - 30);
      this.today = this.dateFormate(today_row);
      this.yesterday = this.dateFormate(yesterday_row);
      this.lastweek = this.dateFormate(lastweek_row);
      return
    },
    getRates(){
        let TimePeriod = {'from': this.fromDate,
                        'to': this.toDate};
        this.Loading = true;
        if(this.itemId){
            this.$store.dispatch('getAllLivestalkRates', TimePeriod).then(resp =>
                                           {this.items = resp.data.data.filter(item => {return item.ItemId == this.itemId}).sort(function(a, b){
                if(a.ItemName < b.ItemName) { return -1; }
                if(a.ItemName > b.ItemName) { return 1; }
                return 0;
            });
        this.Loading = false})
        }
        else{
            this.$store.dispatch('getAllLivestalkRates', TimePeriod).then(resp =>
                                           {this.items = resp.data.data.sort(function(a, b){
                if(a.ItemName < b.ItemName) { return -1; }
                if(a.ItemName > b.ItemName) { return 1; }
                return 0;
            });
        this.Loading = false})
        }
    this.AddItemForm=false;
    },
    refreshItems(){
    this.picked = 'Available';
    this.setDates();
    this.fromDate = this.lastweek;
    this.toDate = this.today;
    this.getRates()
  }
    },
    mounted(){
        this.refreshItems()
    },
    computed : {
       filteredList() {
        if(!this.Loading)
        {
            return this.items.filter(item => {
        return !item.IsSold && (item.ItemName.toLowerCase().includes(this.search.toLowerCase()) || item.ItemUrduName.toLowerCase().includes(this.search.toLowerCase()))
      })
        }
        else{
            return {}
        }
    },
    filteredSoldList() {
        if(!this.Loading)
        {
            return this.items.filter(item => {
        return item.IsSold && (item.ItemName.toLowerCase().includes(this.search.toLowerCase()) || item.ItemUrduName.toLowerCase().includes(this.search.toLowerCase()))
      })
        }
        else{
            return {}
        }
    },
    displayList(){
        if(this.picked == "Available"){
            return this.filteredList
        }
        else{
            return this.filteredSoldList
        }
    }
    },
    }
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
    color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
        border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 13px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
    .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
[type="date"] {
  background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
  width:  200px;
  height: 30px;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
</style>
