<template>
  <div id="login">
    <div class="section first-section">
    <div id="section-login" class="row justify-content-center">
          <div class="col-md-7">
            <h2 class="section-title mb-3">Log In</h2>
            <LogIn/>
          </div>
        </div>
</div>
      </div>
</template>

<script>
import LogIn from '../../components/login_part';
    export default {
        name: 'login',
        components: {
            LogIn,
          },
    data(){
        return{
            form: {
              Email: "",
              Password: "",
              PhoneNumber: "",
              FirstName: "",
              LastName: "",
              UserTypeId: Number,
            },
            msg: '',
            isHidden: true,
            SignupFail: false,
            SignupDone: false,
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
},
mounted(){
      console.log(localStorage.getItem('isAdmin'));
      console.log(localStorage.getItem('isOperator'));
      if(localStorage.getItem('isAdmin'))
      {
        this.goTo('/select')
      }
      if(localStorage.getItem('isOperator'))
      {
        this.goTo('/operator')
      }
      if(localStorage.getItem('isMandiOperator'))
      {
        this.goTo('/mandi-operator/select')
      }
    }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
