<template>
  <div id="login">
  <div class="section">
    <div class="row justify-content-center ">
        <div class="col-md-12">
          <h1 class="mb-3">Order # <b>{{order.OrderId}}</b></h1>
        </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
        <h3><b>Item:</b> {{order.ItemName}} - {{order.ItemUrduName}}</h3>
      </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
        <h3><b>Amount:</b> {{order.Quantity}} {{order.UnitName}}</h3>
      </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
        <h3><b>Delivery Details:</b> {{order.DeliveryAddress}}, {{order.DeliveryCity}}</h3>
      </div>
    </div>

    <div class="form-group row">
        <div class="col-md-12">
        <h3><b>Buyer:</b> <button class="btn-inv" v-on:click="getBuyer(order.BuyerId)">{{order.BuyerName}} - {{order.BuyerAddress}}, {{order.BuyerCity}}</button></h3>
      </div>
    </div>

    <div class="row justify-content-center ">
      <div class="col-md-12">
        <div class="modal-content">
            <div class="modal-body">
                <div v-if="!loading" class="progress-track">
                    <ul id="progressbar">
                        <li v-if="state>0" class="step0 active " id="step1">
                          <span v-if="state==1" id="three">Order {{status[0]}}</span>
                          <button v-else class="btn-inv stat-btn" v-on:click="changeStatus(status[0])">Order {{status[0]}}</button>
                        </li>
                        <li v-else class="step0" id="step1"><button class="btn-inv stat-btn" v-on:click="changeStatus(status[0])">Order {{status[0]}}</button></li>

                        <li v-if="state>1" class="step0 active text-center" id="step2">
                          <span v-if="state==2" id="three">{{status[1]}}</span>
                          <button v-else class="btn-inv stat-btn" v-on:click="changeStatus(status[1])">{{status[1]}}</button>
                        </li>
                        <li v-else class="step0 text-center" id="step2"><button class="btn-inv stat-btn" v-on:click="changeStatus(status[1])">{{status[1]}}</button></li>

                        <li v-if="state>2" class="step0 active text-right" id="step3">
                          <span v-if="state==3" id="three">{{status[2]}}</span>
                          <button v-else class="btn-inv stat-btn" v-on:click="changeStatus(status[2])">{{status[2]}}</button>
                        </li>
                        <li v-else class="step0 text-right" id="step3"><button class="btn-inv stat-btn" v-on:click="changeStatus(status[2])">{{status[2]}}</button></li>

                        <li v-if="state>3" class="step0 active text-right" id="step4">
                          <span v-if="state==4" id="three">{{status[3]}}</span>
                          <button v-else class="btn-inv stat-btn" v-on:click="changeStatus(status[3])">{{status[3]}}</button>
                        </li>
                        <li v-else class="step0 text-right" id="step4"><button class="btn-inv stat-btn" v-on:click="changeStatus(status[3])">{{status[3]}}</button></li>
                    </ul>
                </div>
                <span v-else>loading...</span>

                <div v-if="!loading" class="row">
                    <div class="col-9">
                        <div class="details d-table">
                            <div class="d-table-row">
                                <div class="d-table-cell"> Created on  </div>
                                <div class="d-table-cell"> {{order.DateCreated}} </div>
                            </div>

                            <div class="d-table-row" v-for="(hist,index) in orderHistory" :key="index">
                                <div class="d-table-cell" v-if="index>0"> {{hist.OrderStatus}}</div>
                                <div class="d-table-cell" v-if="index>0"> {{hist.UpdateTime}} </div>

                            </div>
                            <div class="d-table-row">
                                <div class="d-table-cell"> Estimated Delivery </div>
                                <div class="d-table-cell"> {{order.DeliveryDate}} </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div  v-for="(hist,index) in orderHistory" :key="index" class="d-table-row"> <a href=#><i class="fa fa-phone" aria-hidden="true"></i></a> </div>
                        <div class="d-table-row"> <a href=#><i class="fa fa-envelope" aria-hidden="true"></i></a> </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    </div>
         <div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'login',
        props:{
          order: Object
        },
        components: {
          },
    data(){
        return{
          status:['Initiated', 'InProcess', 'Fulfilled', 'Delivered'],
          form: Object,
          addItem: false,
          loading: true,
          refresh: false,
          state: 0,
          orderHistory: []
        }
    },
    methods:{
      goTo: async function(path){
      this.$store.dispatch('goTo', path);
    },
    getBuyer: function(number){
        this.$router.push({
          name: 'BuyerPage',
          params: {
              BuyerId: number,
          }
      })
      },
  changeStatus(e){
      let orderForm = {
        'OrderId' : this.order.OrderId,
        'OrderStatus': e
      }
      this.$store.dispatch('changeOrderStatus', orderForm)
      .then(() =>{
            this.$alert("Order status changed")
            this.goTo('/admin/all-orders').then(() =>
            this.$router.push({
              name: 'OrderPage',
              params: {
                  order: this.order,
              }
            })
            )
          })
    },
},
    mounted(){
      this.$store.dispatch('getOrderById', this.order.OrderId).then(resp => {
        this.order = resp.data.data;
        if(resp.data.data.OrderHistory){
          this.orderHistory = resp.data.data.OrderHistory;
        }
        let i = 0;
        for(const x of this.status){
          if(this.order.OrderStatus == x){
            this.state = i + 1
          }
          i = i + 1;
        }
        this.loading = false;
          })
    }

  }
</script>

<style>
.modal-content {
    background-color: #1a1a1a;
    border-color: #42469d;
    border-radius: 1rem
}

/*@media (min-width: 576px) {
    .modal-dialog {
        max-width: 750px;
        margin: 1.75rem auto
    }
}*/

.show {
    padding: 0
}

.modal-header {
    border-bottom: none;
    text-align: center
}

.modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem 0;
    color: #fff
}

:-moz-any-link:focus {
    outline: none
}

.modal-title {
    line-height: 3rem
}

.modal-body {
    padding: 1rem
}

#progressbar {
    margin-bottom: 3vh;
    overflow: hidden;
    color: white;
    padding-left: 0px;
    margin-top: 3vh
}

#progressbar li {
    list-style-type: none;
    font-size: 0.8rem;
    width: 25%;
    float: left;
    position: relative;
    font-weight: 400;
    color: white
}

#progressbar #step1:before {
    content: "";
    color: white;
    width: 20px;
    height: 20px;
    margin-left: 0px !important
}

#progressbar #step2:before {
    content: "";
    color: #fff;
    width: 20px;
    height: 20px;
    margin-left: 32%
}

#progressbar #step3:before {
    content: "";
    color: #fff;
    width: 20px;
    height: 20px;
    margin-right: 32%
}

#progressbar #step4:before {
    content: "";
    color: rgb(151, 149, 149, 0.651);
    width: 20px;
    height: 20px;
    margin-right: 0px !important
}

#progressbar li:before {
    line-height: 29px;
    display: block;
    font-size: 12px;
    background: rgb(151, 149, 149);
    border-radius: 50%;
    margin: auto;
    z-index: -1;
    margin-bottom: 1vh
}

#progressbar li:after {
    content: '';
    height: 3px;
    background: rgb(151, 149, 149, 0.651);
    position: absolute;
    left: 0%;
    right: 0%;
    margin-bottom: 2vh;
    top: 8px;
    z-index: 1
}

.progress-track {
    padding: 0 8%
}

#progressbar li:nth-child(2):after {
    margin-right: auto
}

#progressbar li:nth-child(1):after {
    margin: auto
}

#progressbar li:nth-child(3):after {
    float: left;
    width: 68%
}

#progressbar li:nth-child(4):after {
    margin-left: auto;
    width: 132%
}

#progressbar li.active:before,
#progressbar li.active:after {
    background: white
}

#three {
    font-size: 1.2rem
}

@media (max-width: 767px) {
    #three {
        font-size: 1rem
    }
}

.details {
    padding: 2rem;
    font-size: 1.4rem;
    line-height: 3.5rem
}

@media (max-width: 767px) {
    .details {
        padding: 2rem 0;
        font-size: 1rem;
        line-height: 2.5rem
    }
}

.d-table {
    width: 100%
}

.d-table-row {
    width: 100%
}

.d-table-cell {
    padding-left: 3rem
}

@media (max-width: 767px) {
    .d-table-cell {
        padding-left: 1rem
    }
}

.col-3 {
    display: grid;
    text-align: end
}

.col-3 .d-table-row {
    align-self: flex-end
}

.fa {
    font-size: xx-large;
    text-align: center;
    width: 3rem;
    padding: 0.5rem;
    color: #42469d;
    background-color: #fff;
    border-radius: 2rem;
    bottom: 0;
    right: 0
}

button:active {
    outline: none
}

button:focus {
    outline: none
}

.stat-btn{
  color: white;
}
</style>
