<template>
<div id="sidebar" :class="[{'collapsed' : collapsed}]">
  <div class="demo">
<sidebar-menu style="margin-top: 60px" :collapsed="collapsed" :menu="menu" :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick" :theme=selectedTheme>
    <span slot="dropdown-icon" class="icon-arrow_drop_down"></span>
    <span slot="toggle-icon" class="icon-toggle-on" v-if="collapsed"></span>
    <span slot="toggle-icon" class="icon-toggle-off" v-else></span>
        </sidebar-menu>
  </div>
</div>
</template>


<script>

export default {
  name: "UserSidebar",
  components: {
  },
 methods: {
    onToggleCollapse(collapsed){
      console.log(collapsed);
      this.collapsed = ! this.collapsed;
      this.$emit('bar-collapsed', this.collapsed);
      console.log(this.collapsed);
    },
    onItemClick(event, item) {
      console.log(event);
      if(item.link){this.goTo(item.link);}
      this.$emit('item-click', item.title);
      console.log(item.title);
    },
    goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
  },
  data() {
        return {
    themes: ['', 'default-theme'],
    selectedTheme: '',
    collapsed: false,
    menu: [
    {
    href: '/operator/item-farmer-mandis',
    title: 'Search Items in Farmer Mandi Groups',
    icon: 'icon-shopping-cart',
    },

    //   {
    //     href: '/operator/clients/add-client',
    //     title: 'Add Client',
    //     icon: 'icon-person',
    // },
      {
        href: '/operator/find-client',
        title: 'Search Clients',
        icon: 'icon-people',
    },
    {
        href: '/operator/subscribe-client',
        title: 'Subscribe Clients',
        icon: 'icon-person_add',
    },

      {
        href: '/operator/search',
        title: 'Search Items/Mandis',
        icon: 'icon-search'
    },
    {
    // props: {userType: 'Operator' },
    href: '/operator/all-livestalk',
    title: 'Livestock',
    icon: 'icon-paw',
    child:[
      {
        link: '/operator/all-livestalk-rates',
        title: 'All Livestock Rates',
        icon: 'icon-attach_money',
        attributes: {userType: 'Operator' },
      },
    ]
  },
   {
        href: '/operator/all-routes',
        title: 'Transportation Routes',
        icon: 'icon-road',
    },
    ]
  }},
  mounted() {
      let recaptchaScript = document.createElement('../../assets/js/sidebar.js')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      document.head.appendChild(recaptchaScript)
    },
};
</script>

