<template>
  <div id="AllSubscriptions">
    <div class="container col-lg-12">
    <div class="table-responsive">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row">
                    <div class="col-xs-5">
                        <h2 v-if="Type=='Item'">{{itemName}} <b>Predictions</b></h2>
                        <h2 v-if="Type=='Mandi'">{{mandiName}} <b>Predictions</b></h2>
                    </div>
                    <div class="col-xs-7">
                        <button v-if="user!='Operator'" v-on:click="AddPredForm=true" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Prediction</span></button>
                    </div>
                </div>
            </div>
            <table v-if="Type=='Item'" class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#Record</th>
                        <th>Mandi</th>
                        <th>Prediction</th>
                        <th>Record Time</th>
                        <th>Added By:</th>
                        <th>Phone</th>
                    </tr>
                </thead>
                <tbody>
                    <NewPredictionLine v-if="AddPredForm" :Type="Type" :itemId="ItemRates.ItemId" v-on:pred-added="refreshPreds()"/>
                </tbody>
                <tbody v-for="(predict, index) in ItemRates.LastPrediction" :key="index">
                    <PredictionLine :Prediction="predict" :Type="Type" :ItemName="itemName"/>
                </tbody>
            </table>
            <table v-else class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#Record</th>
                        <th>Item</th>
                        <th>Prediction</th>
                        <th>Record Time</th>
                        <th>Added By:</th>
                        <th>Phone</th>
                    </tr>
                </thead>
                <tbody>
                    <NewPredictionLine v-if="AddPredForm" :Type="Type" :mandiId="mandiId" v-on:pred-added="refreshPreds()"/>
                </tbody>
                <tbody v-for="(predict, index) in ItemRates" :key="index">
                    <PredictionLine v-if="Type=='MandiTrack'" :Prediction="predict" :Type="Type" :ItemName="predict.ItemName"/>
                    <PredictionLine v-else :Prediction="predict.LastPrediction[0]" :Type="Type" :ItemName="predict.ItemName"/>
                </tbody>
            </table>
        </div>
    </div>
</div>

      </div>
</template>

<script>
import PredictionLine from './PredictionLine';
import NewPredictionLine from './NewPredictionLine'
    export default {
        name: 'AllSubscriptions',
        components: {
      PredictionLine,
      NewPredictionLine
      // Alert,
    },
    props:{
        Items: Array,
        itemName: String,
        Type: String,
        mandiName: String,
        user: String,
        mandiId: Number
    },
    data(){
        return{
            ItemRates: Object,
            msg: '',
            AddFail: false,
            AddDone: false,
            StatusFlag: false,
            AddPredForm: false,
            Loading: false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    refreshPreds: function(){
        this.Loading = true;
    if(this.Type == 'Item'){
        this.$store.dispatch('getItemRates', this.Items.ItemId).then(resp => {this.ItemRates = resp.data.data.item;
        this.Loading = false;
        });
    }
    if(this.Type == 'Mandi'){
        this.$store.dispatch('getMandiItems', this.mandiId).then(resp => {this.ItemRates = resp.data.data.items;
        this.Loading=false});
    }
    this.AddPredForm=false;
    }
    },
    mounted(){
    this.$store.dispatch('getItemPredictions', this.Items.ItemId).then(resp => this.ItemRates = resp.data.data.item)
    // if(this.Type=='Item'){
    //   this.$store.dispatch('getItemRates', this.Items.ItemId).then(resp => this.ItemRates = resp.data.data.item)
    // }
    // else{
    //     this.ItemRates = this.Items;
    // }
    }
    }
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
        color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
    border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 13px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
</style>
