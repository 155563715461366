<template>
<footer class="footer-section">
      <div class="container">
        <div class="row">
          <div class="col-md-3 ml-auto">
            <h3 style="color: #000">Madadgaar LTD</h3>
          </div>

          <div class="col-md-3 ml-auto">
          </div>

          <div class="col-md-4 ml-auto">
        </div>

      </div>
    </div>
    </footer>
</template>
<script>
export default {
    name: "Footer",
    computed : {
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    }
    },
}
</script>
