<template>
  <tr>
    <td>
      {{index}}
    </td>
    <td>{{node.NodeCity}}</td>
    <td>{{node.NodeLocale}}</td>
    <td><a :href="src" class="icon-map-marker" style="color: red" target="_blank"></a> </td>
    <td><button class="btn-inv" v-on:click="gotoRoutes()">Check Routes</button></td>
    <td><button class="btn-inv icon-settings" title="edit" v-on:click="editNode()"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'MandiLine',
        components: {
    },
    props:{
      node: Object,
      index: Number
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            items: Object,
            src: ''

        }
    },
    mounted(){
      this.src = "http://www.google.com/maps/place/" + String(this.node.NodeX) + "," + String(this.node.NodeY)
    },
    methods:{
      gotoRoutes(){
         this.$router.push({
          name: 'NodeRoutesPage',
          params: {
              node: this.node,
          }
      })
      },
      editNode(){
        this.$emit('edit')
      }
      },
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
