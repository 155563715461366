<template>
  <div id="signup-form">
    <div class="form-group row" v-if="FormType=='Add'">
        <div class="col-md-12">
        <p>Select the user type: </p>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectUserType"
                          :value.sync="value"
                          :options="UserTypes"
                          :v-model="type"
                          >
          </v-select>
      </div>
      </div>

      <div class="form-group row" v-if="form.UserTypeId == 3">
        <div class="col-md-12">
        <p>The operator's mandi(s):
          <span v-if="FormType=='Edit'"><b v-for="(mandi,index) in form.mandis" :key="index">{{mandi.MandiName}}, </b></span></p>
          <v-select multiple v-if="gotMandis" class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          :v-model="mandi"
                          >
          </v-select>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Personal Information:</p>
      </div>
        <div class="col-md-6 mb-3 mb-lg-0">
          <input type="text" class="form-control" placeholder="First Name" v-model="form.FirstName" v-on:change="ChangeFormEmit">
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="Last Name" v-model="form.LastName" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Contact Information:</p>
      </div>
        <div class="col-md-6">
         <input v-if="FormType=='Edit'" disabled type="email" class="form-control"  v-model="form.Email" title="Email address cannot be edited" v-on:change="ChangeFormEmit">

          <input v-else type="email" class="form-control" placeholder="Email" v-model="form.Email" v-on:change="ChangeFormEmit">
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="Phone Number" v-model="form.PhoneNumber" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
            <p>Select Password:</p>
         <input v-if="isHidden" type="password" class="form-control" placeholder="Password"  v-model="Password" v-on:change="ChangeFormEmit">
         <input v-else type="text" class="form-control" placeholder="Password" v-model="Password" v-on:change="ChangeFormEmit">
         <a v-on:click="isHidden = !isHidden" class="fa fa-fw fa-eye field-icon toggle-password icon-eye"></a>
        </div>
      </div>
</div>
</template>

<script>
    export default {
        name: 'SignUpForm',
        components: {
    },
    props:{
      FormType: String,
      Inputform: Object,
    },
    data(){
        return{
            form: Object,
            isloading: true,
            ImageUrl: '',
            ImageAlert: false,
            isHidden: true,
            UserTypes: ['Admin', 'Data Operator', 'Mandi Operator'],
            type: '',
            Mandis: Object,
            mandi: [],
            gotMandis: false,
            Password: ""
        }
    },
    methods:{
        ChangeFormEmit(){
          this.form.Password = this.Password;
          this.$emit('form-updated', this.form)
        },
        OnChangeSelectUserType(e){
          const isType = (element) => element == e;
          this.type = e;
          this.form.UserTypeId = this.UserTypes.findIndex(isType) + 1;
        },
        OnChangeSelectMandi(e){
          console.log('selection');
          console.log(e);
          for (let i in e){
            this.form.UserMandiList.push(e[i].value);
          }
          this.$emit('form-updated', this.form)
        }
    },
    created () {
        this.form = this.Inputform;
        this.form.UserMandiList = []
        this.$store.dispatch('getAllMandis').then(resp =>
                                                  {this.Mandis = resp.data.data.mandis.filter(man => {return man.RetailOnly != 3});
                                                    this.gotMandis = true;});

  },
  computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: String(g.MandiName)+', '+String(g.City), value: g.MandiId}))
    },
  }
}
</script>

