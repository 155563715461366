<template>
  <tr>
    <td>{{index}}</td>
    <td>{{user.ClientNumber}}</td>
    <td>{{user.ClientName}}</td>
    <td><button class="btn-inv icon-question-circle" v-on:click="getUser(user.ClientNumber)" hint="more"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'ClientLine',
        components: {
          // ToggleSubscription
    },
    props:{
      user: Object,
      index: Number,
      type: String
    },
    data(){
        return{
        }
    },
    methods:{
      getUser: function(number){
        this.$router.push({
          name: 'ClientInfoPageAdmin',
          params: {
              ClientNumber: number,
              Type: "Admin"
          }
      })
      }
      },
    mounted(){
    }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
