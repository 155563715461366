<template>
  <tr>
    <td v-if="Type!='MandiTrack'">{{index}}</td>
    <td v-else >{{index}}</td>
    <td v-if="Type=='Item'"><v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          >
          </v-select></td>
    <td v-else><v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select></td>
    <td v-if="RetailOnly!=1"><input type="number" style="width: 100px" placeholder="Min Rate" v-model="form.MinRate"></td>
    <td v-if="RetailOnly==1 && Type=='Item'"><i class="material-icons">&#x274C;</i></td>
    <td v-if="RetailOnly!=1"><input type="number" style="width: 100px" placeholder="Max Rate" v-model="form.MaxRate"></td>
    <td v-if="RetailOnly==1 && Type=='Item'"><i class="material-icons">&#x274C;</i></td>
    <td v-if="RetailOnly!=2"><input type="number" placeholder="Retail Rate" style="width: 100px" v-model="form.RetailRate"></td>
    <td v-if="RetailOnly==2 && Type=='Item'"><i class="material-icons">&#x274C;</i></td>
    <td> <v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          :v-model="unitId"
                          >
          </v-select></td>
    <td></td>
    <td v-if="Type=='MandiTrack'"><v-select class="btn-vselect rounded" v-on:input="OnChangeSelectPredict"
                          :value.sync="value"
                          :options="predictions"
                          :v-model="Prediction"
                          v-on:change="OnChangeSelectPredict"
                          >
          </v-select></td>
    <td v-if="Type=='MandiTrack'" ><button class="btn-inv icon-plus" v-on:click="AddRatePredict()"></button></td>
    <td v-else><button class="btn-inv icon-plus" v-on:click="AddRate(form)"></button></td>
    <td></td>
</tr>
</template>
<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'RateLine',
        components: {
          // ToggleSubscription
    },
    props:{
      Rate: Object,
      Type: String,
      ItemName: String,
      index: Number,
      retailOnly: Number,
      itemId: Number,
      mandiId: Number
    },
    data(){
        return{
          RetailOnly: Number,
          form: {},
          Units: [],
          Items: [],
          Mandis: [],
          Loading: true,
          predictions: ['Up', 'Same', 'Down'],
          predictionForm: {},
          Prediction: '',
          rateMsg: '',
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    OnChangeSelectMandi(e){
          this.form.MandiId = e.value;
          this.RetailOnly = e.retailOnly;
        },
    OnChangeSelectItem(e){
          this.form.ItemId = e.value
        },
    OnChangeSelectUnit(e){
          this.form.UnitId = e.value
        },
    OnChangeSelectPredict(e){
          this.Prediction = e
          this.$emit('prediction-updated', this.Prediction)
        },
    AddRate: async function(rate){
      if(this.RetailOnly==1)
          {
            rate.MaxRate = -1;
            rate.MinRate = -1;
          }
          else{
            rate.MaxRate = Number(rate.MaxRate);
            rate.MinRate = Number(rate.MinRate);
          }
          rate.RetailRate = Number(rate.RetailRate)
      await this.$store.dispatch('addRate', rate)
        .then(() => {
          this.rateMsg = "Rate added";
          if(this.Type!='MandiTrack'){
            this.$alert(this.rateMsg );
            this.$emit('rate-added');
          }
          }).catch(() => {
            this.rateMsg = "Rate not added!";
            if(this.Type!='MandiTrack'){
            this.$alert(this.rateMsg );
            this.$emit('rate-added');
          }
          })
      },
    AddRatePredict: async function(){
      this.predictionForm.ItemId = this.form.ItemId;
      this.predictionForm.MandiId = this.form.MandiId;
      this.predictionForm.Prediction = this.Prediction;
      await this.AddRate(this.form).then(() => {this.$store.dispatch('addPredict', this.predictionForm).then(() => {
        this.$alert(this.rateMsg + ", Prediction added");
      }).catch(() => {
        this.$alert(this.rateMsg + ", Prediction not added!")
      })
    });
      this.$emit('rate-added');

    }

  },
  mounted(){
    this.RetailOnly = this.retailOnly;
    if(this.itemId){
      this.form.ItemId = this.itemId;
    }
    if(this.mandiId){
      this.form.MandiId = this.mandiId;
    }
    this.Loading = true;
    this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units)
    this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis)
    this.$store.dispatch('getAllItemsNames').then(resp => {this.Items = resp.data.data.items;
      this.Loading = false})
    },
  computed:{
    SelectUnit(){
      return this.Units.map(g => ({label: g.UnitName, value: g.UnitId}))
    },
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName, value: g.ItemId}))
    },
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId, retailOnly: g.RetailOnly}))
    },
  }
  }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
