<template>
  <div id="login">
    <div class="section">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h1 class="mb-3">Edit Producer <b>{{Producer.ProducerName}}</b>:</h1>
            <form v-on:submit.prevent="ProducerEdit">
              <ProducerForm FormType='Edit' :Inputform="Producer" v-on:form-updated="form = $event"/>
              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="SignupFail == true" v-on:alert-closed="SignupFail = false" :isWarning="SignupFail" :msg="'Oops! Please Complete The Form'"/>
                    <Alert v-if="SignupDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!SignupDone" msg='Producer Edited Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Edit">
                </div>
              </div>
            </form>
          </div>
        </div>
         <div>
      </div>
    </div>
</div>
      </div>
</template>

<script>
import Alert from './Alert.vue'
import ProducerForm from './ProducerForm'
    export default {
        name: 'login',
        props:{
          Producer: Object
        },
        components: {
            Alert,
            ProducerForm,
          },
    data(){
        return{
          form: Object,
            msg: '',
            isHidden: true,
            SignupFail: false,
            SignupDone: false,
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    ProducerEdit: function(){
      let Form = {
        'ProducerId': this.Producer.ProducerId,
        'ProducerForm': this.form
      }
      this.$store.dispatch('editProducer', Form).then(() => {
          this.SignupDone = true}).catch(() => this.SignupFail = true)
    }
},
    created(){
      this.form = this.Producer
    }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
