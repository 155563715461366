<template>
  <div id="client-page">
    <div class="row container ">
    <h1 class="section-title-mod" >Edit Client {{Client.ClientNumber}}</h1>
  </div>

    <div class="row mb-3">
      <div class="col-lg-9">
        <p style="font-size: 24px">
          <br>
          Nearest Mandi: {{Client.NearestMandi}} <button class="btn-inv icon-settings" v-on:click="EditMandi=true"></button>
          <br>
          <ChangeMandi v-if="EditMandi" :ClientInfo="ClientInfo"/>
        <span class="line"></span>
        <br>
        Name: {{Client.ClientName}} <button class="btn-inv icon-settings" v-on:click="EditName=true"></button>
        <br>
        <ChangeName v-if="EditName" :ClientInfo="ClientInfo" Type="Name"/>
        <span class="line"></span>
        <br>
        Subscription Date: <span v-if="Client.SubscriptionDate">{{displayDate(Client.SubscriptionDate)}}</span>
        <span v-else>NA</span>
        <br>
        <span class="line"></span>
        <br>
        Last Bill Date: <span v-if="Client.LastBillDate">{{displayDate(Client.LastBillDate)}}</span>
        <span v-else>NA</span>
        <br>
        <span class="line"></span>
        <br>
        Preffered Category: {{Client.PrefferedCategory}} <button class="btn-inv icon-settings" v-on:click="EditCategory=true"></button>
        <br>
        <ChangeCategory v-if="EditCategory" :ClientInfo="ClientInfo"/>
        <span class="line"></span>
        <br>
        <span v-if="Client.isFarmer =='1'"> Farmer </span>
          <span v-else> Not Farmer </span>
           <button class="btn-inv icon-settings" v-on:click="EditFarmer=true"></button>
        <br>
        <ChangeFarming v-if="EditFarmer" :ClientInfo="ClientInfo"/>
        <span class="line"></span>
        <br>
        District: {{Client.ClientDistrict}} <button class="btn-inv icon-settings" v-on:click="EditDist=true"></button>
        <br>
        <ChangeName v-if="EditDist" :ClientInfo="ClientInfo" Type="District"/>
        <span class="line"></span>
        <br>
        Locale: {{Client.ClientLocale}} <button class="btn-inv icon-settings" v-on:click="EditLocale=true"></button>
        <br>
        <ChangeName v-if="EditLocale" :ClientInfo="ClientInfo" Type="Locale"/>
         </p>
      </div>
    </div>

    <div class="row">
        <div class="col-md-4"/>
        <div class="col-md-4">
          <button class="btn btn-primary py-3 px-5 btn-block" v-on:click="CancelEdit">Cancel</button>
        </div>
      </div>
  </div>
</template>

<script>
import ChangeMandi from '../../components/ChangeMandi'
import ChangeName from '../../components/ChangeName'
import ChangeCategory from '../../components/ChangeCategory'
import ChangeFarming from '../../components/ChangeFarming'
    export default {
        name: 'EditClient',
        components:{
          ChangeMandi,
          ChangeName,
          ChangeCategory,
          ChangeFarming
        },
    props: {
      ClientNumber: String,
      ClientInfo: Object,
      Type: String
    },
    data(){
        return{
          Client: Object,
          EditMandi: false,
          EditName: false,
          EditCategory: false,
          EditFarmer: false,
          EditDist: false,
          EditLocale: false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    displayDate(date){
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    let year = date.slice(0, 4);
    return String(day+'/'+month+'/'+year)
  },
    CancelEdit: function(){
      if(this.Type=="Admin"){
        this.$router.push({
          name: 'ClientInfoPageAdmin',
          params: {
              ClientInfo: this.Client,
          }
      })
      }
      else{
      this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientInfo: this.Client,
          }
      })
    }
      },
    ChangeSubscription: function(){
      this.$router.push({
          name: 'ChangeClientSubscription',
          params: {
              ClientInfo: this.Client,
          }
      })
    }
    },
    created(){
      if(this.ClientInfo){
        this.Client = this.ClientInfo;
      }
      else{
        this.$store.dispatch('getClientById', this.ClientNumber).then(resp => this.Client = resp.data.data)
      }
    }

    }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.line {
  width: 95%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 3px;
  display:inline-block;
}
</style>
