import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'

Vue.use(Router)

let router = new Router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [
        {
          path: '*',
         component: () => import('@/views/pages/PageNotFound')
        },
        {
            path: '/',
            redirect: '/main',
            component: () => import('@/layouts/Layout'),
            children: [

                {
                    name: 'LogIn',
                    path: '/main',
                    component: () => import('@/views/pages/login'),
                },
                {
                    name: 'MainPage',
                    path: '/select',
                    component: () => import('@/views/pages/MainPage'),
                },
                {
                    name: 'MandiSelect',
                    path: '/mandi-operator/select',
                    props: true,
                    component: () => import('@/views/pages/SelectMandi'),
                },
                {
                   name: 'AllLivestalkRatesMandi',
                    path: '/mandi/all-livestalk-rates',
                    props: {userType: 'Mandi' },
                    component: () => import('@/views/pages/AllLivestalkRates'),
                },
                {
                    name: 'MandiPageAllItems',
                    path: '/mandi-operator/mandi',
                    props: true,
                    component: () => import('@/views/pages/DataEntryAllMandiItems'),
                },
                {
                    name: 'ItemSourcesManagement',
                    path: '/mandi-operator/item-sources',
                    props: true,
                    component: () => import('@/views/pages/AllItemSources'),
                }
            ]
        },
        {
            path: '/operator',
            redirect: '/operator/item-farmer-mandis',
            component: () => import('@/layouts/OperatorLayout'),
            children: [

                {
                    name: 'Search',
                    path: '/operator/search',
                    component: () => import('@/views/pages/SearchPage'),
                },
                 {
                    name: 'AllRoutesOp',
                    path: '/operator/all-routes',
                    props: {userType: 'Operator' },
                    component: () => import('@/views/pages/AllRoutes'),
                },
                {
                   name: 'AllLivestalkOp',
                    path: '/operator/all-livestalk',
                    props: {userType: 'Operator' },
                    component: () => import('@/views/pages/AllLivestalk'),
                },
                {
                   name: 'AllLivestalkRatesOp',
                    path: '/operator/all-livestalk-rates',
                    props: {userType: 'Operator' },
                    component: () => import('@/views/pages/AllLivestalkRates'),
                },
                {
                   name: 'ItemBestRates',
                    path: '/operator/item-best-rates',
                    props: true,
                    component: () => import('@/components/AllItemRates'),
                },
                 {
                   name: 'ItemsearchRates',
                    path: '/operator/item-farmer-mandis',
                    component: () => import('@/views/pages/SearchItemRates'),
                },
                {
                   name: 'AllClientsOperator',
                    path: '/operator/clients',
                    component: () => import('@/views/pages/AllClientsOperator'),
                },
                {
                   name: 'SubscribeClient',
                    path: '/operator/subscribe-client',
                    component: () => import('@/views/pages/SubscribeClientPage'),
                },
                {
                   name: 'ClientInfoPage',
                    path: '/operator/clients/client-info',
                    props: true,
                    component: () => import('@/views/pages/ClientPageOperator'),
                },
                 {
                   name: 'AddClientOperator',
                    path: '/operator/clients/add-client',
                    component: () => import('@/views/pages/AddClientOperator'),
                },
                {
                   name: 'ItemPage',
                    path: '/admin/item-history',
                    props: true,
                    component: () => import('@/views/pages/ItemPage'),
                },

                {
                   name: 'FindClient',
                    path: '/operator/find-client',
                    component: () => import('@/views/pages/FindClient'),
                },

                {
                   name: 'EditClientOperator',
                    path: '/operator/edit-client',
                    props: true,
                    component: () => import('@/views/pages/EditClient'),
                },
            ]
        },
        {
            path : "/order",
            redirect: '/order/main',
            component: () => import('@/order_platform/layouts/Layout'),
            meta: {
                    requiresAuth: true,
                    requiresAdmin: true
            },
            children: [
            {
                   name: 'NewOrder',
                    path: '/order/main',
                    props: true,
                    component: () => import('@/order_platform/views/pages/order_main'),
                },
            ]
        },
        {
            path : "/admin",
            meta: {
                    requiresAuth: true,
                    requiresAdmin: true
                      },
            component: () => import('@/layouts/AdminLayout'),
            children: [
            {
                    name: 'AllClients',
                    path: '/admin/all-clients',
                    component: () => import('@/views/pages/AllClients'),
                },

                {
                   name: 'ClientInfoPageAdmin',
                    path: '/admin/client',
                    props: true,
                    component: () => import('@/views/pages/ClientInfoPage'),
                },

                 {
                   name: 'EditClient',
                    path: '/admin/edit-client',
                    props: true,
                    component: () => import('@/views/pages/EditClient'),
                },

                {
                   name: 'ChangeClientSubscription',
                    path: '/admin/edit-client/subscription',
                    props: true,
                    component: () => import('@/views/pages/ChangeSubscription'),
                },

                {
                   name: 'FindClient',
                    path: '/admin/find-client',
                    component: () => import('@/views/pages/FindClient'),
                },

                {
                   name: 'BlockClient',
                    path: '/admin/block-client',
                    component: () => import('@/views/pages/BlockClient'),
                },

            ]

        },
        {
            path : "/admin",
            meta: {
                    requiresAuth: true,
                    requiresSemiAdmin: true
                      },
            component: () => import('@/layouts/OrderBuyLayout'),
            children: [
            {
                    name: 'AllBuyers',
                    path: '/admin/all-buyers',
                    component: () => import('@/views/pages/AllBuyers'),
                },
            {
                    name: 'AllExporters',
                    path: '/admin/all-exporters',
                    component: () => import('@/views/pages/AllExporters'),
                },
            {
                    name: 'AllSellers',
                    path: '/admin/all-sellers',
                    component: () => import('@/views/pages/AllSellers'),
                },
            {
                    name: 'AllProviders',
                    path: '/admin/all-providers',
                    component: () => import('@/views/pages/AllStorageProviders'),
                },

            {
                    name: 'AllRoutes',
                    path: '/admin/all-routes',
                    component: () => import('@/views/pages/AllRoutes'),
                },

            {
                    name: 'BuyerPage',
                    path: '/admin/buyer-info',
                    props: true,
                    component: () => import('@/views/pages/BuyerPage'),
                },
            {
                    name: 'AllProducers',
                    path: '/admin/all-producers',
                    component: () => import('@/views/pages/AllProducers'),
                },
            {
                    name: 'AllOrders',
                    path: '/admin/all-orders',
                    props:{
                        'type': 'all'
                    },
                    component: () => import('@/views/pages/AllOrders'),
                },
            {
                    name: 'AddProducer',
                    path: '/admin/add-producer',
                    component: () => import('@/views/pages/AddProducer'),
                },
            {
                    name: 'AddProvider',
                    path: '/admin/add-provider',
                    component: () => import('@/views/pages/AddProvider'),
                    props: true
                },
            {
                    name: 'AddBuyer',
                    path: '/admin/add-buyer',
                    component: () => import('@/views/pages/AddBuyer'),
                    props: true
                },
             {
                    name: 'AddSeller',
                    path: '/admin/add-seller',
                    component: () => import('@/views/pages/AddSeller'),
                    props: true
                },
            {
                    name: 'AddExporter',
                    path: '/admin/add-exporter',
                    component: () => import('@/views/pages/AddExporter'),
                    props: true
                },
                        {
                    name: 'EditProducer',
                    path: '/admin/edit-producer',
                    props: true,
                    component: () => import('@/components/EditProducer'),
                },
                 {
                    name: 'EditBuyer',
                    path: '/admin/edit-buyer',
                    props: true,
                    component: () => import('@/components/EditBuyer'),
                },

            {
                    name: 'ProducerPage',
                    path: '/admin/producer',
                    props: true,
                    component: () => import('@/views/pages/ProducerPage'),
                },

            {
                    name: 'ProviderPage',
                    path: '/admin/provider',
                    props: true,
                    component: () => import('@/views/pages/ProviderPage'),
                },

            {
                    name: 'OrderPage',
                    path: '/admin/order-tracking',
                    props: true,
                    component: () => import('@/views/pages/OrderPage'),
                },

            {
                    name: 'NewOrderPage',
                    path: '/admin/add-order',
                    component: () => import('@/views/pages/NewOrderPage'),
                },

            ]

        },
        {
            path : "/admin",
            meta: {
                    requiresAuth: true,
                    requiresAdmin: true
                      },
            component: () => import('@/layouts/OrderBuyLayout'),
            children: [
                {
                    name: 'AllNodes',
                    path: '/admin/all-nodes',
                    component: () => import('@/views/pages/TransportationInfo'),
                },

                {
                        name: 'NodeRoutesPage',
                        path: '/admin/node-routes',
                        props: true,
                        component: () => import('@/views/pages/NodeRoutesPage'),
                    },

                {
                        name: 'AllTrucks',
                        path: '/admin/all-trucks',
                        component: () => import('@/views/pages/TransportationTrucks'),
                    },

            ]

        },
        {
            path : "/admin",
            meta: {
                        requiresAuth: true,
                        requiresAdmin: true
                      },
            component: () => import('@/layouts/ManagerLayout'),
            children: [
                 {
                    name: 'AddMandi',
                    path: '/admin/add-mandi',
                    component: () => import('@/views/pages/AddMandi'),
                },
                 {
                    name: 'AddItem',
                    path: '/admin/add-item',
                    component: () => import('@/views/pages/AddItem'),
                },
                {
                   name: 'AddRate',
                    path: '/admin/add-rate',
                    component: () => import('@/views/pages/AddRate'),
                },
                {
                   name: 'AddPredict',
                    path: '/admin/add-prediction',
                    component: () => import('@/views/pages/AddPredict'),
                },
                {
                   name: 'AllMandis',
                    path: '/admin/all-mandis',
                    component: () => import('@/views/pages/AllMandis'),
                },
                {
                   name: 'AllUsers',
                    path: '/admin/mandi-operators',
                    component: () => import('@/views/pages/AllMandiOperators'),
                },
                {
                   name: 'AllUsersData',
                    path: '/admin/data-operators',
                    component: () => import('@/views/pages/AllDataOperators'),
                },
                {
                   name: 'AllItems',
                    path: '/admin/all-items',
                    component: () => import('@/views/pages/AllItems'),
                },
                {
                   name: 'AllItemBids',
                    path: '/admin/all-item-bids',
                    props: true,
                    component: () => import('@/views/pages/ItemBidsPage'),
                },
                {
                   name: 'AllLivestalk',
                    path: '/admin/all-livestalk',
                    component: () => import('@/views/pages/AllLivestalk'),
                },
                {
                   name: 'AllLivestalkRates',
                    path: '/admin/all-livestalk-rates',
                    component: () => import('@/views/pages/AllLivestalkRates'),
                    props: true
                },
                {
                   name: 'MandisTracking',
                    path: '/admin/mandis-tracking',
                    component: () => import('@/views/pages/TrackAllMandis'),
                },
                {
                   name: 'AllItemRates',
                    path: '/admin/item-rates',
                    props: true,
                    component: () => import('@/components/AllItemRates'),
                },
                {
                   name: 'AllItemPredictions',
                    path: '/admin/item-predictions',
                    props: true,
                    component: () => import('@/components/AllItemPredictions'),
                },
                {
                   name: 'AllItemPredictionsRates',
                    path: '/admin/add-rate-predicition',
                    props: true,
                    component: () => import('@/views/pages/AddRatePredictMandi'),
                },
                {
                   name: 'ChangePassword',
                    path: '/admin/change-password',
                    props: true,
                    component: () => import('@/views/pages/ChangePassword'),
                },
                {
                   name: 'AddUser',
                    path: '/admin/add-user',
                    props: true,
                    component: () => import('@/views/pages/SignUp'),
                },
                {
                   name: 'EditUser',
                    path: '/admin/edit-user',
                    props: true,
                    component: () => import('@/components/EditUser'),
                },
                {
                   name: 'EditMandi',
                    path: '/admin/edit-mandi',
                    props: true,
                    component: () => import('@/components/EditMandi'),
                },
                {
                   name: 'EditItem',
                    path: '/admin/edit-item',
                    props: true,
                    component: () => import('@/components/EditItem'),
                },
            ]
        },

    ],
    // scrollBehavior() {
    //     document.getElementById('app').scrollIntoView();
    // }
    scrollBehavior(to) {
        console.log(to);
    if (to.hash) {
      return {
        selector: to.hash
      };
    }
    else{
         return { x: 0, y: 0 };
    }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin))
  {
    if(store.getters.isAdmin)
    {
        next()
        return
    }
    else{
        router.push('/unauthorized')
    }
  }
  else if (to.matched.some(record => record.meta.requiresSemiAdmin))
  {
    if(store.getters.isSemiAdmin || store.getters.isAdmin)
    {
        next()
        return
    }
    else{
        router.push('/unauthorized')
    }
  }
  else {
    next()
    return
  }
})

export default router
