<template>
<div id="sidebar" :class="[{'collapsed' : collapsed}]">
  <div class="demo">
<sidebar-menu style="margin-top: 60px" :collapsed="collapsed" :menu="menu" :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick" :theme=selectedTheme>
    <span slot="dropdown-icon" class="icon-arrow_drop_down"></span>
    <span slot="toggle-icon" class="icon-toggle-on" v-if="collapsed"></span>
    <span slot="toggle-icon" class="icon-toggle-off" v-else></span>
        </sidebar-menu>
  </div>
</div>
</template>


<script>

export default {
  name: "UserSidebar",
  components: {
  },
 methods: {
    onToggleCollapse(collapsed){
      console.log(collapsed);
      this.collapsed = ! this.collapsed;
      this.$emit('bar-collapsed', this.collapsed);
      console.log(this.collapsed);
    },
    onItemClick(event, item) {
      console.log(event);
      if(item.link){this.goTo(item.link);}
      this.$emit('item-click', item.title);
      console.log(item.title);
    },
    goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
  },
  data() {
        return {
    themes: ['', 'default-theme'],
    selectedTheme: '',
    collapsed: false,
    menu: [
    {
    href: '/admin/mandis-tracking',
    title: 'Live Daily Tracking',
    icon: 'icon-line-chart',
},

  {
    href: '/admin/mandi-operators',
    title: 'Mandi Operators Info',
    icon: 'icon-person',
},
{
    href: '/admin/data-operators',
    title: 'Data Operators Info',
    icon: 'icon-bar-chart',
},
  {
    href: '/admin/all-mandis',
    title: 'Mandis',
    icon: 'icon-shopping-cart',
  },
  {
    href: '/admin/all-livestalk',
    title: 'Livestock',
    icon: 'icon-paw',
    child:[
      {
        link: '/admin/all-livestalk-rates',
        title: 'All Livestock Rates',
        icon: 'icon-attach_money'
      },
    ]
  },
  {
    href: '/admin/all-items',
    title: 'Items',
    icon: 'flaticon-banana',
},

  {
    title: 'Add',
    icon: 'icon-plus',
    child: [
    {
        link: '/admin/add-user',
        title: 'Add User',
        icon: 'icon-person'
      },
      {
        link: '/admin/add-mandi',
        title: 'Add Mandi',
        icon: 'icon-shopping-cart'
      },
      {
        link: '/admin/add-item',
        title: 'Add Item',
        icon: 'flaticon-banana'
      },
      {
        link: '/admin/add-rate-predicition',
        title: 'Add Rate and Prediction',
        icon: 'icon-shopping-cart'
      },
      {
        link: '/admin/add-rate',
        title: 'Add Rate',
        icon: 'icon-money'
      },
      {
        link: '/admin/add-prediction',
        title: 'Add Prediction',
        icon: 'icon-line-chart'
      }
    ]
},
{
    link: '/admin/change-password',
    title: 'Settings',
    icon: 'icon-settings',
},
    ]
  }},
  mounted() {
      let recaptchaScript = document.createElement('../../assets/js/sidebar.js')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      document.head.appendChild(recaptchaScript)
    },
};
</script>

