<template>
<div>
  <div id="main" class="section mb-5 raw first-section justify-content-center">
    <div class="col-md-12 justify-content-center">
    <div v-if="isSemiAdmin" class="row justify-content-center">
      <div class="col-md-3 mb-5">
          <button class="btn btn-primary btn-block" v-on:click="goTo('/admin/all-orders')">Buyer/Seller Management</button>
    </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-md-3 mb-5">
          <button class="btn btn-primary btn-block" v-on:click="goTo('/mandi/all-livestalk-rates')">Livestock Rates</button>
    </div>
    </div>
    <div class="row justify-content-center">
      <h3>Select A Mandi:</h3>
    </div>
    <div class="row justify-content-center">
      <p style="font-size: 18px"><span class="btn-inv icon-circle" style="color: #f0c419"></span>: Not updated, <span class="btn-inv icon-circle" style="color: orange"></span>: >20 rates updated, <span class="btn-inv icon-circle" style="color: green"></span>: >40 rates updated</p>
    </div>
    <div class="row justify-content-center">
      <p v-if="Loading">Loading ...</p>
    </div>
    <div class="row justify-content-center mb-5">
    <div v-for="(mandi, index) in Mandis" :key="index" class="col-md-3 mb-5">
          <button  v-if="mandi.RatesCount < 20" class="btn btn-primary btn-block" v-on:click="goTomandi(mandi)">{{mandi.MandiName}}, {{mandi.City}}</button>
          <button  v-if="mandi.RatesCount >= 20 && mandi.RatesCount <= 40" class="btn btn-primary btn-block" v-on:click="goTomandi(mandi)" style="background-color: orange">{{mandi.MandiName}}, {{mandi.City}}</button>
          <button  v-if="mandi.RatesCount > 40" class="btn btn-primary btn-block" v-on:click="goTomandi(mandi)" style="background-color: green">{{mandi.MandiName}}, {{mandi.City}}</button>
    </div>
  </div>
  </div>
  </div>
</div>
</template>

<script>
    export default {
        name: 'MandiSelect',
        components: {
          },
        props:{
        },
    data(){
        return{
          Mandis: Object,
          Loading: true,
          isSemiAdmin: false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    goTomandi: function(mandi){
      this.$router.push({
          name: 'MandiPageAllItems',
          params: {
              mandiName: mandi.MandiName,
              mandiId: mandi.MandiId,
              retailOnly: mandi.RetailOnly,
          }
      })
    },

},
   mounted(){
    if(localStorage.getItem('isSemiAdmin')){
      this.isSemiAdmin = true
    }
    let token = localStorage.getItem('token');
      const newconfig = {headers:{
      'Accept': 'json',
      'Content-Type': 'json',
      'x-authorization-token': `${token}`,
    }, responseType: 'json'};
    this.$store.dispatch('getUserInfo', newconfig)
    .then(resp => {this.Mandis = resp.data.data.mandis;
      this.Loading = false})
  }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
