<template>
  <form v-on:submit.prevent="ChangeMandi" class="container" style="margin-left: -7px">
      <div class="form-group row">
        <div class="col-md-12">
        <p>Select a mandi:</p>
          <v-select v-if="gotMandis" class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          >
          </v-select>
          <p v-else>Loading...</p>
      </div>
      </div>

       <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
          <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Confirm" style="background-color: red; border-color: black">
        </div>
      </div>

    </form>
</template>

<script>
    export default {
        name: 'changeMandi',
        components: {
    },
    props:{
      ClientInfo: Object
    },
    data(){
        return{
            form:{},
            Mandis: Object,
            gotMandis: false,
        }
    },
    methods:{
        ChangeFormEmit(){
            this.$emit('form-updated', this.form)
        },
        OnChangeSelectMandi(e){
          this.form.NearestMandiId = e.value
          // this.form.MandiName = e.label
          this.ChangeFormEmit()
        },
        ChangeMandi(){
          this.$confirm("Change mandi from "+ this.ClientInfo.NearestMandi+" to "+this.form.MandiName+"?").then(() =>{
            let mandiForm = this.form;
          //   {
          //   'NearestMandiId': this.form.UserMandiId
          // };
          let ChangeForm ={
            'ClientNumber': this.ClientInfo.ClientNumber,
            'form': mandiForm
          };
          this.$store.dispatch('editClientById', ChangeForm).then(() =>  {
            this.$alert("Client Nearest Mandi Changed!");
          this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientNumber: this.ClientInfo.ClientNumber,
          }
          })}).catch(() =>  this.$alert("Error! Mandi did not change"))
          }).catch(() =>{
             this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientInfo: this.ClientInfo,
          }
          })
        })
        }
    },
    created () {
        this.form = this.ClientInfo;
        this.$store.dispatch('getAllMandis').then(resp =>
                                                  {this.Mandis = resp.data.data.mandis.filter(mandi => {return mandi.RetailOnly!=2});
                                                    this.gotMandis = true;});

  },
  computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: String(g.MandiName)+', '+String(g.City), value: g.MandiId}))
    },
  }
}
</script>

