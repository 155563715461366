<template>
  <tr>
    <td></td>
    <td><input type="text" placeholder="Name" v-model="node.TruckName"></td>
    <td><input type="number" step="any" placeholder="Max Load" v-model="node.TruckMaxLoad" v-on:change="value.replace(/\d+|^\s+|\s+$/g, '').trim(); ChangeFormEmit" @keydown.space.prevent dense ></td>
    <td><input type="text" placeholder="Notes" v-model="node.Notes"></td>
    <td v-if="!update"><button class="btn-inv icon-plus" v-on:click="AddNode()"></button></td>
    <td v-if="update"><button class="btn-inv icon-plus" v-on:click="editNode()"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'MandiLine',
        components: {
    },
    props:{
      oldNode: Object,
      index: Number
    },
    data(){
        return{
            node: {
              NodeCity: '',
              NodeLocale: '',
              NodeX: Number,
              NodeY: Number,
            },
            update: false
        }
    },
    methods:{
      AddNode(){
        this.$store.dispatch('addTruck', this.node)
        .then(() => {
          this.$emit('update');
          }).catch(() => {
            this.rateMsg = "Error adding node!";
          })
      },
      editNode(){
        let nodeForm ={
          'TruckForm': this.node,
          'TruckId': this.index
        };
        this.$store.dispatch('editTruck', nodeForm)
        .then(() => {
          this.$emit('edited');
          }).catch(() => {
            this.rateMsg = "Error updating truck!";
          })
      }
      },
    mounted(){
      if(this.oldNode){
        this.node = this.oldNode;
        this.update = true
      }
    }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
