<template>
  <div id="login">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h2 class="mb-3">{{item.ItemName}} - {{item.ItemUrduName}} Bids</h2>
      </div>
    </div>
    <div class="row">
          <div class="col-md-6">
            <p>Get Item Bids At Mandi: </p>
            <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                            :value.sync="value"
                            :options="SelectMandi"
                            :v-model="MandiId"
                            >
            </v-select>
            <br>
          </div>
        </div>
        <div v-if="mandiselected" class="row justify-content-center ">
          <AllItemSources :Item="item" :ItemName="item.ItemName" :MandiId="MandiId" :MandiName="mandiName"/>
    </div>
</div>
</div>
</template>

<script>
  import AllItemSources from './AllItemSources'
  export default {
        name: 'itemBidsPage',
        components: {
          AllItemSources
          },
        props:{
          item: Object
        },
        data(){
            return{
              Mandis: [],
              MandiId: Number,
              mandiName: '',
              mandiselected: false
            }
          },
        methods:{
          OnChangeSelectMandi(e){
            this.mandiselected = false;
            this.MandiId = e.value;
            this.mandiName = e.name;
            this.mandiselected = true;
          },
        },
         mounted(){
          this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis.filter(mandi => {return mandi.RetailOnly!=1}));
        },
        computed:{
          SelectMandi(){
            return this.Mandis.map(function(g) {
            if(g.MandiUser){
              return {label: g.MandiName + ', '+ g.MandiUser.FirstName +' ' + g.MandiUser.LastName, value: g.MandiId, name:g.MandiName}
            }
            else{
              return {label: g.MandiName + ', Not Assigned', value: g.MandiId, name:g.MandiName}
            }

            })
          },
          myData: function() {
            return this.chartData;
          }
        }
      }
</script>
