<template>
  <tr v-if="!loading">
    <td><span v-if="order.OrderStatus=='Initiated'" class="btn-inv icon-circle" style="color: red; font-size: 20px"></span>
      <span v-if="order.OrderStatus=='InProcess'" class="btn-inv icon-circle" style="color: orange; font-size: 20px"></span>
      <span v-if="order.OrderStatus=='Fulfilled'" class="btn-inv icon-circle" style="color: blue; font-size: 20px"></span>
      <span v-if="order.OrderStatus=='Delivered'" class="btn-inv icon-circle" style="color: green; font-size: 20px"></span>
      {{index}}
    </td>
    <td v-if="!changeStatus" v-on:click="changeStatus=true">{{order.OrderStatus}}</td>
    <td v-if="changeStatus"><v-select class="btn-vselect" v-on:input="OnChangeStatus"
                          :value.sync="value"
                          :options="status"
                          >
          </v-select></td>
    <td>{{order.DateCreated}}</td>
    <td>{{order.BuyerName}}</td>
    <td>{{order.ItemName}}</td>
    <td>{{order.Quantity}} {{order.UnitName}}</td>
    <td>{{order.UserName}}</td>
    <td><button class="btn-inv icon-settings" v-on:click="trackOrder(order)" title="track/edit"></button></td>
    <td><button class="btn-inv icon-delete" title="delete" v-on:click="deleteOrder(order)"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'OrderLine',
        components: {
    },
    props:{
      order: Object,
      index: Number,
    },
    data(){
        return{
            status:['Initiated', 'InProcess', 'Fulfilled', 'Delivered'],
            changeStatus: false,
            loading: false
        }
    },
    methods:{
      async goToMandiRate(mandiName, mandiId){
      await this.getTodayRates(mandiId)
      this.$router.push({
          name: 'AllItemRates',
          params: {
              Items: this.todayItems,
              itemName: "",
              Type: "MandiTrack",
              mandiName: mandiName,
              mandiId: mandiId,
              retailOnly: this.mandi.RetailOnly,
              mandi: Object
          }
      })
    },
    OnChangeStatus(e){
      let orderForm = {
        'OrderId' : this.order.OrderId,
        'OrderStatus': e
      }
      this.$store.dispatch('changeOrderStatus', orderForm).then(() => this.refreshOrder())
    },
    refreshOrder(){
      this.loading = true;
      this.$emit('update');
    },
    trackOrder(order){
        this.$router.push({
          name: 'OrderPage',
          params: {
              order: order,
          }
      })
    },
    deleteOrder(order){
      this.$confirm("Permanently delete order #"+ String(order.OrderId) + "?").then(() => {
        this.$store.dispatch('deleteOrder', order.OrderId)
        .then(() => {
              this.$alert("Order #" + String(order.OrderId) + " successfully deleted!");
              this.refreshOrder();
            }).catch(() => this.$alert("Error deleting Order #" + String(order.OrderId)))
      })
    }
    }
  }
</script>
