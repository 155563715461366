<template>
  <tr>
    <td>{{index}}</td>
    <td >
      <v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select></td>
    <td>{{NewItem.ItemUrduName}}</td>
    <td>{{NewItem.ItemCategory}}</td>
    <td>{{NewItem.Unit}}</td>
    <td v-if="type=='Preferred'"><button class="btn-inv" v-on:click="goToBestRate(item)">Best Rates</button></td>
    <td v-if="type!='Preferred'"><button class="btn-inv" v-on:click="goToRate(item)">Rates</button></td>
    <td v-if="type!='Preferred'"><button class="btn-inv" v-on:click="goToPredict(item)">Predictions</button></td>
    <td><button class="btn-inv icon-plus" v-on:click="AddItem(NewItem.ItemId)"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'AddItemLine',
        components: {
          // ToggleSubscription
    },
    props:{
      index: Number,
      type: String,
      ClientNumber: String,
    },
    data(){
        return{
            AllItems: [],
            NewItem: {
              ItemUrduName: '',
              ItemCategory: '',
              Unit: ''
            }
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    goToRate: function(item){
      console.log(item)
      this.$router.push({
          name: 'AllItemRates',
          params: {
              itemName: item.ItemName,
              Items: item,
              Type: "Item",
              mandiName: ""
          }
      })
    },
    goToBestRate: function(item){
      console.log(item)
      this.$router.push({
          name: 'AllItemRates',
         params: {
              bestRates: '1',
              itemName: item.ItemName,
              Items: item,
              Type: "Item",
              mandiName: '',
              farmerGroup: true,
              user: 'Operator'
          }
      })
    },
    goToPredict: function(item){
      this.$router.push({
          name: 'AllItemPredictions',
          params: {
              itemName: item.ItemName,
              Items: item,
              Type: "Item",
              mandiName: ""
          }
      })
    },
    AddItem: function(itemId){
      let itemForm ={
        'ClientNumber': this.ClientNumber,
        'ItemId' : itemId
      };
        this.$store.dispatch('addClientItem', itemForm).then(() => {
            this.$emit('item-added');
            this.$alert("Item added client preferred items");
          }).catch(() =>
          this.$alert("Error! Item did not get added"))
      },
      OnChangeSelectItem(e){
          this.NewItem = e.value
        }
  },
  created(){
    this.$store.dispatch('getAllItemsNames').then(resp => {this.AllItems = resp.data.data.items;
    })
  },
  computed:{
    SelectItem(){
      return this.AllItems.map(g => ({label: g.ItemName, value: g}))
    },
},}
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
