<template>
  <tr>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectBuyer"
                          :value.sync="value"
                          :options="SelectBuyer"
                          style="width: 200px;"
                          >
          </v-select></td>
    <th><input type="Number" placeholder="Price" v-model="form.BiddingPrice" style="width: 100px;">Rs.</th>
    <td><button class="btn-inv icon-plus" v-on:click="AddMore()" title="add more"></button>/<button class="btn-inv icon-send" v-on:click="SendBids()" title="Submit All"></button></td>
  </tr>
</template>

<script>
    export default {
    name: 'NewBidLine',
    props:{
      ItemSourceId: Number,
      buyers: Array
    },
    data(){
        return{
            state: '',
            date: '',
            Units: Object,
            addNewBid: false,
            form: {},
            addedmore: false
        }
    },
    methods:{
       OnChangeSelectBuyer(e){
          this.form.BidderId = e.value
        },
        AddMore(){
          this.form.BiddingPrice = Number(this.form.BiddingPrice);
          this.$emit('add-more', this.form);
          this.addedmore = true
        },
        SendBids(){
          if(this.addedmore){
            this.$emit('send-all');
          }
          else{
            this.form.BiddingPrice = Number(this.form.BiddingPrice);
            this.$emit('send-now', this.form)
          }
        }
    },
    mounted(){
      this.form.ItemSourceId = this.ItemSourceId
    },
    computed:{
       SelectBuyer(){
      return this.buyers.map(g => ({label: g.BuyerName + ', ' + g.BuyerCity, value: g.BuyerId}))
    }
    }
  }
</script>

