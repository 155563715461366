<template>
<div id="sidebar" :class="[{'collapsed' : collapsed}]">
  <div class="demo">
<sidebar-menu style="margin-top: 60px" class="v-sidebar-menu" :collapsed="collapsed" :menu="menu" :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick" :theme=selectedTheme>
    <span slot="dropdown-icon" class="icon-arrow_drop_down"></span>
    <span slot="toggle-icon" class="icon-toggle-on" v-if="collapsed"></span>
    <span slot="toggle-icon" class="icon-toggle-off" v-else></span>
        </sidebar-menu>
  </div>
</div>
</template>


<script>

export default {
  name: "UserSidebar",
  components: {
  },
 methods: {
    onToggleCollapse(collapsed){
      console.log(collapsed);
      this.collapsed = ! this.collapsed;
      this.$emit('bar-collapsed', this.collapsed);
      console.log(this.collapsed);
    },
    onItemClick(event, item) {
      console.log(event);
      if(item.link){this.goTo(item.link);}
      this.$emit('item-click', item.title);
      console.log(item.title);
    },
    goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
  },
  data() {
        return {
    themes: ['', 'default-theme'],
    selectedTheme: '',
    collapsed: false,
    menu: [
   {
    href: '/order/main',
    title: 'Main Menu',
    icon: 'icon-home',
},
  {
    href: '/order/new-order',
    title: 'New Order',
    icon: 'icon-shopping-cart',
},

    {
    href: '/order/my-orders',
    title: 'My Orders',
    icon: 'icon-shopping-basket',
},

 {
    href: '/order/orders-tracking',
    title: 'Orders Tracking',
    icon: 'icon-truck',
},

 {
    href: '/order/edit-info',
    title: 'Edit Profile',
    icon: 'icon-person',
},

 {
    title: 'Log Out',
    icon: 'icon-exit_to_app',
}
    ]
  }},
  mounted() {
      let recaptchaScript = document.createElement('../../assets/js/sidebar.js')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      document.head.appendChild(recaptchaScript)
    },
};
</script>

