<template>
  <div id="login">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h2 class="mb-3">{{item.ItemName}} - {{item.ItemUrduName}}</h2>
          </div>
    </div>
    <div class="row">
          <div class="col-md-6">
            <!-- <p v-if="!loading">Today's highest max rate in mandis: {{maxRate}}Rs.</p> -->
            <p>Select a mandi to get item rate history:</p>
            <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                            :value.sync="value"
                            :options="SelectMandi"
                            :v-model="MandiId"
                            >
            </v-select>
            <br>
            <p v-if="!loading">Item rate history at {{MandiName}}:</p>
          </div>
        </div>
        <div class="row justify-content-center ">
          <div class="col-md-12">
            <zingchart v-if="!loading" :data="myData"></zingchart>
            <p v-else>Loading ...</p>
        </div>
    </div>
</div>
</div>
</template>

<script>
import zingchart from 'zingchart/es6';
import zingchartVue from 'zingchart-vue';
zingchart.DEV.KEEPSOURCE = 0; // prevents lib from storing the original data package
zingchart.DEV.COPYDATA = 0; //
zingchart.LICENSE = ['abcdefghijklmnopqrstuvwxy'];
    export default {
        name: 'itemPage',
        components: {
          zingchart: zingchartVue,
          },
        props:{
          item: Object
        },
    data(){
        return{
          itemDetails: Object,
          maxHist: [],
          Mandis: [],
          MandiId: Number,
          MandiName: '',
          retailOnly: Number,
          loading: true,
          Xlabels: [0],
          maxRate: 0,
          chartData: {
                globals: {
                  fontSize: '16px'
                },
                theme: 'dark',
                type: 'line',
                title:{
                  text: 'Price History',
                  fontSize: '22px'
                },
                legend: {
                  cursor: 'hand',
                  draggable: true
                },
               guide: {
                  visible: true,
                  lineColor: '#D7D8D9',
                  lineStyle: 'dotted',
                  lineGapSize: '4px',
                  rules: [{
                    rule: "%i == 0",
                    visible: false
                  }]
                },
                scaleX:{
                  label:{
                    text: 'Date'
                  },
                  labels: [0],
                },
                scaleY:{
                  zooming: true,
                  label:{
                    // text: 'Price'
                  }
                },
                series: [{
                    values: [0],
                    text: 'Max Rate',
                    lineColor: "#f0c419",
                    marker: {
                      'background-color': "#f0c419",
                    }
                },
                {
                  values: [0],
                  text: 'Min Rate',
                  lineColor: "#43C6D8",
                    marker: {
                      'background-color': "#43C6D8",
                    }
                },
                {
                  value: [0],
                  text: 'Retail Rate',
                  lineColor: "#C11B17",
                    marker: {
                      'background-color': "#C11B17",
                    }
                },
                {
                    values: [0],
                    lineStyle: "dashed",
                    text: 'Moving Average (Max)',
                    visible: false,
                    lineColor: "#f0c419",
                    marker: {
                      'background-color': "#f0c419",
                      visible: false
                    }
                },
                {
                  values: [0],
                  lineStyle: "dashed",
                  text: 'Moving Average (Min)',
                  visible: false,
                  lineColor: "#43C6D8",
                    marker: {
                      'background-color': "#43C6D8",
                      visible: false
                    }
                },
                {
                  values: [0],
                  lineStyle: "dashed",
                  text: 'Moving Average (Retail)',
                  visible: false,
                  lineColor: "#C11B17",
                    marker: {
                      'background-color': "#C11B17",
                      visible: false
                    }
                },
                ]
            }
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    OnChangeSelectMandi(e){
          this.MandiId = e.value;
          this.MandiName = e.label;
          this.retailOnly = e.retailOnly;
          this.GetMandiHistory();
        },
    GetMandiHistory(){
      this.loading = true;
      let itemForm ={
        'ItemId': this.item.ItemId,
        'MandiId': this.MandiId
      }
      this.$store.dispatch('getItemBestRatesInFarmerGroup', itemForm).then(resp => {
        this.itemDetails = resp.data.data.item;
        this.UpdateGraph(this.retailOnly, 0)
        this.loading = false;
      });
    },
    UpdateMax(all){
      this.chartData.series[0].values = this.itemDetails.max_rate_history.map(function(x) {
          if(x[1]<=0){return null}
          return Math.round(x[1] * 100) / 100;});
      if(all != 1){
      this.chartData.series[3].values = this.itemDetails.max_rate_moving_avg.map(function(x) {
          if(x[1]<=0){return null}
          return Math.round(x[1] * 100) / 100;});
    }
    },
    UpdateMin(all){
      this.chartData.series[1].values = this.itemDetails.min_rate_history.map(function(x) {
          if(x[1]<=0){return null}
          return Math.round(x[1] * 100) / 100;});
      if(all != 1){
        this.chartData.series[4].values = this.itemDetails.min_rate_moving_avg.map(function(x) {
          if(x[1]<=0){return null}
          return Math.round(x[1] * 100) / 100;});
      }
    },
    UpdateRetail(all){
      this.chartData.series[2].values = this.itemDetails.retail_rate_history.map(function(x) {
          if(x[1]<=0){return null}
          return Math.round(x[1] * 100) / 100;});
      if(all != 1){
      this.chartData.series[5].values = this.itemDetails.retail_rate_moving_avg.map(function(x) {
          if(x[1]<=0){return null}
          return Math.round(x[1] * 100) / 100;});
    }
    },
    UpdateGraph(retailOnly, all){
      console.log(retailOnly);
      if(retailOnly != 1){
      if(this.chartData['scale-x']){
      this.chartData['scale-x'].labels = this.itemDetails.max_rate_history.map(function(x) {return x[0];}).sort(function(a, b){
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
            });}
      else{
        this.chartData['scaleX'].labels = this.itemDetails.max_rate_history.map(function(x) {return x[0];}).sort(function(a, b){
                if(a < b) { return -1; }
                if(a > b) { return 1; }
                return 0;
            })
      }
    }
      if (retailOnly == 0)
        {
          this.UpdateMax(all);
          this.UpdateMin(all);
          this.UpdateRetail(all);
      }
      if(retailOnly == 2){
        this.UpdateMax(all);
        this.UpdateMin(all);
        this.chartData.series[2].values = [];
        this.chartData.series[5].values = [];
      }
      if(retailOnly == 1){
        this.chartData.series[0].values = [];
        this.chartData.series[1].values = [];
        this.chartData.series[3].values = [];
        this.chartData.series[4].values = [];
        this.UpdateRetail(all);
        if(this.chartData['scale-x']){
      this.chartData['scale-x'].labels = this.itemDetails.retail_rate_history.map(function(x) {return x[0];})}
      else{
        this.chartData['scaleX'].labels = this.itemDetails.retail_rate_history.map(function(x) {return x[0];})
      }
      }
    }
  },
  mounted(){
    this.$store.dispatch('getAllMandis').then(resp => {this.Mandis = resp.data.data.mandis;
                                              this.loading = false;});
    // this.$store.dispatch('getItemRatesHistory', this.item.ItemId).then(resp =>
    //       {this.itemDetails = resp.data.data.item;
    //         // this.maxHist = resp.data.data.item.max_rate_history;
    //         this.UpdateGraph(0, 1);
    //         this.maxRate = this.itemDetails.highest_MaxRate;
    //       })
  },
  computed:{
    SelectMandi(){
      return this.Mandis.map(function(g) {
      if(g.MandiUser){
        return {label: g.MandiName + ', '+ g.MandiUser.FirstName +' ' + g.MandiUser.LastName, value: g.MandiId, retailOnly: g.RetailOnly}
      }
      else{
        return {label: g.MandiName + ', Not Assigned', value: g.MandiId, retailOnly: g.RetailOnly}
      }

      })
    },
    myData: function() {
      return this.chartData;
    }
  }


  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
