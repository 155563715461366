<template>
  <div id="login">
    <div class="section">
  <div class="section last-section">
    <div class="row justify-content-center ">
        <div class="col-md-12">
          <h1 class="mb-3">Provider: <b>{{Provider.ProviderName}}</b> <button class="icon-settings" v-on:click="ProviderEdit()" title="edit"></button></h1>
          <ProviderForm FormType='Input' :Inputform="form" v-on:form-updated="form = $event"/>
        </div>
    </div>

         <div>
      </div>
    </div>
</div>
      </div>
</template>

<script>
import ProviderForm from '../../components/ProviderForm'
    export default {
        name: 'login',
        props:{
          Provider: Object
        },
        components: {
            ProviderForm,
          },
    data(){
        return{
          form: Object,
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    ProviderEdit: function(){
      this.goTo('all-providers');
       this.$router.push({
          name: 'AddProvider',
          params: {
              ProviderIn: this.Provider,
              Type: 'Edit',
              ProviderId: this.Provider.ProviderId
          }
      })
    },
},
    created(){
      this.form = this.Provider;
      this.loading = true;
    }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
