<template>
  <div id="find-client">
    <div class="section last-section container">
    <div id="section-login" class="row justify-content-center">
          <div class="col-md-12">
            <h2 class=" mb-3">Search Item Best Rates In Farmer Mandi Groups:</h2>
            <form v-on:submit.prevent="getItemRates" data-aos="fade">
              <div v-if="Items" class="form-group row">
                <div class="col-md-12">
                <p>Select an item:</p>
                  <v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                                  :value.sync="value"
                                  :options="SelectItem"
                                  >
                  </v-select>
              </div>
              </div>

              <div class="form-group row" v-if="Mandis">
                <div class="col-md-12">
                <p>Select a mandi: </p>
                  <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                                  :value.sync="value"
                                  :options="SelectMandi"
                                  :v-model="form.UserMandiId"
                                  >
                  </v-select>
              </div>
              </div>

              <div class="form-group row">
              <div class="col-md-4"/>
              <div class="col-md-4">
                <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Search ">
              </div>
            </div>

            </form>
            <Alert v-if="findFail == true" v-on:alert-closed="LoginFail = false" :isWarning="findFail" :msg="'Client does not exist in the database'"/>
          </div>
        </div>
      </div>
      </div>
</template>

<script>
import Alert from '../../components/Alert.vue';
    export default {
        name: 'FindItem',
        components: {
          Alert
          },
    data(){
        return{
          form: {},
          findFail: false,
          Mandis: [],
          Items: [],
          Item: {}
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    OnChangeSelectItem(e){
      this.form.ItemId = e.value
    },
    OnChangeSelectMandi(e){
      this.form.MandiId = e.value;
    },
    getItemRates: function(){
      let itemForm = {
        'ItemId': this.form.ItemId,
        'MandiId': this.form.MandiId
      };
      console.log(itemForm);
      this.$store.dispatch('getItemBestRatesInFarmerGroup2', itemForm).then(resp => {
        this.Item = resp.data.data.item;
        this.$router.push({
          name: 'ItemBestRates',
          params: {
              itemName: this.Item.ItemName,
              Items: this.Item.LastRate,
              Type: "Item",
              mandiName: "",
              bestRates: "1",
              farmerGroup: true
          }
      })
      })
    }

},

created () {
        this.Loading = true;
        this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis)
        this.$store.dispatch('getAllItemsNames').then(resp => {this.Items = resp.data.data.items;
          this.Loading = false})
  },
  computed:{
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName, value: g.ItemId}))
    },
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId, retailOnly: g.RetailOnly}))
    },
  }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
