<template>
  <div id="mandi-form">
      <div class="form-group row">
        <div class="col-md-12">
        <p>Producer Name: {{form.ProducerName}}</p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-12">
          <input type="text" class="form-control" placeholder="Name" v-model="form.ProducerName" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Producer Type: {{form.ProducerType}}</p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-12">
          <v-select class="btn-vselect" v-on:input="OnChangeSelectType"
                          :value.sync="value"
                          :options="producerTypeOptions"
                          >
          </v-select>
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Producer Location: <span v-if="FormType!='Edit'"> {{form.ProducerAddress}}, {{form.ProducerCity}}</span></p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="text" class="form-control" placeholder="Address" v-model="form.ProducerAddress" v-on:change="ChangeFormEmit">
        </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="text" class="form-control" placeholder="City" v-model="form.ProducerCity" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Producer Contact: <span v-if="FormType!='Edit'">{{form.ProducerContact}}</span></p>
      </div>
        <div class="col-md-6">
          <input v-if="FormType=='Edit'" type="text" class="form-control" placeholder="Phone/Email" v-model="form.ProducerContact" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p v-if="FormType=='Edit'">Producer Coordinates (X, Y):</p>
        <p v-if="FormType!='Edit'">Producer Coordinates: <a :href="src" class="icon-map-marker" style="color: red" target="_blank"></a>({{form.ProducerX}}, {{form.ProducerY}})</p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="number" step="any" class="form-control" placeholder="X" v-model="form.ProducerX" v-on:change="value.replace(/\d+|^\s+|\s+$/g, '').trim(); ChangeFormEmit" @keydown.space.prevent dense >
        </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="number" step="any" class="form-control" placeholder="Y" v-model="form.ProducerY" v-on:change="value.replace(/\d+|^\s+|\s+$/g, '').trim(); ChangeFormEmit" @keydown.space.prevent dense>
        </div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'ProducerForm',
        components: {
    },
    props:{
      Inputform: Object,
      FormType: String,
    },
    data(){
        return{
            form: Object,
            producerTypeOptions: ['Small', 'Medium', 'Industrial'],
            Loading: true,
            src: ''
        }
    },
    methods:{
      OnChangeSelectType(e){
        this.form.ProducerType = e;
        this.ChangeFormEmit();
      },
        ChangeFormEmit(){
          this.form.ProducerX = Number(this.form.ProducerX);
          this.form.ProducerY = Number(this.form.ProducerY);
          this.$emit('form-updated', this.form);
        },
    },
    created () {
        this.form = this.Inputform;
        this.src = "http://www.google.com/maps/place/" + String(this.form.ProducerX) + "," + String(this.form.ProducerY);
  },
}
</script>

