<template>
  <tr>
    <td>{{index}}</td>
    <td>{{user.FirstName}} {{user.LastName}}</td>
    <td><span v-for="(mandi,index) in user.mandis" :key="index">{{mandi.MandiName}}<br></span></td>
    <td>{{user.Email}}</td>
    <td>{{user.PhoneNumber}}</td>
    <td><button class="btn-inv icon-settings" v-on:click="EditUser(user)"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'userLine',
        components: {
          // ToggleSubscription
    },
    props:{
      user: Object,
      index: Number,
    },
    data(){
        return{
          Loading: true,
          form: {}
        }
    },
    methods:{
      EditUser: function(user){
        this.$router.push({
          name: 'EditUser',
          params: {
              User: user,
          }
      })
      }
      },
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
