<template>
<div id="sidebar" :class="[{'collapsed' : collapsed}]">
  <div class="demo">
<sidebar-menu style="margin-top: 60px" :collapsed="collapsed" :menu="menu" :show-one-child="true"
        @toggle-collapse="onToggleCollapse"
        @item-click="onItemClick" :theme=selectedTheme>
    <span slot="dropdown-icon" class="icon-arrow_drop_down"></span>
    <span slot="toggle-icon" class="icon-toggle-on" v-if="collapsed"></span>
    <span slot="toggle-icon" class="icon-toggle-off" v-else></span>
        </sidebar-menu>
  </div>
</div>
</template>


<script>

export default {
  name: "UserSidebar",
  components: {
  },
 methods: {
    onToggleCollapse(collapsed){
      console.log(collapsed);
      this.collapsed = ! this.collapsed;
      this.$emit('bar-collapsed', this.collapsed);
      console.log(this.collapsed);
    },
    onItemClick(event, item) {
      console.log(event);
      if(item.link){this.goTo(item.link);}
      this.$emit('item-click', item.title);
      console.log(item.title);
    },
    goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
  },
  data() {
        return {
    themes: ['', 'default-theme'],
    selectedTheme: '',
    collapsed: false,
    menu: [
   {
    href: '/admin/all-orders',
    title: 'All Orders',
    icon: 'icon-shopping-cart',
},
  {
    href: '/admin/all-buyers',
    title: 'All Buyers',
    icon: 'icon-people',
},
{
    href: '/admin/all-sellers',
    title: 'All Sellers',
    icon: 'icon-money',
},
{
    href: '/admin/all-exporters',
    title: 'All Exporters',
    icon: 'icon-car',
},
{
    href: '/admin/all-providers',
    title: 'All Storage Providers',
    icon: 'icon-home',
},
  {
    href: '/admin/all-producers',
    title: 'All Producers',
    icon: 'flaticon-banana',
},

{
    title: 'Transportation Info',
    icon: 'icon-truck',
    child:[
    {
    href: '/admin/all-nodes',
    title: 'Transportation Nodes',
    icon: 'icon-add_location',
    },

    {
        href: '/admin/all-trucks',
        title: 'Transportation Trucks',
        icon: 'icon-truck',
    },

    {
        href: '/admin/all-routes',
        title: 'Transportation Routes',
        icon: 'icon-road',
    },
    ]
},


{
    title: 'Add',
    icon: 'icon-plus',
    child:[

    {
        href: '/admin/add-order',
        title: 'Add New Order',
        icon: 'icon-shopping-cart',
    },

    ]
},
    ]
  }},
  mounted() {
      let recaptchaScript = document.createElement('../../assets/js/sidebar.js')
      recaptchaScript.setAttribute('src', 'https://www.google.com/recaptcha/api.js')
      document.head.appendChild(recaptchaScript)
    },
};
</script>

