<template>
  <div id="login-form">
    <form v-on:submit.prevent="login" data-aos="fade">
      <div class="form-group row">
        <div class="col-md-12">
          <Alert v-if="LoginFail == true" v-on:alert-closed="LoginFail = false" :isWarning="LoginFail" :msg="'Wrong email or password, please try again.'"/>

          <input type="text" class="form-control" placeholder="Email" v-model="username">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
          <input v-if="isHidden" type="password" class="form-control" placeholder="Password"  v-model="password">
         <input v-else type="text" class="form-control" placeholder="Password" v-model="password">
          <a v-on:click="isHidden = !isHidden" class="fa fa-fw fa-eye field-icon toggle-password icon-eye"></a>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-4"/>
        <div class="col-md-4">
          <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Log In">
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Alert from './Alert.vue';
    export default {
        name: 'login',
    components: {
    Alert,
    },
    data(){
        return{
            username: '',
            password: '',
            msg: '',
            isAdmin: Boolean,
            isHidden: true,
            LoginFail: false,
            UserType: Number,
            user: Object,
            BuyerAccessList: ['okara@mandi.com', 'jackobabad@mandi.com ', 'hyderabad@mandi.com', 'hazara@mandi.com', 'multan@mandi.com', 'faisalabad@mandi.com', 'rawalpindi@mandi.com', 'bahawalnagar@mandi.com', 'karachi@mandi.com', 'sukkar@mandi.com', 'gb@mandi.com', 'quetta@mandi.com', 'peshawar@mandi.com', 'lahore@mandi.com', 'dik@mandi.com', 'jackobabad@mandi.com', 'test2@test.com', 'gilgit@mandi.com']
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
      async login(){
        const config = {
        auth: {
          username: this.username,
          password: this.password,
        },
      };
      let token = '';
      await this.$store.dispatch('login', config).then(resp => {token = resp.headers["x-authorization-token"];  localStorage.setItem('token', token);}).catch(() => { console.log('error login');
            this.LoginFail = true});
      console.log(token.length);
      if (token.length > 1)
      {
        const newconfig = {headers:{
        'Accept': 'json',
        'Content-Type': 'json',
        'x-authorization-token': `${token}`,
      }, responseType: 'json'};
      await this.$store.dispatch('getUserInfo', newconfig)
      .then(resp => {this.UserType = resp.data.data.UserTypeId;
        this.user = resp.data.data;})
      console.log(this.UserType);
      if (this.UserType == 1 && localStorage.getItem('token'))
      {
        localStorage.setItem('userType', 1);
        localStorage.setItem('isAdmin', 1);
        if(localStorage.getItem('token') != '' && localStorage.getItem('token')!= null && localStorage.getItem('userType') != 0)
        {
          this.$router.push('/select').then(() => this.$router.go())
        }
      }
      else if (this.UserType == 2)
      {
        localStorage.setItem('isOperator', 1);
        if(localStorage.getItem('token') != '' && localStorage.getItem('token')!= null && localStorage.getItem('userType') != 0)
        {
          localStorage.setItem('userType', 2);
          this.$router.push('/operator').then(() => this.$router.go())
        }
      }
      else if (this.UserType == 3)
      {
        localStorage.setItem('isMandiOperator', 1);
        if(localStorage.getItem('token') != '' && localStorage.getItem('token')!= null && localStorage.getItem('userType') != 0)
        {
          localStorage.setItem('userType', 3);
          console.log(this.username);
          if(this.BuyerAccessList.includes(this.username))
          {
            localStorage.setItem('isSemiAdmin', 1);
          }
            this.$router.push({
            name: 'MandiSelect',
            }).then(() => this.$router.go())

        }
      }
      }

        },
      }
    }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
