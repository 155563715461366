<template>
  <tr>
    <td><span v-if="item.Flag" class="icon-flag" style="color: red"></span>{{index}}</td>
    <td v-if="dataOperator=='1'"><button class="btn-inv" v-on:click="goToItem(item)" title="rates history">{{item.ItemName}}</button></td>
    <td v-else>{{item.ItemName}}</td>
    <td>{{item.ItemUrduName}}</td>
    <td v-if="type!='Livestalk'">{{item.ItemCategory}}</td>
    <td>{{item.Unit}}</td>
    <div v-if="dataOperator!='1'">
    <td><button class="btn-inv" v-on:click="goToBids(item)">Bids</button></td>
    <td><button class="btn-inv" v-on:click="goToRate(item)">Rates</button></td>
    <td v-if="type!='Livestalk'"><button class="btn-inv" v-on:click="goToPredict(item)">Predictions</button></td>
    <td v-if="type!='Preferred' && userType!='Operator'"><button class="btn-inv icon-settings" v-on:click="EditItem(item)"></button></td>
  </div>
  <div v-else>
    <td v-if="type=='Preferred'"><button class="btn-inv icon-delete" v-on:click="DeleteItem(item)"></button></td>
    <td><button class="btn-inv" v-on:click="goToBestRate(item)">Best Rates</button></td>
  </div>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'MandiLine',
        components: {
          // ToggleSubscription
    },
    props:{
      item: Object,
      index: Number,
      type: String,
      dataOperator: String,
      userType: String
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            state: '',
            date: '',
            Units: Object,
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    goToRate: function(item){
      if(item.ItemCategory != 'Livestalk'){
        this.$router.push({
          name: 'AllItemRates',
          params: {
              itemName: item.ItemName,
              Items: item,
              Type: "Item",
              mandiName: ""
          }
        })
      }
      else{
        if(this.userType!='Operator'){
          this.$router.push({
          name: 'AllLivestalkRates',
          params: {
              itemId: item.ItemId,
              itemName: item.ItemName
          }
        })
        }
        else{
          this.$router.push({
          name: 'AllLivestalkRatesOp',
          params: {
              itemId: item.ItemId,
              itemName: item.ItemName,
              userType: 'Operator'
          }
        })
        }

      }

    },
    goToBids(item){
       this.$router.push({
          name: 'AllItemBids',
          params: {
              item: item
          }
      })
    },
    goToItem: function(item){
      this.$router.push({
          name: 'ItemPage',
          params: {
              item: item
          }
      })
    },
    goToBestRate: function(item){
      console.log(item)
      this.$router.push({
          name: 'ItemBestRates',
          params: {
              itemName: item.ItemName,
              Items: item,
              Type: "Item",
              mandiName: "",
              bestRates: "1"
          }
      })
    },
    goToPredict: function(item){
      this.$router.push({
          name: 'AllItemPredictions',
          params: {
              itemName: item.ItemName,
              Items: item,
              Type: "Item",
              mandiName: ""
          }
      })
    },
    EditItem: function(item){
        this.$router.push({
          name: 'EditItem',
          params: {
              Item: item,
          }
      })
      },
    DeleteItem: function(item){
        this.$confirm("Remove "+item.ItemName+" from client's preferred items?").then(() => {
          this.$store.dispatch('removeClientItem', item.PreferredItemId).then(() => {
            this.$emit('item-removed');
            this.$alert("Item removed from client preferred items");
          }).catch(() =>
          this.$alert("Error! Item did not get deleted"))
        }).catch(() => console.log('aborted'))
      }
  }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
