<template>
  <tr>
      <td>{{index}}</td>
      <td>{{rec.CallTime.substring(0, 25)}}</td>
      <td>{{rec.CallMotive}}</td>
      <td>{{rec.OperatorNote}}</td>
      <td>{{rec.FirstName}} {{rec.LastName}}</td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'MandiLine',
        components: {
          // ToggleSubscription
    },
    props:{
      rec: Object,
      index: Number,
      type: String,
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            motiveList: ['Complaint', 'Advice', 'Request', 'Profile Update']
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    OnChangeSelectMotive(e){
      this.rec.CallMotive = e
    }
  },
  computed:{
    todayDate(){return new Date()}
    }
  }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
