<template>
<div>
    <div v-if="isWarning" class="alert alert-warning alert-dismissible fade show" role="alert">
          <strong>{{ msg }}</strong>
          <button v-on:click="$emit('alert-closed')" type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
    <div v-else class="alert alert-success alert-dismissible fade show" role="alert">
          <strong>{{msg}}</strong>
          <button type="button" v-on:click="$emit('alert-closed')" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
</div>
</template>


<script>

    export default {
    name: 'Alert',
   components: {
    },
    props:{
        msg: String,
        isWarning: Boolean,
    },
    data(){
        return{
        }
    },
  mounted() {
  },
  methods: {
     goTo: function(path){
      this.$store.dispatch('goTo', path);
    }
  }
    }
</script>


<style>
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,700,900')
</style>
