<template>
  <div id="login">
    <div>
    <div id="section-login" class="row justify-content-center">
          <div class="col-md-12">
            <h2 class="mb-3" style="color: red">Confirm <span v-if="ClientInfo.ClientSubscription=='Yes'">Unsubscribing</span><span v-if="ClientInfo.ClientSubscription=='Left'">Re-subscribing</span> The follwoing Client:</h2>
            <ClientPage :ClientInfo="ClientInfo"/>
          </div>
        </div>
        <div class="row">
        <div class="col-md-4"/>
        <div class="col-md-4">
          <button class="btn btn-primary py-3 px-5 btn-block"  v-on:click="popupConfirm()" style="background-color: red; border-color: black">Confirm</button>
        </div>
      </div>
    </div>


      </div>
</template>

<script>
import ClientPage from '../../components/ClientPage';
    export default {
        name: 'login',
        components: {
            ClientPage,
          },
        props:{
          ClientInfo: Object
        },
    data(){
        return{
          popupActivo: false,
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    popupConfirm: function(){
      if(this.ClientInfo.ClientSubscription == 'Yes'){
        this.$confirm("Confirm Unsubscribing Client "+ this.ClientInfo.ClientNumber+"?").then(() => {
          this.$store.dispatch('unsubscribeClient', this.ClientInfo.ClientNumber).then(() => this.$alert("Client "+ this.ClientInfo.ClientNumber + "Unsubscribed!")).catch(() => this.$alert("Error! Client Unsubscription faild"))
          }).catch(() => this.$router.push({
          name: 'ClientInfoPage',
          params: {
              ClientInfo: this.ClientInfo,
          }
      }));
      }
      else{
        this.$alert("Subscriptions can only be made through the inbound/outbound service call")
      }

    }

},

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
