<template>
  <tr v-if="!Loading">
    <td v-if="type!='add'">{{item.ProducerItemId}}</td>
    <td v-else></td>
    <td v-if="type!='add'">{{item.ItemCategory}}</td>
    <td v-else></td>
    <td v-if="type!='add'">{{item.ItemName}}</td>
    <td colspan="2" style="width:100%" v-if="type=='add'"><v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :value.sync="value"
                          :options="SelectItem"
                          >
          </v-select></td>
    <td v-if="type!='add'">{{item.ItemUrduName}}</td>
    <td v-if="type!='add' && !edit">{{item.Volume}} {{item.VolumeUnitName}}</td>
    <td v-if="type=='add' || edit">
      <input type="number" placeholder="Volume" style="width: 100px" v-model="NewItem.Volume">
      <br>
      <v-select class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          :v-model="unitId"
                          >
          </v-select>
    </td>
    <td v-if="type!='add' && !edit">{{item.AreaUnderCultivation}} ACER</td>
    <td v-if="type=='add' || edit"><input type="number" placeholder="AreaUnderCultivation" style="width: 100px" v-model="NewItem.AreaUnderCultivation"> ACER</td>
    <td v-if="type!='add' && !edit"><button class="btn-inv icon-settings" v-on:click="EditItem()" title="edit"></button></td>
    <td v-if="type!='add' && edit"><button class="btn-inv icon-plus" style="color: red" v-on:click="SubmitItem()" title="submit"></button></td>
    <td v-if="type=='add'"><button class="btn-inv icon-plus" v-on:click="AddItem(NewItem)" title="add"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'ProducerItemLine',
        components: {
          // ToggleSubscription
    },
    props:{
      item: Object,
      index: Number,
      type: String,
      ProducerId: Number
    },
    data(){
        return{
            Units: [],
            Items: [],
            Loading: false,
            edit: false,
            NewItem: {
              ItemId: Number,
              Volume: Number,
              VolumeUnit: Number,
              AreaUnderCultivation: Number
            }
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    OnChangeSelectItem(e){
          this.NewItem.ItemId = e.value
        },
    OnChangeSelectUnit(e){
          this.NewItem.VolumeUnit = e.value
        },
    AddItem(newitem){
      newitem.AreaUnderCultivation = Number(newitem.AreaUnderCultivation);
      newitem.Volume = Number(newitem.Volume);
      let form = {
        'ProducerId': this.ProducerId,
        'ItemForm': newitem
      };
      this.$store.dispatch('addProducerItem', form).then(() => {
        this.$emit('item-added');
        this.$alert("Item added to producer");
      }).catch(() => {
        this.$alert("Error adding producer item")
      })
    },
    EditItem(){
      this.Loading = true;
      this.edit = true;
      this.NewItem = this.item;
      this.$store.dispatch('getAllUnits').then(resp => {
        this.Units = resp.data.data.units;
        this.Loading = false;
      });

    },
    SubmitItem(){
      this.NewItem.AreaUnderCultivation = Number(this.NewItem.AreaUnderCultivation);
      this.NewItem.Volume = Number(this.NewItem.Volume);
      let Form = {
        'ProducerItemId': this.item.ProducerItemId,
        'ProducerItem': this.NewItem
      }
      this.$store.dispatch('editProducerItem', Form).then(() => {
        this.$emit('item-edited');
        this.$alert("Item edited");
        this.Loading = true;
        this.edit = false;
        this.Loading = false;
      }).catch(() => {
        this.$alert("Error editing producer item")
      })
    },
    // DeleteItem: function(item){
    //     this.$confirm("Remove "+item.ItemName+" from client's preferred items?").then(() => {
    //       this.$store.dispatch('removeClientItem', item.PreferredItemId).then(() => {
    //         this.$emit('item-removed');
    //         this.$alert("Item removed from client preferred items");
    //       }).catch(() =>
    //       this.$alert("Error! Item did not get deleted"))
    //     }).catch(() => console.log('aborted'))
    //   }
  },
  mounted(){
    if(this.type=='add'){
      this.Loading = true;
      this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units);
      this.$store.dispatch('getAllItemsNames').then(resp => {this.Items = resp.data.data.items;
        this.Loading = false
          });
    }

  },
  computed:{
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName+" (" +g.ItemUrduName+")", value: g.ItemId}))
    },
    SelectUnit(){
      return this.Units.map(g => ({label: g.UnitName, value: g.UnitId}))
    },
  }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
