<template>
  <div id="main" class="section first-section">
    <div id="section-main" class="mb-5 row justify-content-center">
          <div class="col-md-7">
            <button class="btn btn-primary py-3 px-5 btn-block" v-on:click="goTo('/admin/mandis-tracking')">RateList Management</button>
          </div>
    </div>
    <div class="mb-5 row justify-content-center">
          <div class="col-md-7">
            <button class="btn btn-primary py-3 px-5 btn-block" v-on:click="goTo('/admin/all-clients')">Telenor Clients Management</button>
          </div>
    </div>
    <div class="mb-5 row justify-content-center">
          <div class="col-md-7">
            <button class="btn btn-primary py-3 px-5 btn-block" v-on:click="goTo('/admin/all-orders')">Supplier/Buyer Managment</button>
          </div>
    </div>
    <div class="mb-5 row justify-content-center">
          <div class="col-md-7">
            <button class="btn btn-primary py-3 px-5 btn-block" v-on:click="goTo('/order/main')">Test Ordering</button>
          </div>
    </div>
  </div>
</template>

<script>
    export default {
        name: 'main',
        components: {
          },
    data(){
        return{
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },

},

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
