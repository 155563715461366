<template>
  <tr>
    <td>{{index}}</td>
    <td>{{item.ItemName}} / {{item.ItemUrduName}}
      <v-select v-if="EditRate" class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :options="SelectItem"
                          >
          </v-select>
    </td>
    <td><button class="btn-inv" v-on:click="seeSeller(item.SellerId)">{{item.SellerName}}</button>
      <v-select v-if="EditRate" class="btn-vselect" v-on:input="OnChangeSelectSeller"
                          :options="SelectSeller"
                          >
          </v-select>
    </td>
    <td>{{item.MandiName}}
      <v-select v-if="EditRate" class="btn-vselect" v-on:input="OnChangeSelectMandi"
                          :options="SelectMandi"
                          >
          </v-select>
    </td>
    <td><span v-if="!EditRate">{{item.LivestalkAge}}</span>
      <input v-else type="number" placeholder="Age" style="width: 80px" v-model="form.LivestalkAge">
    </td>
    <td><span v-if="!EditRate">{{item.LivestalkNumber}}</span>
      <input v-else type="number" placeholder="Number" style="width: 80px" v-model="form.LivestalkNumber">
    </td>
    <td><span v-if="!EditRate">{{item.LivestalkAskingPrice}}</span>
      <input v-else type="number" placeholder="Price" style="width: 100px" v-model="form.LivestalkAskingPrice">
    </td>
    <td>
      <!-- <span v-if="item.AverageMilkYield">{{item.AverageMilkYield}}</span>
       <input v-if="EditRate" type="number" placeholder="Milk Yield" style="width: 100px" v-model="form.AverageMilkYield"> -->
       <input v-if="isAdmin && !item.IsSold" type="checkbox" id="checkbox" v-model="item.IsSold" v-on:change="toggleIsSold()" title="Sold"> <input v-if="isAdmin && item.IsSold" type="checkbox" id="checkbox" v-model="item.IsSold" disabled readonly title="Sold">
    </td>
    <td><span v-if="item.LivestalkWeight">{{item.LivestalkWeight}}</span>
       <input v-if="EditRate" type="number" placeholder="Average Weight" style="width: 100px" v-model="form.LivestalkWeight"> Kg
    </td>
    <td><span v-if="item.Notes && !EditRate">{{item.Notes}}</span>
      <input v-if="EditRate" type="text" placeholder="Notes.." style="width: 100px" v-model="form.Notes">
    </td>
    <td>

      <label class="btn-inv icon-photo_camera" title="Add Picture" style="font-size: 20px">
      <input type="file" v-on:change="uploadfile" name="file" id="file"  accept="image/*" style="display: none;">
      </label>

    </td>
    <td><button v-if="!EditRate  && userType!='Operator'" class="btn-inv icon-settings" v-on:click="StartEditRate()" title="Edit"></button>
      <button v-if="EditRate" class="btn-inv icon-plus" v-on:click="EditItem()" title="Update"></button>
    </td>
    <td v-if="isAdmin"><button class="btn-inv icon-delete" v-on:click="DeleteRate()" title="Delete"></button></td>
</tr>
</template>

<script>
import axios from 'axios';
    export default {
        name: 'LivestalkRateLine',
        components: {
    },
    props:{
      item: Object,
      index: Number,
      userType: String
    },
    data(){
        return{
          EditRate: false,
          form: {},
          Items: [],
          producers: [],
          Mandis: [],
          lactatoionPeriods: ['First', 'Second', 'Thrird'],
          user: {},
          test: false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    toggleIsSold(){
      this.$confirm('Change livestock #'+ String(this.index)+' status to sold?').then(() => {
          this.$store.dispatch('markLivestalkSold', this.item.LivestalkRateId).then(() => {
                this.$alert('Livestock rate status changed')
                this.$emit('rate-edited');
        }).catch(() =>{
          this.$alert('Error, Livestock rate status could not be changed!');
          this.$emit('rate-edited');
        })
      }).catch(() => this.$emit('rate-edited'))
    },
    StartEditRate(){
      this.form = this.item;
      this.EditRate = true;
      this.$store.dispatch('getAllBuyers').then(resp => this.producers = resp.data.data.buyers.filter(buyer => {return buyer.isSeller}));
      this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis);
      this.$store.dispatch('getAllLivestalkNames').then(resp => {this.Items = resp.data.data;
      this.Loading = false})
    },
    OnChangeSelectItem(e){
          this.form.ItemId = e.value
        },
      OnChangeSelectSeller(e){
          this.form.SellerId = e.value
      },
      OnChangeSelectPeriod(e){
        this.form.LactationPeriod = e
      },
      OnChangeSelectMandi(e){
        this.form.MandiId = e.value
      },
      async EditItem(){
        this.form.LivestalkAge = Number(this.form.LivestalkAge);
        this.form.LivestalkNumber = Number(this.form.LivestalkNumber);
        this.form.LivestalkAskingPrice = Number(this.form.LivestalkAskingPrice);
        this.form.AverageMilkYield = Number(this.form.AverageMilkYield);
        let EditedForm ={
          'rate': this.form,
          'rateId': this.item.LivestalkRateId
        }
        await this.$store.dispatch('editLivestalkRate', EditedForm)
        .then(() => {
            this.$alert("Livestock Rate edited");
            this.$emit('rate-edited');
          }).catch(() => {
            this.$alert("Livestock Rate not edited!");
          })
      },
      DeleteRate(){
        this.$confirm("Delete Livestock Rate #" + String(this.index) + " Permanently?").then(() => {
          this.$store.dispatch('deleteLivestalkRate', this.item.LivestalkRateId).then(() => {
            this.$alert("Livestock Rate deleted");
            this.$emit('rate-edited');
          }).catch(() => {
            this.$alert("Livestock Rate not deleted!");
          })
        })
      },
    seeSeller: async function(userId){
      this.$router.push({
          name: 'BuyerPage',
          params: {
              BuyerId: userId,
          }
      })
      },
        async uploadfile (e) {
            let img = e.target.files[0];
            if (img.size > 5e7)
            {
              this.$alert("Cannot upload, image size is too big! (Max allowed 5MB)");
            }
            else{
            console.log(img);
            let file= new FormData();

            file.append('picture', img);
            const url = this.$store.state.server_url;
            const token = this.$store.state.token;
           await axios.post( url + 'livestalk_pic', file, {headers:{
              // 'Content-Type': 'image/jpeg',
              'x-authorization-token': token,
              'Access-Control-Allow-Origin': '*',
              }, params:{'LivestalkRateId': this.item.LivestalkRateId}})
              .then(resp => {
                 this.ImagePath = resp.data.data.upload_path;
                 this.$alert("Image Uploaded!")
              }).catch(err => this.$alert("Upload Failed! " + String(err)))
          this.form.Content = this.ImagePath;
          this.ContentUrl = this.$store.state.s3Bucket_url.concat(this.ImagePath)
            }
          },
  },
  computed:{
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName + " / " + g.ItemUrduName, value: g.ItemId}))
    },
    SelectSeller(){
      return this.producers.map(g => ({label: g.BuyerName + " - " + g.BuyerCity, value: g.BuyerId}))
    },
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId}))
    },
    isAdmin(){
      return this.$store.getters.isAdmin
    }
  }
    }
</script>
