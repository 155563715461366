<template>
  <tbody>
  <AddItemSourceLine :ItemId="ItemId" :MandiId="MandiId" :Mandis="Mandis" :Units="Units" v-on:add-more="addMore" FirstLine="1" v-on:send-all="AddSources" v-on:send="addLast"/>
    <AddItemSourceLine v-for="ind in addlist" :key="ind" :ItemId="ItemId" :MandiId="MandiId" :Mandis="Mandis" :Units="Units" FirstLine="0" v-on:add-more="addMore" v-on:add-last="addLast"/>
</tbody>
</template>

<script>
  import AddItemSourceLine from './NewItemSourceLine'
    export default {
    name: 'SourceLine',
    components:{
      AddItemSourceLine
    },
    props:{
      ItemId: Number,
      MandiId: Number,
    },
    data(){
        return{
            Mandis: [],
            Units: [],
            loading: true,
            form: {},
            changeUnit: false,
            today: "",
            sources: [],
            addlist: [],
            count : 0
        }
    },
    methods:{
      addMore(e){
        this.sources.push(e);
        this.count = this.count + 1;
        this.addlist.push(this.count);
      },
      addLast(e){
        this.sources.push(e);
        this.AddSources();
      },
      async AddSources(){
      let srcForm = {
        'itemId': this.ItemId,
        'source': this.sources
      };
      await this.$store.dispatch('addItemSources', srcForm)
        .then(() => {
          this.$emit('src-added');
          this.$alert("Item Sources added");
          }).catch(() => {
            this.$alert("Error! Not All Item Sources not added")
          })
    }
    },
    mounted(){
      this.loading = true;
      this.$store.dispatch('getAllUnits').then(resp => this.Units = resp.data.data.units);
      this.$store.dispatch('getAllMandis').then(resp => {this.Mandis = resp.data.data.mandis.filter(mandi => {return mandi.RetailOnly != 1}); this.loading = false});
    },
    computed:{
  }

  }
</script>

