<template>
  <tr>
    <td>{{index}}</td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectMandi"
                          :options="SelectMandi"
                          >
    </v-select></td>

     <td> <input type="number" style="width: 100px" placeholder="Amount" v-model="form.Amount"> Quintal <button class="btn-inv" v-on:click="changeUnit=true">(change unit)</button>
      <v-select v-if="changeUnit" class="btn-vselect" v-on:input="OnChangeSelectUnit"
                          :value.sync="value"
                          :options="SelectUnit"
                          :v-model="unitId"
                          >
    </v-select></td>
    <td><input type="number" v-model="form.PackageWeight"  placeholder="Weight"></td>
    <td><input type="date" name="from"  v-model="form.ArrivalDate"  :max="today"></td>
    <td v-if="!done"><button class="btn-inv icon-plus" v-on:click="AddMore()">Add More</button></td>
     <td v-if="FirstLine==1"><button v-on:click="SendAll()">Send All</button></td>
     <td v-if="FirstLine==0"><button v-on:click="Done()">Done</button></td>
</tr>
</template>

<script>
    export default {
    name: 'SourceLine',
    props:{
      ItemId: Number,
      MandiId: Number,
      index: Number,
      Mandis: Array,
      Units: Array,
      FirstLine: Number
    },
    data(){
        return{
            form: {},
            changeUnit: false,
            today: "",
            addedMore: false,
            done: false
        }
    },
    methods:{
      OnChangeSelectMandi(e){
        this.form.SourceMandiId = e.value
      },
      OnChangeSelectUnit(e){
          this.form.AmountUnitId = e.value
        },
      dateFormate(date){
        let month = '';
        let day = '';
        if(date.getMonth()+1 < 10){
            month = '0' + (date.getMonth()+1)
        }
        else{
            month = (date.getMonth()+1)
        }
        if(date.getDate() < 10){
            day = '0' + (date.getDate())
        }
        else{
            day = (date.getDate())
        }
        return date.getFullYear()+'-'+month+'-'+day;
    },
    AddMore(){
      this.form.Amount = Number(this.form.Amount);
      this.form.PackageWeight = Number(this.form.PackageWeight);
      this.$emit('add-more', this.form);
      this.addedMore = true;
    },
    Done(){
      this.form.Amount = Number(this.form.Amount);
      this.form.PackageWeight = Number(this.form.PackageWeight);
      this.done = true;
      this.$emit('add-last', this.form);
    },
    SendAll(){
      if(this.addedMore){
      this.$emit('send-all');
    }
    else{
      this.form.Amount = Number(this.form.Amount);
      this.form.PackageWeight = Number(this.form.PackageWeight);
      this.$emit('send', this.form);
    }
    },
    },
    mounted(){
      const today_row = new Date();
      today_row.setDate(today_row.getDate() + 1);
      this.today = this.dateFormate(today_row);
      this.form.ArrivalDate = this.today;
      this.form.DestMandiId = this.MandiId;
      this.form.AmountUnitId = 8;
    },
    computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId}))
    },
    SelectUnit(){
      return this.Units.map(g => ({label: g.UnitName, value: g.UnitId}))
    },
  }

  }
</script>

