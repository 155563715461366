import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    UserLoggedIn: localStorage.getItem('userType') || 0,
    server_url: 'https://api.madadgaar.net/',
    s3Bucket_url: 'https://d3c829pr48z735.cloudfront.net/',
    AdminLoggedIn: localStorage.getItem('isAdmin') || false,
    SemiLoggedIn: localStorage.getItem('isSemiAdmin') || false,
    //'http://www.vrkstudios.net.s3-website-ap-southeast-1.amazonaws.com/',
  },
  mutations: {
      auth_request(state){
        state.status = 'loading'
      },
      auth_success(state, token, data){
        state.status = 'success'
        state.token = data.token
        state.user = data.user
      },
      auth_error(state){
        state.status = 'error';
      },
      logout(state){
        state.status = ''
        state.token = ''
      },
      unauthorized_access(state){
        state.status = 'unauthorized';
        router.push('/unauthorized');
      },
      router_link(){
      }
    },
  actions: {
    goTo({commit}, path){
      commit('router_link');
      router.push(path);
      window.scrollTo(0,0);
    },
    signup({commit}, userForm){
      return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      commit('auth_request')
      axios.post(url + 'signup', userForm, {headers:{
        'Content-Type': 'application/json'
      }})
      .then(resp => {
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        // commit('auth_error')
        reject(err)
              })
            })
    },
    login({commit}, config){
    return new Promise((resolve, reject) => {
      commit('auth_request')
      const url = this.state.server_url;
      axios.get(url + 'login', config, {headers:{
        'Content-Type': 'application/json'
      }})
      .then(resp => {
        console.log(resp);
        // const data = resp.data;
        const token = resp.headers["x-authorization-token"];
        localStorage.setItem('token', token);
        axios.defaults.headers.common['Authorization'] = token;
        // commit('auth_success', data);
        resolve(resp)
      })
      .catch(err => {
        commit('auth_error')
        localStorage.removeItem('token')
        reject(err)
              })
            })
        },

      logout({commit}){
          return new Promise((resolve) => {
            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('isAdmin');
            localStorage.removeItem('isSemiAdmin');
            localStorage.removeItem('isOperator');
            localStorage.removeItem('isMandiOperator');
            router.push('/main').then(()=> router.go());
            delete axios.defaults.headers.common['Authorization'];
            resolve();

          })
        },

      getUserInfo({commit}, config){
         return new Promise((resolve, reject) => {
      // commit('auth_request');
      // const token = this.state.token || localStorage.getItem('token');
      const url = this.state.server_url;
      axios.get(url + 'user', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

       getUserInfoByAdmin({commit}, UserId){
         return new Promise((resolve, reject) => {
      const token = this.state.token;
      const url = this.state.server_url;
      axios.get(url + 'user/' + String(UserId), {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }})
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      changePassword({commit}, passwordForm){
      return new Promise((resolve, reject) => {
      commit('auth_request');
      const token = this.state.token;
      console.log(myObjStr);
      const myObjStr = JSON.parse(JSON.stringify(passwordForm));
      console.log(myObjStr);
      const url = this.state.server_url;
      axios.post(url + 'change_pwd', myObjStr, {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }})
      .then(resp => {
        console.log(resp);
        resolve(resp)
      })
      .catch(err => {
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })
    },


      //Admin Calls
      getAllUsers({commit}){
         return new Promise((resolve, reject) => {
      const token = this.state.token || localStorage.getItem('token');
      const url = this.state.server_url;
      axios.get(url + 'all_users',{headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }})
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        console.log(err);
        reject(err)
              })
            })
      },

      editUserByAdmin({commit}, UserForm){
         return new Promise((resolve, reject) => {
      commit('auth_request')
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
      var form = {}
      if(UserForm.Password!="")
      {
        form =
      {'Email' : UserForm.Email,
      'FirstName' : UserForm.FirstName,
      'LastName' : UserForm.LastName,
      'PhoneNumber' : UserForm.PhoneNumber,
      'UserTypeId' : Number(UserForm.UserTypeId),
      'UserMandiId': Number(UserForm.UserMandiId),
      'UserMandiList': UserForm.UserMandiList,
      'Password': UserForm.Password
        }
      }
      else{
        form =
      {'Email' : UserForm.Email,
      'FirstName' : UserForm.FirstName,
      'LastName' : UserForm.LastName,
      'PhoneNumber' : UserForm.PhoneNumber,
      'UserTypeId' : Number(UserForm.UserTypeId),
      'UserMandiId': Number(UserForm.UserMandiId),
      'UserMandiList': UserForm.UserMandiList
        }
      }
      // const myObjStr = JSON.parse(JSON.stringify(form));
      axios.post(url + 'user/' + String(UserForm.UserId), form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      editMandiByAdmin({commit}, MandiForm){
         return new Promise((resolve, reject) => {
      commit('auth_request')
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
      const form =
      {'MandiName' : MandiForm.MandiName,
      'City' : MandiForm.City,
      'District' : MandiForm.District,
      'RetailOnly': MandiForm.RetailOnly,
      'BulkMandiId': MandiForm.BulkMandiId,
      'MandiX': Number(MandiForm.MandiX),
      'MandiY': Number(MandiForm.MandiY),
    };
      // const myObjStr = JSON.parse(JSON.stringify(form));
      axios.post(url + 'mandi/' + String(MandiForm.MandiId), form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      editItemByAdmin({commit}, ItemForm){
         return new Promise((resolve, reject) => {
      commit('auth_request')
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
      const form =
      {'ItemName' : ItemForm.ItemName,
      'ItemUrduName' : ItemForm.ItemUrduName,
      'ItemCategory' : ItemForm.ItemCategory,
      'DefaultUnitId': Number(ItemForm.DefaultUnitId)
    };
      // const myObjStr = JSON.parse(JSON.stringify(form));
      axios.post(url + 'item/' + String(ItemForm.ItemId), form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      editLivestalkRate({commit}, Form){
         return new Promise((resolve, reject) => {
      commit('auth_request')
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
    //   const form =
    //   {'ItemName' : ItemForm.ItemName,
    //   'ItemUrduName' : ItemForm.ItemUrduName,
    //   'ItemCategory' : ItemForm.ItemCategory,
    //   'DefaultUnitId': Number(ItemForm.DefaultUnitId)
    // };
      // const myObjStr = JSON.parse(JSON.stringify(form));
      axios.post(url + 'livestalk_rate/' + String(Form.rateId), Form.rate, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      deleteLivestalkRate({commit}, RateId){
         return new Promise((resolve, reject) => {
      commit('auth_request')
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
      axios.get(url + 'delete_livestalk/' + String(RateId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      deleteItemSource({commit}, SrcId){
         return new Promise((resolve, reject) => {
      commit('auth_request')
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
      axios.get(url + 'delete_item_source/' + String(SrcId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      markLivestalkSold({commit}, SrcId){
         return new Promise((resolve, reject) => {
      commit('auth_request')
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
      axios.get(url + 'sold_livestalk/' + String(SrcId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },


      addItem({commit}, ItemForm){
         return new Promise((resolve, reject) => {
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      // const myObjStr = JSON.parse(JSON.stringify(ClassForm));
      const url = this.state.server_url;
      axios.post(url + 'add_item', ItemForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        console.log(err);
        reject(err)
              })
            })
      },


      addMandi({commit}, MandiForm){
         return new Promise((resolve, reject) => {
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      const url = this.state.server_url;
      MandiForm.MandiX = Number(MandiForm.MandiX);
      MandiForm.MandiY = Number(MandiForm.MandiY);
      axios.post(url + 'add_mandi', MandiForm, config)
     .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        console.log(err);
        reject(err)
              })
            })
      },


      addRate({commit}, RateForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      console.log(token)
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.post(url + 'add_rate', RateForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      addLivestalkRate({commit}, form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      console.log(token)
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.post(url + 'add_livestalk_rate', form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      addPredict({commit}, Predictform){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.post(url + 'add_predict', Predictform, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      addMulti({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      console.log(token)
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.post(url + 'add_multi', Form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('unauthorized_access')}
        reject(err)
              })
            })

      },

      getAllMandis({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_mandis', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getMandi({commit}, mandiId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'mandi/' + String(mandiId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllUnits({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_units', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getAllItems({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_items', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getLatestItemSoucres({commit}, srcForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'itemid': srcForm.ItemId, 'mandiid': srcForm.MandiId}};
      axios.get(url + 'item_sources', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getItemSoucres({commit}, srcForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'itemid': srcForm.ItemId, 'mandiid': srcForm.MandiId, 'from': srcForm.from, 'to': srcForm.to}};
      axios.get(url + 'item_sources', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addItemSources({commit}, srcForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      let sources = {'sources': srcForm.source};
      axios.post(url + 'add_item_sources/' + String(srcForm.itemId), sources, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addItemBidRates({commit}, srcForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      let bidrates = {'bidrates': srcForm.bidrates};
      axios.post(url + 'add_item_bidrates/' + String(srcForm.itemSourceId), bidrates, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllItemsNames({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_item_names', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getAllItemsAllNames({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'itemcategory': 'All'}};
      axios.get(url + 'all_item_names', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getAllLivestalkNames({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_livestalk', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getAllLivestalkRates({commit}, TimePeriod){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'from': TimePeriod.from, 'to': TimePeriod.to}};
      axios.get(url + 'livestalk_rates', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getItemRates({commit}, ItemId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'item/' + String(ItemId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getItemFlags({commit}, Item){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.post(url + 'item_flags/' + String(Item.ItemId), Item.MandisList, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getItemPredictions({commit}, ItemId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'item_prediction/' + String(ItemId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getItemRatesHistory({commit}, ItemId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'item_history/' + String(ItemId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getMandiItems({commit}, MandiId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'mandiid' : MandiId}};
      axios.get(url + 'all_items', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getTodayRates({commit}, MandiId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'todays_rate/' + String(MandiId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },
      //download
      downloadAllMandis({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'x-authorization-token': token,
        responseType: 'blob'
      }};
      axios.get(url + 'all_mandis_csv',config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      downloadTodaysRates({commit}, MandiId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'csv' : true}};
      axios.get(url + 'todays_rate/' + String(MandiId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      downloadMandiSheet({commit}, MandiId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'download_rate_file/' + String(MandiId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },


      getAllClients({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_clients', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getClientById({commit}, ClientNumber){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'client/' + String(ClientNumber), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      unsubscribeClient({commit}, ClientNumber){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'x-authorization-token': token,
      }};
      axios.get(url + 'unsubscribe_client/' + String(ClientNumber), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      editClientById({commit}, ClientForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        // 'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      let form = ClientForm.form;
      axios.post(url + 'client/' + String(ClientForm.ClientNumber), form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addClient({commit}, ClientForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.post(url + 'add_client', ClientForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getClientItems({commit}, ClientNumber){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'preferred_items/' + String(ClientNumber), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      removeClientItem({commit}, preferredItemId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'x-authorization-token': token
      }};
      axios.get(url + 'remove_preferred_item/' + String(preferredItemId),config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addClientItem({commit}, preferredItem){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'x-authorization-token': token
      }, params:{'itemid' : preferredItem.ItemId}};
      axios.get(url + 'add_preferred_item/' + String(preferredItem.ClientNumber), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getItemBestRatesInFarmerGroup({commit}, itemForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'mandiid' : String(itemForm.MandiId)}};
      axios.get(url + 'item_history/' + String(itemForm.ItemId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      getItemBestRatesInFarmerGroup2({commit}, itemForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }, params:{'mandiid' : String(itemForm.MandiId)}};
      axios.get(url + 'item/' + String(itemForm.ItemId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })

      },

      addClientCallRecord({commit}, Record){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'x-authorization-token': token
      }};
      let RecordForm ={
        'CallMotive': Record.CallMotive,
        'OperatorNote': Record.OperatorNote
      };
      axios.post(url + 'add_call_record/' + String(Record.ClientNumber), RecordForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      //Buyer-Supplier Calls
      getAllBuyers({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_buyers', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllProducers({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_producers', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllOrders({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_orders', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllNodes({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_nodes', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllTrucks({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_trucks', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllStorageProviders({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token,
      }};
      axios.get(url + 'all_providers', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getBuyerOrders({commit}, buyerId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      console.log('buyer id');
      console.log(buyerId);
      const config = {headers:{
        'x-authorization-token': token,
      }, params:{'buyerid' : buyerId}};
      axios.get(url + 'all_orders', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      changeOrderStatus({commit}, changeForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'x-authorization-token': token,
      }, params:{'status' : changeForm.OrderStatus}};
      axios.get(url + 'change_order_status/' + String(changeForm.OrderId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addOrder({commit}, OrderForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.post(url + 'add_order', OrderForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addBuyer({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.post(url + 'add_buyer', Form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addProducer({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.ProducerX = Number(Form.ProducerX);
      Form.ProducerY = Number(Form.ProducerY);
      axios.post(url + 'add_producer', Form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addProvider({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.ProviderX = Number(Form.ProviderX);
      Form.ProviderY = Number(Form.ProviderY);
      Form.GeneralStorageCapacity = Number(Form.GeneralStorageCapacity);
      Form.ColdStorageCapacity = Number(Form.ColdStorageCapacity);
      Form.FreeStorageArea = Number(Form.FreeStorageArea);
      axios.post(url + 'add_provider', Form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addNode({commit}, NodeForm){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      NodeForm.NodeX = Number(NodeForm.NodeX);
      NodeForm.NodeY = Number(NodeForm.NodeY);
      axios.post(url + 'add_node', NodeForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addTruck({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.TruckMaxLoad = Number(Form.TruckMaxLoad);
      axios.post(url + 'add_truck', Form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      editTruck({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.TruckForm.TruckMaxLoad = Number(Form.TruckForm.TruckMaxLoad);
      axios.post(url + 'truck/' + String(Form.TruckId), Form.TruckForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      editNode({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.NodeForm.NodeX = Number(Form.NodeForm.NodeX);
      Form.NodeForm.NodeY = Number(Form.NodeForm.NodeY);
      axios.post(url + 'node/' + String(Form.NodeId), Form.NodeForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getNode({commit}, nodeId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.get(url + 'node/' + String(nodeId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addRoute({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.RouteCost = Number(Form.RouteCost);
      axios.post(url + 'add_route', Form, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getAllRoutes({commit}){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.get(url + 'all_routes', config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },


      editProducer({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.ProducerForm.ProducerX = Number(Form.ProducerForm.ProducerX);
      Form.ProducerForm.ProducerY = Number(Form.ProducerForm.ProducerY);
      axios.post(url + 'producer/' + String(Form.ProducerId), Form.ProducerForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      editBuyer({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.post(url + 'buyer/' + String(Form.BuyerId), Form.BuyerForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      editProvider({commit}, Form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      Form.ProviderForm.ProviderX = Number(Form.ProviderForm.ProviderX);
      Form.ProviderForm.ProviderY = Number(Form.ProviderForm.ProviderY);
      axios.post(url + 'provider/' + String(Form.ProviderId), Form.ProviderForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getBuyerById({commit}, BuyerId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.get(url + 'buyer/' + String(BuyerId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      addProducerItem({commit}, form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.post(url + 'add_producer_item/' + String(form.ProducerId), form.ItemForm, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getProducerById({commit}, ProducerId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.get(url + 'producer/' + String(ProducerId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      editProducerItem({commit}, form){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.post(url + 'producer_item/' + String(form.ProducerItemId), form.ProducerItem, config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      deleteOrder({commit}, orderId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'x-authorization-token': token
      }};
      axios.get(url + 'delete_order/' + String(orderId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },

      getOrderById({commit}, orderId){
         return new Promise((resolve, reject) => {
      const url = this.state.server_url;
      const token = this.state.token;
      const config = {headers:{
        'Content-Type': 'application/json',
        'x-authorization-token': token
      }};
      axios.get(url + 'order/' + String(orderId), config)
      .then(resp => {
        console.log(resp);
        resolve(resp);
      })
      .catch(err => {
        console.log(err);
        // commit('unauthorized_access')
        if (err.response.status == 401)
          {commit('bla')}
        reject(err)
              })
            })
      },


  },
  getters : {
      isLoggedIn: state => !!state.token,
      authStatus: state => state.status,
      isAdmin: state => state.AdminLoggedIn,
      isSemiAdmin: state => state.SemiLoggedIn,
    }
})
