import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'

import BootstrapVue from 'bootstrap-vue/dist/bootstrap-vue.esm';
import AOS from "aos";
import feather from 'vue-icon'
import VueResource from 'vue-resource';
import VueSidebarMenu from 'vue-sidebar-menu'

// Import the styles
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "aos/dist/aos.css";
import "vue-sidebar-menu/src/scss/vue-sidebar-menu.scss";
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// css local files
import '@/assets/fonts/icomoon/style.css';
import "@/assets/fonts/flaticon/font/flaticon.css";
import "@/assets/css/bootstrap.min.css";
import "@/assets/css/jquery-ui.css";
import "@/assets/css/owl.carousel.min.css";
import "@/assets/css/owl.theme.default.min.css";
import "@/assets/css/jquery.fancybox.min.css";
import "@/assets/css/bootstrap-datepicker.css";
import "@/assets/css/aos.css";
import "@/assets/css/style.css";
import "@/assets/css/sidebar.css";

import VueScrollTo from 'vue-scrollto'
import VFileInput from 'v-file-input'
import vSelect from "vue-select";

import "vue-select/dist/vue-select.css";
import 'vue-select/src/scss/vue-select.scss';
import '@progress/kendo-ui'
import VModal from 'vue-js-modal/dist/index.nocss.js';
import 'vue-js-modal/dist/styles.css';

import 'zingchart/es6';
import zingchartVue from 'zingchart-vue';
Vue.component('zingchart', zingchartVue);

Vue.use(VModal);
import VueSimpleAlert from "vue-simple-alert";

import VueElUpload from 'vue-el-upload';
Vue.use(VueElUpload);

import ProgressBar from 'vuejs-progress-bar';
Vue.use(ProgressBar);

import { MapsComponent, MapsPlugin } from '@syncfusion/ej2-vue-maps';

Vue.component(MapsPlugin.name, MapsComponent);

Vue.use(VueSimpleAlert);
var vueEl = require('vue-el')
Vue.use(vueEl)
Vue.use(VFileInput)
Vue.use(VueScrollTo)
Vue.component("v-select", vSelect);
Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(feather, 'v-icon');
Vue.use(VueSidebarMenu);
Vue.http.options.crossOrigin = true;
Vue.config.productionTip = false;

Vue.directive('disable-space', { // you can rename it to your liking
    update (el) {
        // using regex to only remove spaces at the beginning
        el.value = el.value.replace(/(^\s*)|(\s*$)/gi, ""); // or you can use your own preferred way to remove leading spaces
        el.dispatchEvent(new Event('input'))
    }
})

new Vue({
    created() {
    AOS.init({ disable: "phone" });
  },
    router,
    store,
  render: h => h(App),
}).$mount('#app')


