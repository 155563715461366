<template>
  <div calss="container" id="AllClients">
    <div class="row">
      <div class="col-lg-12 mb-3">
      <h1 >Routes connecting {{node.NodeCity}} ({{node.NodeLocale}}):</h1>
      </div>
    </div>

    <div v-if="loading" class="row">
      <p>Loading ...</p>
    </div>
    <div v-else>
      <button v-on:click="addRouteFrom=!addRouteFrom" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Route From Node</span></button>
      <span class="line"></span>
      <button v-on:click="addRouteTo=!addRouteTo" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Route To Node</span></button>
      <br>
      <p v-if="fullNode.from_routes.length==0 && fullNode.to_routes.length==0">
        No Available routes  from/to this node
      </p>
      <br>
      <OneRoute v-if="addRouteTo" type="to" :node="node" :route="{}" v-on:added="refresh()"/>
      <OneRoute v-if="addRouteFrom" type="from" :node="node" :route="{}" v-on:added="refresh()"/>
      <div v-if="fullNode.to_routes.length!=0">
        <h3>Routes To {{node.NodeCity}}:</h3>
        <br>
        <div calss="row" v-for="route in fullNode.to_routes" :key="route.RouteId">
          <OneRoute type="display-to" :node="node" :route="route" v-on:added="refresh()"/>
        </div>
      </div>

      <div v-if="fullNode.from_routes.length!=0">
        <h3>Routes From {{node.NodeCity}}:</h3>
        <br>
        <div calss="row" v-for="route in fullNode.from_routes" :key="route.RouteId">
          <OneRoute type="display-from" :node="node" :route="route" v-on:added="refresh()"/>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import OneRoute from '../../components/OneRoute'
    export default {
        name: 'NodeRoutes',
        components: {
          OneRoute
    },
    props:{
      node: Object
    },
    data(){
        return{
          fullNode: {},
          loading: true,
          addRouteFrom: false,
          addRouteTo: false
        }
    },
    mounted(){
      this.refresh()
    },
    methods:{
      refresh(){
        this.loading = true;
        this.addRouteFrom = false;
        this.addRouteTo = false;
        this.$store.dispatch('getNode', this.node.NodeId).then(resp => {
            this.fullNode = resp.data.data;
            this.loading = false})
      }
      },
    }
</script>

<style>
.line {
  width: 25%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 3px;
  display:inline-block;
}
</style>
