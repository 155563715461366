<template>
  <tr v-if="!loading">
    <td>#</td>
    <td><v-select class="btn-vselect rounded" v-on:input="OnChangeSelectStartNode"
                          :options="Nodes"
                          >
          </v-select></td>
    <td><v-select class="btn-vselect rounded" v-on:input="OnChangeSelectEndNode"
                          :options="Nodes"
                          >
          </v-select></td>
    <td><v-select class="btn-vselect rounded" v-on:input="OnChangeSelectTruck"
                          :options="Trucks"
                          >
          </v-select></td>
    <td><input type="number" style="width: 110px" v-model="form.RouteCost"></td>
    <td><button class="btn-inv icon-plus" title="add" v-on:click="addRoute()"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'RouteLine',
        components: {
    },
    props:{
      nodes: Array,
      trucks: Array
    },
    data(){
        return{
          form:{},
          loading: true

        }
    },
    mounted(){
      if(this.Cost){
        this.form.RouteCost = this.Cost;
      }
      this.loading = false
    },
    methods:{
      OnChangeSelectStartNode(e){
        this.form.StartNodeId = e.value
      },
      OnChangeSelectEndNode(e){
        this.form.EndNodeId = e.value
      },
      OnChangeSelectTruck(e){
        this.form.TruckId = e.value
      },
      addRoute(){
        if (this.form.StartNodeId == this.form.EndNodeId){
          this.$alert("Error: start node = end node!");
        }
        else{
        if (this.form.RouteCost > 0){
        this.$store.dispatch('addRoute', this.form).then(() => {
          this.$emit('added');
          this.$alert("added route!");
          }).catch(() => {
            this.$alert("Error adding route!");
          })
        }
        else{
          this.$alert("Error: cost should be > 0!");
        }
        }
      },
      },
  computed:{
    Nodes(){
      return this.nodes.map(g => ({label: g.NodeCity + ", " + g.NodeLocale, value: g.NodeId}))
    },
    Trucks(){
      return this.trucks.map(g => ({label: g.TruckName + ", (" + g.TruckMaxLoad + "MT)", value: g.TruckId}))
    }
  }
    }
</script>

