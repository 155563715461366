<template>
  <div id="login">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h2 class="section-title mb-3">Add Producer</h2>
            <form v-on:submit.prevent="AddProducer">
              <ProducerForm FormType='Edit' :Inputform="form" v-on:form-updated="form = $event"/>

              <div class="form-group row">
                <div class="col-md-12">
                  <Alert v-if="AddFail == true" v-on:alert-closed="AddFail = false" :isWarning="AddFail" :msg="'Oops! Please Complete The Form'"/>
                    <Alert v-if="AddDone == true" v-on:alert-closed="SignupDone = false" :isWarning="!AddDone" msg='Producer Added Successfully'/>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Add">
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
</div>
</template>

<script>
import Alert from '../../components/Alert.vue'
import ProducerForm from '../../components/ProducerForm'
    export default {
        name: 'login',
        components: {
            Alert,
            ProducerForm,
          },
    data(){
        return{
            form: {
              ProducerName: '',
              ProducerCity: '',
              ProducerAddress: '',
              ProducerX: Number,
              ProducerY: Number,
              ProducerType: '',
              ProducerContact: ''
            },
            msg: '',
            isHidden: true,
            AddFail: false,
            AddDone: false,
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    async AddProducer(){
        await this.$store.dispatch('addProducer', this.form)
        .then(() => {
          this.AddDone = true}).catch(() => this.AddFail = true)
  }
},

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
