<template>
  <tr>
    <td>
      {{index}}
    </td>
    <td>{{node.TruckName}}</td>
    <td>{{node.TruckMaxLoad}}</td>
    <td>{{node.Notes}}</td>
    <td><button class="btn-inv icon-settings" title="edit" v-on:click="editNode()"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'MandiLine',
        components: {
    },
    props:{
      node: Object,
      index: Number
    },
    methods:{
      editNode(){
        this.$emit('edit')
      }
      },
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
