<template>
  <form v-on:submit.prevent="AddClient" class="container" style="margin-left: -7px">
    <h1 class="section-title-mod" >Add Client</h1>
    <div class="form-group row">
        <div class="col-md-12">
        <p>Client Name:</p>
          <input type="text" class="form-control" placeholder="Name" v-model="form.ClientName">
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Client Number:</p>
          <input type="text" class="form-control" placeholder="Number" v-model="form.ClientNumber">
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Client Location:</p>
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="District" v-model="form.ClientDistrict">
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="Locale" v-model="form.ClientLocale">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Client Address:</p>
        </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Address" v-model="form.ClientAddress">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Associate with farming?</p>
          <v-select v-if="gotMandis" class="btn-vselect rounded" v-on:input="OnChangeSelectFarming"
                          :value.sync="value"
                          :options="YesNo"
                          >
          </v-select>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Add Nearest Mandi:</p>
          <v-select v-if="gotMandis" class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          >
          </v-select>
      </div>
      </div>

<!--       <div class="form-group row">
        <div class="col-md-12">
        <p>Preferred Category:</p>
          <v-select v-if="gotMandis" class="btn-vselect rounded" v-on:input="OnChangeSelectcategory"
                          :value.sync="value"
                          :options="Categories"
                          >
          </v-select>
      </div>
      </div> -->

      <!-- <div class="form-group row">
        <div class="col-md-12">
        <p>Additional Notes:</p>
          <textarea class="form-control" placeholder="Notes..." v-model="form.OperatorNote"/>
      </div>
      </div> -->

       <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
          <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Add">
        </div>
      </div>

    </form>
</template>

<script>
    export default {
        name: 'changeMandi',
        components: {
    },
    props:{
      ClientInfo: Object
    },
    data(){
        return{
            form:{
            },
            Mandis: Object,
            gotMandis: false,
            YesNo: ['Yes', 'No'],
            Categories: ['Fruit', 'Vegetable', 'Grain']
        }
    },
    methods:{
        ChangeFormEmit(){
            this.$emit('form-updated', this.form)
        },
        OnChangeSelectMandi(e){
          this.form.NearestMandiId = e.value
          // this.form.MandiName = e.label
          this.ChangeFormEmit()
        },
        OnChangeSelectFarming(e){
          this.form.isFarmer = '0';
          if(e == 'Yes'){
            this.form.isFarmer = '1';
          }
        },
        OnChangeSelectcategory(e){
          this.form.PrefferedCategory = e
        },
        AddClient(){
          this.$store.dispatch('addClient', this.form).then(() =>
              {this.$alert("Client Added!");
              this.$router.push({
                  name: 'ClientInfoPage',
                  params: {
                      ClientNumber: this.form.ClientNumber,
                      Type: 'Operator'
                  }
                  })
            }).catch(()=>{
              this.$alert("Client Not Added!");
            })
        }
    },
    created () {
        this.$store.dispatch('getAllMandis').then(resp =>
                                                  {this.Mandis = resp.data.data.mandis;
                                                    this.gotMandis = true;});

  },
  computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: String(g.MandiName)+', '+String(g.City), value: g.MandiId}))
    },
  }
}
</script>

