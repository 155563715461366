<template>
  <div id="mandi-form">
      <div class="form-group row">
        <div class="col-md-12">
        <p>Personal Information:</p>
      </div>
        <div class="col-md-6 mb-3 mb-lg-0">
          <input type="text" class="form-control" placeholder="Full Name" v-model="form.BuyerName" v-on:change="ChangeFormEmit" required>
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="Contact (Phone/Email)" v-model="form.BuyerContact" v-on:change="ChangeFormEmit" required>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Address:</p>
      </div>
        <div class="col-md-6 mb-3 mb-lg-0">
          <input type="text" class="form-control" placeholder="Address" v-model="form.BuyerAddress" v-on:change="ChangeFormEmit" required>
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="City" v-model="form.BuyerCity" v-on:change="ChangeFormEmit" required>
        </div>
      </div>


      <div class="form-group row">
        <div class="col-md-12">
        <p>Select Preferred Category/ies: <span v-if="fromType=='edit'"><p v-for="(cat, index) in form.BuyerPreference" :key="index">{{cat}}</p></span> </p>
          <v-select multiple class="btn-vselect" v-on:input="OnChangeSelectPreference"
                          :options="prefs"
                          :v-model="form.BuyerPreference">
          </v-select>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Select Type: <span v-if="fromType=='edit'">{{form.BuyerType}}</span></p>
          <v-select class="btn-vselect" v-on:input="OnChangeSelectType"
                          :options="buyerTypes"
                          :v-model="form.BuyerType">
          </v-select>
      </div>
      </div>

      <div v-if="type=='buyer'" class="form-group row">
        <div class="col-md-12">
        <p>Is this a seller as well?
          <input type="checkbox" id="seller" v-model="isSeller" v-on:change="ChangeFormEmit">
          <label for="seller">  Yes</label>
        </p>
      </div>
      </div>

      <div v-if="type=='seller'" class="form-group row">
        <div class="col-md-12">
        <p>Is this a buyer as well?
          <input type="checkbox" id="buyer" v-model="isBuyer" v-on:change="ChangeFormEmit">
          <label for="buyer">  Yes</label>
        </p>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Additional Notes:</p>
          <input type="text" class="form-control" placeholder="Notes" v-model="form.Notes" v-on:change="ChangeFormEmit">

      </div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'BuyerForm',
        components: {
    },
    props:{
      Inputform: Object,
      type: String,
      fromType: String
    },
    data(){
        return{
            form: Object,
            prefs: ['Fruit', 'Vegetable', 'Grain', 'Livestalk'],
            buyerTypes: ['Small','Medium','Industrial'],
            isSeller: false,
            isBuyer: false
        }
    },
    methods:{
        ChangeFormEmit(){
          if(this.type == 'buyer'){
            this.form.isSeller = 0;
            if(this.isSeller){
              this.form.isSeller = 1
            }
          }
          if(this.type == 'seller'){
            this.form.onlySeller = 1;
            if(this.isBuyer){
              this.form.onlySeller = 0
            }
          }
          this.$emit('form-updated', this.form)
        },
        OnChangeSelectPreference(e){
          this.form.BuyerPreference = e;
          this.ChangeFormEmit()
          },
        OnChangeSelectType(e){
          this.form.BuyerType = e;
          this.ChangeFormEmit()
        }
    },
    created(){
      if(this.Inputform){
        this.form = this.Inputform
      }
    },
  computed:{
  }
}
</script>

