<template>
  <div id="client-page">
    <div class="row container ">
    <h1 class="section-title-mod" >{{Buyer.BuyerName}} <button class="icon-settings" v-on:click="BuyerEdit()" title="edit"></button></h1>
  </div>
    <div class="row mb-3">
      <div class="col-lg-7">
        <p style="font-size: 24px">
        Address: {{Buyer.BuyerAddress}}, {{Buyer.BuyerCity}}
          <br>
        <span class="line"></span>
        <br>
        Contact: {{Buyer.BuyerContact}}
        <br>
        <span class="line"></span>
        <br>
        Preffered Categories:
        <span v-for="(pref,index) in Buyer.BuyerPreference" :key="index">{{pref}}, </span>
         </p>
      </div>
      <div class="col-lg-1 text-center">
        <span class="vline"></span>
      </div>
      <div class="col-lg-4">
        <p style="font-size: 24px">
        <span style="color: green" v-if="Buyer.isExporter">Exporter</span>
        <span style="color: blue" v-if="Buyer.isSeller && Buyer.onlySeller">Seller</span>
        <span style="color: red" v-if="Buyer.isSeller && !Buyer.onlySeller">Buyer & Seller</span>
        <span style="color: red" v-if="!Buyer.isSeller && !Buyer.isExporter">Buyer</span>
        <br>
        <span class="line"></span>
        <br>
        Type: {{Buyer.BuyerType}}
        <br>
        <span class="line"></span>
        <br>
        Added By: {{Buyer.UserId}}
        <br>
        </p>
      </div>
    </div>

    <AllOrders type="buyer" :buyerId="BuyerId"/>

  </div>
</template>

<script>
import AllOrders from './AllOrders'
    export default {
        name: 'ClientPage',
    components:{
      AllOrders
    },
    props: {
      BuyerId: Number,
      BuyerInfo: Object
    },
    data(){
        return{
          Buyer: Object,
          OngoingOrders: [],
          RefreshTable: true,
          addOrder: false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    displayDate(date){
    let day = date.slice(8, 10);
    let month = date.slice(5, 7);
    let year = date.slice(0, 4);
    return String(day+'/'+month+'/'+year)
    },
    BuyerEdit(){
      let mtype = 'buyer';
        if(this.Buyer.isExporter){
          mtype = 'exporter'
        }
        if(this.Buyer.isSeller){
          mtype = 'seller'
        }
        this.$router.push({
          name: 'EditBuyer',
          params: {
              Buyer: this.Buyer,
              type: mtype
          }
      })
      }
    },
    created(){
      if(this.BuyerInfo){
        this.Buyer = this.BuyerInfo;
      }
      else{
        this.$store.dispatch('getBuyerById', this.BuyerId).then(resp => this.Buyer = resp.data.data)
      }
      // this.$store.dispatch('getClientItems', this.ClientNumber).then(resp => this.PreferredItems = resp.data.data.PreferredItems)
    }

    }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
.line {
  width: 95%;
  height: 0;
  border: 1px solid #C4C4C4;
  margin: 3px;
  display:inline-block;
}
</style>
