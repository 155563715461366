<template>
  <div id="login">
  <div id="section-signup" class="section last-section">
    <div class="row justify-content-center">
      <div class="form-group row col-md-12">
        <div class="col-md-12" v-if="Mandis">
        <p>Select rate mandi: </p>
          <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          :v-model="UserMandiId"
                          >
          </v-select>
      </div>
      </div>
    </div>
    <RatePredictForm :MandiId="UserMandiId" :MandiName="MandiName" :retailOnly="retailOnly"/>
</div>
</div>
</template>

<script>
// import Alert from '../../components/Alert.vue'
import RatePredictForm from './AddRatePredict'
    export default {
        name: 'login',
        components: {
            // Alert,
            RatePredictForm,
          },
    data(){
        return{
            UserMandiId: Number,
            Mandis: Object,
            MandiName: '',
            retailOnly: Boolean,
            updateForm: true
        }
    },
    methods:{
        OnChangeSelectMandi(e){
          this.updateForm = false;
          this.UserMandiId = e.value;
          this.MandiName = e.label;
          if(e.retailOnly==1)
          {
            this.retailOnly = true;
          }
          else{
            this.retailOnly = false;
          }
          this.updateForm = true
        },
    },
    created () {
        this.retailOnly = false;
        this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis)
  },
  computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId, retailOnly: g.RetailOnly}))
    },
  }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
