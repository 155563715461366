<template>
  <div id="client-form">
      <div class="form-group row">
        <div class="col-md-12">
        <p>Client Number:</p>
      </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Phone Number" maxlength="12" minlength="12" pattern="92+[0-9]{10}" v-model="form.ClientNumber" v-on:change="ChangeFormEmit" required oninvalid="this.setCustomValidity('Enter number starting with 92 and followed by 10 digits. Example: 926687609876')">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Subscription Type:</p>
      </div>
        <div class="col-md-12">
           <v-select class="btn-vselect" v-on:input="OnChangeSelectType"
                          :value.sync="value"
                          :options="subscriptionOptions"
                          >
          </v-select>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Client Name:</p>
      </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Name" v-model="form.ClientName" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Is this client a farmer?</p>
      </div>
        <div class="col-md-12">
          <v-select class="btn-vselect" v-on:input="OnChangeSelectFarming"
                          :value.sync="value"
                          :options="yesNoOptions"
                          >
          </v-select>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Preferred Category:</p>
      </div>
        <div class="col-md-12">
          <v-select class="btn-vselect" v-on:input="OnChangeSelectCategory"
                          :value.sync="value"
                          :options="categoryOptions"
                          >
          </v-select>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Nearest Mandi:</p>
      </div>
        <div class="col-md-12">
          <v-select class="btn-vselect rounded" v-on:input="OnChangeSelectMandi"
                          :value.sync="value"
                          :options="SelectMandi"
                          >
          </v-select>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Client Address:</p>
      </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="District" v-model="form.ClientDistrict" v-on:change="ChangeFormEmit">
        </div>
        <div class="col-md-6">
          <input type="text" class="form-control" placeholder="Locale" v-model="form.ClientLocale" v-on:change="ChangeFormEmit">
        </div>
        <div class="col-md-12">
          <input type="text" class="form-control" placeholder="Address" v-model="form.ClientAddress" v-on:change="ChangeFormEmit">
        </div>
      </div>

</div>
</template>

<script>
    export default {
        name: 'ClientForm',
        components: {
    },
    props:{
      Inputform: Object,
      FormType: String,
    },
    data(){
        return{
            form: {},
            categoryOptions: ['Fruit', 'Vegetable', 'Grain', 'Livestalk'],
            yesNoOptions: ['Yes', 'No'],
            subscriptionOptions: ['Yes', 'Daily', 'Monthly'],
            isFarmer: 'No',
            Loading: true,
            src: '',
            Mandis: []
        }
    },
    methods:{
      OnChangeSelectCategory(e){
        this.form.PrefferedCategory = e;
        this.ChangeFormEmit();
      },
      OnChangeSelectType(e){
         this.form.ClientSubscription = e;
         this.ChangeFormEmit();
      },
        ChangeFormEmit(){
          this.$emit('form-updated', this.form);
        },
      OnChangeSelectFarming(e){
        if(e =='Yes'){
          this.form.isFarmer = true
        }
        else{
          this.form.isFarmer = false
        }
        this.ChangeFormEmit();
      },
      OnChangeSelectMandi(e){
          this.form.NearestMandiId = e.value;
          this.ChangeFormEmit();
        },
    },
    mounted () {
        this.form.ClientSubscription = 'Yes';
        this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis)
  },
   computed:{
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId, retailOnly: g.RetailOnly}))
    },
  }
}
</script>

