<template>
  <tr>
    <td>{{index}}</td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectItem"
                          :options="SelectItem"
                          >
          </v-select></td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectSeller"
                          :options="SelectSeller"
                          >
          </v-select></td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectMandi"
                          :options="SelectMandi"
                          >
          </v-select></td>
    <td><input type="number" placeholder="Age" style="width: 80px" v-model="form.LivestalkAge"></td>
    <td><input type="number" placeholder="Number" style="width: 80px" v-model="form.LivestalkNumber"></td>
    <td><input type="number" placeholder="Price" style="width: 100px" v-model="form.LivestalkAskingPrice"></td>
    <td><!-- <input  type="number" placeholder="Milk Yield" style="width: 100px" v-model="form.AverageMilkYield"> --></td>
    <td><input  type="number" placeholder="Weight" style="width: 100px" v-model="form.LivestalkWeight"> Kg</td>
    <td><input type="text" placeholder="Notes.." style="width: 100px" v-model="form.Notes"></td>
    <td><button class="btn-inv icon-plus" v-on:click="AddRate()" title="Add"></button></td>
</tr>
</template>

<script>
    export default {
        name: 'LivestalkRateLine',
        components: {
    },
    props:{
      index: Number
    },
    data(){
        return{
          form: {},
          Items: [],
          producers: [],
          Mandis: [],
          lactatoionPeriods: ['First', 'Second', 'Thrird']
        }
    },
    methods:{
      OnChangeSelectItem(e){
          this.form.ItemId = e.value
        },
      OnChangeSelectSeller(e){
          this.form.SellerId = e.value
      },
      OnChangeSelectPeriod(e){
        this.form.LactationPeriod = e
      },
      OnChangeSelectMandi(e){
        this.form.MandiId = e.value
      },
      async AddRate(){
        this.form.LivestalkAge = Number(this.form.LivestalkAge);
        this.form.LivestalkNumber = Number(this.form.LivestalkNumber);
        this.form.LivestalkAskingPrice = Number(this.form.LivestalkAskingPrice);
        this.form.AverageMilkYield = Number(this.form.AverageMilkYield);
        this.form.LivestalkWeight = Number(this.form.LivestalkWeight);
        await this.$store.dispatch('addLivestalkRate', this.form)
        .then(() => {
            this.$alert("Livestock Rate added");
            this.$emit('rate-added');
          }).catch(() => {
            this.$alert("Livestock Rate not added!");
          })
      }
    },
    mounted(){
      this.$store.dispatch('getAllBuyers').then(resp => this.producers = resp.data.data.buyers.filter(buyer => {return buyer.isSeller}));
      this.$store.dispatch('getAllMandis').then(resp => this.Mandis = resp.data.data.mandis);
      this.$store.dispatch('getAllLivestalkNames').then(resp => {this.Items = resp.data.data;
      this.Loading = false})
    },
    computed:{
    SelectItem(){
      return this.Items.map(g => ({label: g.ItemName + " / " + g.ItemUrduName, value: g.ItemId}))
    },
    SelectSeller(){
      return this.producers.map(g => ({label: g.BuyerName + " - " + g.BuyerCity, value: g.BuyerId}))
    },
    SelectMandi(){
      return this.Mandis.map(g => ({label: g.MandiName, value: g.MandiId}))
    },
  }
    }
</script>
