<template>
  <tr>
    <td>{{Prediction.RecordID}}</td>
    <td v-if="Type=='Item'">{{Prediction.MandiName}}</td>
    <td v-if="Type=='Mandi'">{{ItemName}}</td>
    <td>{{Prediction.Prediction}}</td>
    <td>{{Prediction.RecordDate}}</td>
    <td>{{Prediction.FirstName}} {{Prediction.LastName}}</td>
    <td>{{Prediction.PhoneNumber}}</td>
</tr>
</template>
<script>
    export default {
    name: 'PredictionLine',
    props:{
      Prediction: Object,
      Type: String,
      ItemName: String,
    },
    data(){
        return{
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
  }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
