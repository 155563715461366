<template>
  <div id="AllClients">
    <div class="container col-lg-12">
    <div class="table-responsive" v-if="!loading">
        <div class="table-wrapper" id="unique">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row mb-3 justify-content-center">
                    <div class="col-xs-4">
                        <h2 v-if="type!= 'buyer'">Orders <b>Management</b></h2>
                        <h2 v-else><b>Orders</b></h2>
                    </div>
                </div>
                <div class="row mb-3 justify-content-center">
                    <div class="col-xs-7">
                        <button v-on:click="sort='all'; sort0On=false; sort1On=false; sort2On=false; sort3On=false;" class="btn btn-primary"><span style="color: red" v-if="sort=='all' || (!this.sort0On && !this.sort1On && !this.sort2On && !this.sort3On)">ALL Status</span><span v-else>ALL Status</span></button>
                        <button v-on:click="sort=status[0]; sort0On = !sort0On; sort1On=false; sortAll=false; sort1On=false; sort3On=false" class="btn btn-primary"><span style="color: red" v-if="sort==status[0] && sort0On"><span class="btn-inv icon-circle" style="color: red"></span> {{status[0]}}</span><span v-else><span class="btn-inv icon-circle" style="color: red"></span> {{status[0]}}</span></button>

                        <button v-on:click="sort=status[1]; sort1On = !sort1On; sort0On=false; sort2On=false; sort3On=false" class="btn btn-primary"><span style="color: red" v-if="sort==status[1] && sort1On"><span class="btn-inv icon-circle" style="color: orange"></span> {{status[1]}}</span><span v-else><span class="btn-inv icon-circle" style="color: orange"></span> {{status[1]}}</span></button>

                        <button v-on:click="sort=status[2]; sort2On = !sort2On; sort0On=false; sort1On=false; sort3On=false" class="btn btn-primary"><span style="color: red" v-if="sort==status[2] && sort2On"><span class="btn-inv icon-circle" style="color: blue"></span> {{status[2]}}</span><span v-else><span class="btn-inv icon-circle" style="color: blue"></span> {{status[2]}}</span></button>

                        <button v-on:click="sort=status[3]; sort3On = !sort3On; sort0On=false; sort1On=false; sort2On=false" class="btn btn-primary"><span style="color: red" v-if="sort==status[3] && sort3On"><span class="btn-inv icon-circle" style="color: green"></span> {{status[3]}}</span><span v-else><span class="btn-inv icon-circle" style="color: green"></span> {{status[3]}}</span></button>
                    </div>
                    </div>

                    <div class="row mb-3 justify-content-center">
                    <div class="col-xs-7">
                        <button v-on:click="catsort='all'; catsort0On=false; catsort1On=false; catsort2On=false; catsort3On=false" class="btn btn-primary"><span style="color: red" v-if="catsort=='all' || (!catsort0On && !catsort1On && !catsort2On && !catsort3On)">ALL Categories</span><span v-else>ALL Categories</span></button>
                        <button v-on:click="catsort=itemCat[0]; catsort0On = !catsort0On; catsort1On=false; catsort1On=false; catsort3On=false" class="btn btn-primary"><span style="color: red" v-if="catsort==itemCat[0] && catsort0On"><span class="btn-inv"></span> {{itemCat[0]}}</span><span v-else><span class="btn-inv" style="color: red"></span> {{itemCat[0]}}</span></button>

                        <button v-on:click="catsort=itemCat[1]; catsort1On = !catsort1On; catsort0On=false; catsort2On=false; catsort3On=false" class="btn btn-primary"><span style="color: red" v-if="catsort==itemCat[1] && catsort1On"><span class="btn-inv"></span> {{itemCat[1]}}</span><span v-else><span class="btn-inv" style="color: orange"></span> {{itemCat[1]}}</span></button>

                        <button v-on:click="catsort=itemCat[2]; catsort2On = !catsort2On; catsort0On=false; catsort1On=false; catsort3On=false" class="btn btn-primary"><span style="color: red" v-if="catsort==itemCat[2] && catsort2On"><span class="btn-inv"></span> {{itemCat[2]}}</span><span v-else><span class="btn-inv"></span> {{itemCat[2]}}</span></button>

                        <button v-on:click="catsort=itemCat[3]; catsort3On = !catsort3On; catsort0On=false; catsort1On=false; catsort2On=false" class="btn btn-primary"><span style="color: red" v-if="catsort==itemCat[3] && catsort3On"><span class="btn-inv"></span> Livestock</span><span v-else><span class="btn-inv"></span> Livestock</span></button>
                    </div>
                    </div>


                <div class="row justify-content-center">
                    <div class="col-xs-8">
                        <button v-on:click="AddOrder()" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Order</span></button>
                    </div>
                    <div class="col-xs-4">
                        <form class="search-form" action="" style="margin-top: -1px; margin-left: 20px">
                          <input class="search-box" v-model="search" placeholder="Search Buyer/Item..." style="font-size: 14px;">
                          <i class="faa icon-search" style="font-size: 14px"></i>
                        </form>
                    </div>
                </div>
            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Status</th>
                        <th>Date</th>
                        <th>Buyer</th>
                        <th>Item</th>
                        <th>Quantity</th>
                        <th>Added By</th>
                    </tr>
                </thead>
                <tbody v-if="addOrder">
                    <NewOrderLine v-on:update="refreshTable()"/>
                </tbody>
                <tbody v-for="order in categoryFilteredList" :key="order.OrderId">
                    <OrderLine :order="order" :index="order.OrderId" v-on:update="refreshTable()"/>
                </tbody>
            </table>
        </div>
    </div>
</div>

      </div>
</template>

<script>
import OrderLine from '../../components/OrderLine'
import NewOrderLine from '../../components/NewOrderLine'
    export default {
        name: 'AllSubscriptions',
        components: {
      OrderLine,
      NewOrderLine
      // Alert,
    },
    props:{
        type: String,
        buyerId: Number,
        buyerName: String
    },
    data(){
        return{
            orders: [],
            status:['Initiated', 'InProcess', 'Fulfilled', 'Delivered'],
            itemCat: ['Fruit', 'Vegetable', 'Grain', 'Livestalk'],
            msg: '',
            AddFail: false,
            AddDone: false,
            StatusFlag: false,
            loading: false,
            sort: 'all',
            catsort: 'all',
            catsort0On: false,
            catsort1On: false,
            catsort2On: false,
            catsort3On: false,
            sort0On: false,
            sort1On: false,
            sort2On: false,
            sort3On: false,
            addOrder: false,
            search: ''
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    refreshTable(){
        this.addOrder = false;
        this.loading = true;
        this.getOrders()
    },
    getOrders(){
        if(this.type == 'all')
        {
            this.$store.dispatch('getAllOrders').then(resp => {
            this.orders = resp.data.data.orders;
            this.loading = false})
            return
        }
        else if(this.type == 'buyer')
        {
            this.$store.dispatch('getBuyerOrders', this.buyerId).then(resp => {
            this.orders = resp.data.data.orders;
            this.loading = false})
            return
        }
    },
    AddOrder(){
        this.$router.push({
          name: 'NewOrderPage',
      })
    }
    },
    mounted(){
        this.loading = true;
        this.getOrders()
    },
    computed : {
       filteredList() {
        var ordersSub = [];
        if(!this.Loading)
        {
            if(this.sort == 'all' || (!this.sort0On && !this.sort1On && !this.sort2On && !this.sort3On))
            {
                ordersSub = this.orders;
                return ordersSub.sort(function(a,b){
                          return new Date(b.DateCreated) - new Date(a.DateCreated);
                        }).filter(order => {
                return order.BuyerName.toLowerCase().includes(this.search.toLowerCase()) || order.ItemName.toLowerCase().includes(this.search.toLowerCase())
              })
            }
            else{
                for(let i in this.status)
                {
                  if(this.sort==this.status[i])
                    {
                        ordersSub = this.orders.filter(order => {return order.OrderStatus == this.status[i]}).sort(function(a,b){
                          return new Date(b.DateCreated) - new Date(a.DateCreated);
                        })
                        return ordersSub.filter(order => {
                                return order.BuyerName.toLowerCase().includes(this.search.toLowerCase()) || order.ItemName.toLowerCase().includes(this.search.toLowerCase())
                            })
                        // break;
                    }
                }
                ordersSub = this.orders;
                return ordersSub.sort(function(a,b){
                          return new Date(b.DateCreated) - new Date(a.DateCreated);
                        }).filter(order => {
                                return order.BuyerName.toLowerCase().includes(this.search.toLowerCase()) || order.ItemName.toLowerCase().includes(this.search.toLowerCase())
                            })
            }
        }
        else{
            return {}
        }

    },

    categoryFilteredList(){
        if(this.catsort == 'all'){
            return this.filteredList
        }
        else{
            let listnew = this.filteredList
            return listnew.filter(order => {
                                return order.ItemCategory == this.catsort})
                            }
        }
    },
    }
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
        color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
    border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 13px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
</style>
