<template>
  <tbody style="font-size: 24px;">
    <tr>
    <td>{{index}}</td>
    <td>{{source.SourceMandiName}}</td>
    <td>{{source.Amount}} ({{source.AmountUnitName}})</td>
    <td>{{source.PackageWeight}} (KG)</td>
    <td>{{source.ArrivalDate}}</td>
    <td v-if="!addNewBid"><button class="btn-inv icon-plus" v-on:click="AddNewBid()">Bids</button></td>
    <td v-else><button class="btn-inv icon-minus" v-on:click="AddNewBid()">Bids</button></td>
    <td v-if="isAdmin"><button class="btn-inv icon-delete" v-on:click="deleteSource()" title="Delete"></button></td>
    </tr>
    <tr v-if="addNewBid" style="background-color: #FFFFE0;">
     <td colspan="5">
      <table class="table table-striped table-hover" style="background-color: #FFFFE0;">
      <thead>
          <th>Bidder</th>
          <th>Bidding Price</th>
          <th>Add/Submit</th>
      </thead>
      <tbody>
          <BidderLine v-for="bid in source.BidRates" :key="bid.ItemBidRateId" :bid="bid"/>
          <AddBidderLine :ItemSourceId="source.ItemSourceId" :buyers="buyers" v-on:add-more="AddMore" v-on:send-now="addLast" v-on:send-all="AddBids"/>
          <AddBidderLine v-for="ind in addlist" :key="ind" :ItemSourceId="source.ItemSourceId" :buyers="buyers" v-on:add-more="AddMore" v-on:send-now="addLast" v-on:send-all="AddBids"/>
      </tbody>
     </table>
    </td>
    </tr>
</tbody>
</template>

<script>
import AddBidderLine from './NewSourceBidLine'
import BidderLine from './SourceBidLine'
    export default {
    name: 'SourceLine',
    components:{
      AddBidderLine,
      BidderLine
    },
    props:{
      source: Object,
      index: Number,
      type: String,
      ItemId: Number,
      MandiId: Number,
      buyers: Array
    },
    data(){
        return{
            state: '',
            date: '',
            Units: Object,
            addNewBid: false,
            count: 0,
            bids: [],
            addlist: []
        }
    },
    methods:{
      AddNewBid(){
       this.addNewBid = !this.addNewBid;
      },
       AddMore(e){
        this.bids.push(e);
        this.count = this.count + 1;
        this.addlist.push(this.count);
      },
      addLast(e){
        this.bids.push(e);
        this.AddBids();
      },
      async AddBids(){
      let srcForm = {
        'itemSourceId': this.source.ItemSourceId,
        'bidrates': this.bids
      };
      await this.$store.dispatch('addItemBidRates', srcForm)
        .then(() => {
          this.$emit('src-added');
          this.$alert("Item Bids added");
          }).catch(() => {
            this.$alert("Error! Not All Item Bids added")
          })
    },
    deleteSource(){
      this.$confirm("Delete Item Source #" + String(this.source.ItemSourceId) + " Permanently?").then(() => {
          this.$store.dispatch('deleteItemSource', this.source.ItemSourceId).then(() => {
            this.$alert("Item source deleted");
            this.$emit('src-added');
          }).catch(() => {
            this.$alert("Item source not deleted!");
          })
        })
    }
    },
    computed:{
      isAdmin(){
      return this.$store.getters.isAdmin
    }
    }
  }
</script>

