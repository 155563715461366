<template>
  <div id="mandi-form">
    <div v-if="Loading">Loading...</div>
    <div v-else>
      <div class="form-group row">
        <div class="col-md-12">
        <p>Provider Name: {{form.ProviderName}}</p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-12">
          <input type="text" class="form-control" placeholder="Name" v-model="form.ProviderName" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Provider Location: <span v-if="FormType!='Edit'"> {{form.ProviderAddress}}, {{form.ProviderCity}}</span></p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="text" class="form-control" placeholder="Address" v-model="form.ProviderAddress" v-on:change="ChangeFormEmit">
        </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="text" class="form-control" placeholder="City" v-model="form.ProviderCity" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Provider Contact: <span v-if="FormType!='Edit'">{{form.ProviderContact}}</span></p>
      </div>
        <div class="col-md-6">
          <input v-if="FormType=='Edit'" type="text" class="form-control" placeholder="Phone/Email" v-model="form.ProviderContact" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p v-if="FormType=='Edit'">Provider Coordinates (X, Y):</p>
        <p v-if="FormType!='Edit'">Provider Coordinates: <a :href="src" class="icon-map-marker" style="color: red" target="_blank"></a>({{form.ProviderX}}, {{form.ProviderY}})</p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="number" step="any" class="form-control" placeholder="X" v-model="form.ProviderX" v-on:change="value.replace(/\d+|^\s+|\s+$/g, '').trim(); ChangeFormEmit" @keydown.space.prevent dense >
        </div>
        <div v-if="FormType=='Edit'" class="col-md-6">
          <input type="number" step="any" class="form-control" placeholder="Y" v-model="form.ProviderY" v-on:change="value.replace(/\d+|^\s+|\s+$/g, '').trim(); ChangeFormEmit" @keydown.space.prevent dense>
        </div>
      </div>

      <div class="form-group row">
         <div class="col-md-12">
        <p>Storage Capacity: (square meters)<span v-if="FormType!='Edit'"><br>
        General Storage: {{form.GeneralStorageCapacity}}<br>
        Cold Storage: {{form.ColdStorageCapacity}}<br>
        Free Storage: {{form.FreeStorageArea}}</span>
        </p>
      </div>
        <div v-if="FormType=='Edit'" class="col-md-4">
          <input type="number" class="form-control" placeholder="General" v-model="form.GeneralStorageCapacity" v-on:change="ChangeFormEmit">
        </div>
        <div v-if="FormType=='Edit'" class="col-md-4">
          <input type="number" class="form-control" placeholder="Cold" v-model="form.ColdStorageCapacity" v-on:change="ChangeFormEmit">
        </div>
        <div v-if="FormType=='Edit'" class="col-md-4">
          <input type="number" class="form-control" placeholder="Free" v-model="form.FreeStorageArea" v-on:change="ChangeFormEmit">
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Cooling System Type: <span v-if="FormType!='Edit'">{{form.CoolingSystemType}}</span></p>
          <v-select v-if="FormType=='Edit'" class="btn-vselect" v-on:input="OnChangeSelectCooling"
                          :options="coolingSys"
                          :v-model="form.CoolingSystemType">
          </v-select>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p v-if="FormType=='Edit'" >Has StandBy Electricity?
          <input type="checkbox" id="elec" v-model="StandByElectricity" v-on:change="ChangeFormEmit">
          <label for="elec">  Yes</label>
        </p>
        <p v-else>
          <span v-if="form.StandByElectricity">
            Has standBy electricity
          </span>
          <span v-else>
            Does not have standBy electricity
          </span></p>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p v-if="FormType=='Edit'" >Wants to sell as well?
          <input type="checkbox" id="seller" v-model="inSelling" v-on:change="ChangeFormEmit">
          <label for="seller">  Yes</label>
        </p>
        <p v-else>
          <span v-if="form.inSelling">
          Wants to sell
          </span>
          <span v-else>
          Does not want to sell
          </span></p>
      </div>
      </div>

      <div class="form-group row">
        <div class="col-md-12">
        <p>Additional Notes: <span v-if="FormType!='Edit'">{{form.Notes}}</span></p>
          <input v-if="FormType=='Edit'" type="text" class="form-control" placeholder="Notes" v-model="form.Notes" v-on:change="ChangeFormEmit">
      </div>
      </div>

</div>
</div>
</template>

<script>
    export default {
        name: 'ProducerForm',
        components: {
    },
    props:{
      Inputform: Object,
      FormType: String,
    },
    data(){
        return{
            form: Object,
            Loading: true,
            src: '',
            coolingSys: ['Ammonia Based', 'Ammonia Free', 'Solar', 'Other'],
            inSelling: false,
            StandByElectricity: false
        }
    },
    methods:{
      OnChangeSelectCooling(e){
        this.form.CoolingSystemType = e;
        this.ChangeFormEmit()
      },
      ChangeFormEmit(){
        this.form.ProviderX = Number(this.form.ProviderX);
        this.form.ProviderY = Number(this.form.ProviderY);
        this.form.StandByElectricity = 0;
        this.form.inSelling = 0;
        if(this.StandByElectricity){this.form.StandByElectricity = 1}
          if(this.inSelling){this.form.inSelling = 1}
        this.$emit('form-updated', this.form);
      },
    },
    mounted(){
        this.form = this.Inputform;
        if(this.form.inSelling == 1){
          this.inSelling = true
        }
        if(this.form.StandByElectricity == 1){
          this.StandByElectricity = true
        }
        this.src = "http://www.google.com/maps/place/" + String(this.form.ProviderX) + "," + String(this.form.ProviderY);
        this.Loading = false;
  },
}
</script>

