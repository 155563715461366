<template>
  <div id="find-client">
    <div class="section last-section container">
    <div id="section-login" class="row justify-content-center">
          <div class="col-lg-12">
            <h2 class="mb-3"><b>Block Client Calls To Our Agents:</b></h2>
            <form v-on:submit.prevent="findClient" data-aos="fade">
              <div class="form-group row">
                <div class="col-md-12">
                  <input type="text" class="form-control" placeholder="Phone Number" v-model="ClientNumber">
                </div>
              </div>

              <div class="form-group row justify-content-center">
              <div class="col-md-4 justify-content-center">
                <input type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Search">
              </div>
            </div>

            </form>
            <Alert v-if="findFail == true" v-on:alert-closed="findFail=false" :isWarning="findFail" :msg="'Client '+String(ClientNumber)+' does not exist in the database'"/>
          </div>
        </div>
      </div>
      </div>
</template>

<script>
import Alert from '../../components/Alert.vue';
    export default {
        name: 'FindClient',
        components: {
          Alert,
          },
    data(){
        return{
          ClientNumber: "",
          findFail: false,
          Client: Object,
          found: false
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    Block(){
      let alert = "Block Client " + String(this.Client.ClientNumber) + " from dialling our agents?"
      this.$confirm(alert).then(() =>{
      this.Client.ClientName = 'Blocked';
      let ChangeForm ={
        'ClientNumber': this.Client.ClientNumber,
        'form': this.Client
      };
      this.$store.dispatch('editClientById', ChangeForm).then(() =>  {
        this.$alert("Client "+ this.Client.ClientNumber +" Successfully Blocked!");
      }).catch(() =>  this.$alert("Error! Client "+ this.Client.ClientNumber +" did not get blocked! Please try again later."))
      })
    },
    findClient: function(){
      if(this.ClientNumber.substring(0,2) != '92'){
        this.ClientNumber = '92' + this.ClientNumber;
      }
      this.$store.dispatch('getClientById', this.ClientNumber).then(resp => {
        if(resp.data.error)
        {
          this.findFail = true
        }
        else{
          this.Client = resp.data.data;
          this.Block();

        }
      })
    }

},

  }
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
        color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
    border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 18px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
      .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
