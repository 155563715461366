<template>
  <tr>
    <td>{{index}}</td>
    <td><input type="text" placeholder="Name" v-model="mandi.MandiName"></td>
    <td><input type="text" placeholder="City" v-model="mandi.City"></td>
    <td><input type="text" placeholder="District" v-model="mandi.District"></td>
    <td><v-select class="btn-vselect" v-on:input="OnChangeSelectRetail"
                          :value.sync="value"
                          :options="YesNo"
                          :v-model="RetailOnly"
                          >
          </v-select></td>
    <td><button class="btn-inv icon-plus" v-on:click="AddMandi(mandi)"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'MandiLine',
        components: {
          // ToggleSubscription
    },
    props:{
      index: Number,
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            mandi: {
              MandiName: '',
              City: '',
              District: '',
              RetailOnly: 0
            },
            retailOnly: Boolean,
            YesNo: ['Retail Only', 'Not Retail Only'],
        }
    },
    methods:{
     OnChangeSelectRetail(e){
        this.mandi.RetailOnly = 0;
        if(e == 'Retail Only')
        {
          this.mandi.RetailOnly = 1;
        }
    },
     AddMandi: async function(mandi){
      await this.$store.dispatch('addMandi', mandi)
        .then(() => {
          this.$emit('mandi-added');
          this.$alert("Mandi added");
          }).catch(() => {
            this.$alert("Error! Mandi not added")
          })
      },

      },
    mounted(){
      this.retailOnly = this.mandi.RetailOnly;
    }
    }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
