<template>
  <div id="AllClients" class="section first-section row justify-content-center">
    <div class="container col-lg-11">
    <div class="table-responsive" v-if="!loading">
        <div class="table-wrapper">
            <div class="table-title" style="background-color: #f0c419;">
                <div class="row mb-3">
                    <div class="col-xs-4">
                        <h2>{{ItemName}} <b>Sources</b> - {{MandiName}}</h2>
                    </div>
                    <div class="col-xs-4">
                        <button v-on:click="addSource()" class="btn btn-primary"><i class="material-icons">&#xE147;</i> <span>Add New Source</span></button>
                    </div>
                </div>

                <div class="row justify-content-center">
                        <p>Get From: </p>
                        <input type="date" name="from"
                               v-model="fromDate"
                               :max="yesterday" style="height:35px">
                        <p>   - To: </p>
                        <input type="date" name="to"
                               v-model="toDate"
                               :max="today" :min="fromDate" style="height:35px">
                        <button class="btn-inv icon-search" v-on:click="getRates()" title="search" style="margin-top: -20px; margin-left: 20px"></button>
                </div>

            </div>
            <table class="table table-striped table-hover">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Source Mandi</th>
                        <th>Amount (Unit)</th>
                        <th>Package Weight</th>
                        <th>Arrival Date</th>
                    </tr>
                </thead>
                <AddItemSourcePart v-if="AddItemSource" :ItemId="Item.ItemId" :MandiId="MandiId" v-on:src-added="refreshTable()"/>
                    <ItemSourceLine v-for="source in sources" :key="source.ItemSourceId" :source="source" :index="source.ItemSourceId" :buyers="buyers" v-on:src-added="refreshTable()"/>
            </table>
        </div>
    </div>
</div>

      </div>
</template>

<script>
import ItemSourceLine from '../../components/ItemSourceLine'
import AddItemSourcePart from '../../components/NewItemSourcePart'
    export default {
        name: 'AllItemSources',
        components: {
            ItemSourceLine,
            AddItemSourcePart
    },
    props:{
        Item: Object,
        ItemName: String,
        MandiId: Number,
        MandiName: String
    },
    data(){
        return{
            loading: true,
            sources: [],
            AddItemSource: false,
            biddedsource: {},
            addNewBid: false,
            search: '',
            buyers: [],
            yesterday: "",
            lastweek: "",
            today: "",
            fromDate: "",
            toDate: "",

        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    refreshTable(){
        this.AddItemSource = false;
        this.loading = true;
        let srcForm = {
            'MandiId': this.MandiId,
            'ItemId' : this.Item.ItemId
        };
        this.$store.dispatch('getLatestItemSoucres', srcForm).then(resp => {
            this.sources = resp.data.data;
            this.loading = false})
    },
    addSource(){
        this.AddItemSource = true
    },
    AddBids(e){
        console.log('add bids');
        console.log(e);
        this.addNewBid = true;
        this.biddedsource = e;
    },
    getRates(){
        let TimePeriod = {'from': this.fromDate,
                        'to': this.toDate,
                        'MandiId': this.MandiId,
                        'ItemId' : this.Item.ItemId};
        this.Loading = true;
        this.AddItemSource = false;
        this.$store.dispatch('getItemSoucres', TimePeriod).then(resp =>
                                           {this.sources = resp.data.data;
            this.loading = false})
    },
    },
    mounted(){
      this.refreshTable();
      this.$store.dispatch('getAllBuyers').then(resp => {this.buyers = resp.data.data.buyers});
    },
    computed : {
    },
    }
</script>

<style>
td{
  text-align: center;
}
th{
  text-align: center;
}
    .AllSubscriptions {
        color: #566787;
    background: #737373;
    font-family: 'Varela Round', sans-serif;
    font-size: 13px;
  }
    .table-responsive {
        margin: 30px 0;
    }
  .table-wrapper {
        min-width: 500px;
        background: #fff;
        padding: 20px 25px;
    border-radius: 3px;
        box-shadow: 0 1px 1px rgba(0,0,0,.05);
    }
  .table-title {
    padding-bottom: 15px;
    background: #f0c419;
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 3px 3px 0 0;
    }
    .table-title h2 {
    margin: 5px 0 0;
    font-size: 24px;
  }
  .table-title .btn {
    color: #566787;
    float: right;
    font-size: 13px;
    background: #fff;
    border: none;
    min-width: 50px;
    border-radius: 2px;
    border: none;
    outline: none !important;
    margin-left: 10px;
  }
  .table-title .btn:hover, .table-title .btn:focus {
        color: #566787;
    background: #f2f2f2;
  }
  .table-title .btn i {
    float: left;
    font-size: 21px;
    margin-right: 5px;
  }
  .table-title .btn span {
    float: left;
    margin-top: 2px;
  }
    table.table tr td {
    border-color: #e9e9e9;
    padding: 5px 5px;
    vertical-align: middle;
    font-size: 13px;
    }
    table.table tr th {
    border-color: #e9e9e9;
    padding: 12px 15px;
    vertical-align: middle;
    font-size: 18px;
    }
    .Deactivated {
    background-color: #737373;
    }
  table.table tr th:first-child {
    width: 30px;
  }
  table.table tr th:last-child {
    width: 30px;
  }
    table.table-striped tbody tr:nth-of-type(odd) {
      background-color: #fcfcfc;
  }
  table.table-striped.table-hover tbody tr:hover {
    background: #f5f5f5;
  }
    table.table th i {
        font-size: 13px;
        margin: 0 5px;
        cursor: pointer;
    }
    table.table td:last-child i {
    opacity: 0.9;
    font-size: 22px;
    margin: 0 5px;
    }
  table.table td a {
    font-weight: bold;
    color: #566787;
    display: inline-block;
    text-decoration: none;
  }
  table.table td a:hover {
    color: #2196F3;
  }
  table.table td a.settings {
        color: #2196F3;
    }
    table.table td button.delete {
        color: #F44336;
    }
    table.table td i {
        font-size: 19px;
    }
  table.table .avatar {
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 10px;
  }
  .status {
    font-size: 30px;
    margin: 2px 2px 0 0;
    display: inline-block;
    vertical-align: middle;
    line-height: 10px;
  }
    .text-success {
        color: #10c469;
    }
    .text-info {
        color: #62c9e8;
    }
    .text-warning {
        color: #FFC107;
    }
    .text-danger {
        color: #ff5b5b;
    }
    .pagination {
        float: right;
        margin: 0 0 5px;
    }
    .pagination li a {
        border: none;
        font-size: 13px;
        min-width: 30px;
        min-height: 30px;
        color: #999;
        margin: 0 2px;
        line-height: 30px;
        border-radius: 2px !important;
        text-align: center;
        padding: 0 6px;
    }
    .pagination li a:hover {
        color: #666;
    }
    .pagination li.active a, .pagination li.active a.page-link {
        background: #03A9F4;
    }
    .pagination li.active a:hover {
        background: #0397d6;
    }
  .pagination li.disabled i {
        color: #ccc;
    }
    .pagination li i {
        font-size: 16px;
        padding-top: 6px
    }
    .hint-text {
        float: left;
        margin-top: 10px;
        font-size: 13px;
    }
</style>
