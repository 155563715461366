<template>
  <tr>
    <td>{{index}}</td>
      <td>{{todayDate}}</td>
      <td><v-select class="btn-vselect" v-on:input="OnChangeSelectMotive"
                          :value.sync="value"
                          :options="motiveList"
                          >
          </v-select></td>
      <td><textarea type="text" class="form-control" placeholder="Notes..." v-model="rec.OperatorNote"></textarea></td>
      <td></td>
    <td><button class="btn-inv icon-plus" v-on:click="AddRecord()"></button></td>
</tr>
</template>

<script>
// import ToggleSubscription from './ToggleSubscription'
    export default {
        name: 'MandiLine',
        components: {
          // ToggleSubscription
    },
    props:{
      ClientNumber: String,
      index: Number,
      type: String,
    },
    data(){
        return{
            // Status:['Active', 'Deactivated'],
            motiveList: ['Complaint', 'Advice', 'Request', 'Profile Update'],
            rec: {},
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
    },
    OnChangeSelectMotive(e){
      this.rec.CallMotive = e
    },
    AddRecord: function(){
      let recForm ={
        'ClientNumber': this.ClientNumber,
        'CallMotive' : this.rec.CallMotive,
        'OperatorNote': this.rec.OperatorNote
      };
        this.$store.dispatch('addClientCallRecord', recForm).then(() => {
            this.$emit('record-added');
          }).catch(() =>
          this.$alert("Error! Record did not get added"))
      },
  },
  computed:{
    todayDate(){return new Date().toString().substring(0, 25)}
    }
  }
</script>

<style>
.toggle-icon{
  font-size: 40px;
  color: #f0c419;
  align-self: center;
}
</style>
