<template>
  <div>
  <div class="section last-section">
    <div class="row justify-content-center ">
          <div class="col-md-12">
            <h2 class="section-title mb-3" v-if="Type!='Edit'">Add Provider</h2>
            <h2 class="section-title mb-3" v-else>Edit Provider</h2>
            <form v-on:submit.prevent="AddProducer">
              <ProviderForm v-if="!Loading" FormType='Edit' :Inputform="form" v-on:form-updated="form = $event"/>

              <div class="form-group row">
                <div class="col-md-4"/>
                <div class="col-md-4">
                  <input v-if="Type!='Edit'" type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Add">
                  <input v-if="Type=='Edit'" type="submit" class="btn btn-primary py-3 px-5 btn-block" value="Edit">
                </div>
              </div>
            </form>
        </div>
    </div>
</div>
</div>
</template>

<script>
import ProviderForm from '../../components/ProviderForm'
    export default {
        name: 'login',
        components: {
            ProviderForm,
          },
        props:{
          ProviderIn: Object,
          Type: String,
          ProviderId: Number
        },
    data(){
        return{
            form: {
              ProviderName: '',
              ProviderCity: '',
              ProviderAddress: '',
              ProviderX: Number,
              ProviderY: Number,
              ProviderContact: ''
            },
            Loading: true
        }
    },
    methods:{
      goTo: function(path){
      this.$store.dispatch('goTo', path);
        },
        async AddProducer(){
          if(this.Type!='Edit'){
            await this.$store.dispatch('addProvider', this.form)
            .then(() => {
            this.$alert("Storage Provider added!");
            this.goTo('all-providers');
            }).catch(err => {
              this.$alert(err.error.message);
            });
          }
          else{
            let newForm = {
              'ProviderForm': this.form,
              'ProviderId': this.ProviderId
            };
            await this.$store.dispatch('editProvider', newForm)
            .then(() => {
            this.$alert("Storage Provider edited!");
            this.goTo('all-providers');
            }).catch(err => {
              this.$alert(err.error.message);
            });

          }
        }
    },
    mounted(){
      if(this.Type == 'Edit'){
        this.form = this.ProviderIn
      }
      this.Loading = false
    }

  }
</script>

<style>
  .field-icon {
  float: right;
  margin-right: 25px;
  margin-top: -30px;
  position: relative;
  z-index: 2;
}
</style>
